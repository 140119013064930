import { useMutation} from "react-query";
import { useToast } from "../../../hook/usetoast";
import { queryClient } from "../../../config";
import DataService from "../../../config/DataService";
import { useLogout } from "../../../Components/Header/api";

const { getRequest, postRequest } = DataService()

export const useChangePassword = () => {
    const toast = useToast();
    const logout = useLogout();

    return useMutation({
        mutationFn: data => {
            return postRequest("user/change_password", { ...data })
        },
        onSuccess: async (error) => {
            if (!error?.status) {
                return toast.error(error?.message)
            }
            await logout.mutateAsync()
        }
    })
}

export const useChangeMPIN = () => {
    const toast = useToast();
    const logout = useLogout();

    return useMutation({
        mutationFn: data => {
            return postRequest("user/change_MPIN", { ...data })
        },
        onSuccess: async (error) => {
            if (!error?.status) {
                return toast.error(error?.message)
            }
            await logout.mutateAsync()
        }
    })
}

export const useUpdateProfile = () => {
    const toast = useToast();

    return useMutation({
        mutationFn: data => {
            return postRequest("user/update_profile_details", data)
        },
        onSuccess: success => {
            if (success?.status) {
                queryClient.refetchQueries('get-profile')
                return toast.success(success?.message)
            }
            return toast.error(success?.message)
        }
    })
}