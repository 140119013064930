import React from 'react'
import { NotFound } from '../../Nodata/Nodatafound'


function TransactionHistory() {

    let tableContent;

 
       tableContent = (
          <tr>
             <td colSpan={4}><NotFound message="No Data Found" /></td>
          </tr>
       );
    

    return (
        <div className="card tableCard">
            <div className="cardInnerCnt">
                <div className="table-responsive">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th className="">Date & Time</th>
                                <th>Type</th>
                                <th>Transaction ID</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableContent
                            }
                        </tbody>
                    </table>
                </div>
             
            </div>
        </div>
    )
}

export default TransactionHistory