import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import "../../assets/scss/Innerpages/Innerpages.scss";
import classnames from 'classnames';
import BusinessVolumeReport from './tab/BussinessVolumeReport';
import ProgramCode from './tab/ProgramCode';
const BusinessVolume = () => {

   const [activeTab, setActiveTab] = useState('1');
   const toggle = tab => {
      if (activeTab !== tab) setActiveTab(tab);
   }

   return (
      <div className="container">
         <h3 className="secHeading text-center">Business Volume</h3>

         <Nav tabs className="mb-4">
            <NavItem>
               <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => { toggle('1'); }}>
                  Business Volume Report
               </NavLink>
            </NavItem>
            <NavItem>
               <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => { toggle('2'); }} >
                  Program Code
               </NavLink>
            </NavItem>
         </Nav>

         <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
               <BusinessVolumeReport />
            </TabPane>
            <TabPane tabId="2">
               <ProgramCode />
            </TabPane>
         </TabContent>
      </div>
   )
}
export default BusinessVolume;