import "../../assets/scss/Innerpages/Innerpages.scss";
import { Fragment, useEffect } from "react";
import { useCms } from "./api";
import { WaitingLoader } from "../../Components/Loader/buttonLoad";
import ReactHtmlParser from 'react-html-parser';


const Privacy = () => {

   useEffect(() => {
      window.scrollTo({
         top: 0,
         behavior: 'smooth'
      })
   }, [])
   const { data: cms, isLoading } = useCms("privacy")

   return (
      <Fragment>
         {isLoading && <WaitingLoader />}

         <h3 className="secHeading text-center">{cms?.status ? cms?.data[0]?.page_title : ""}</h3>

         <div className="container">
            <div className="row text-center text-lg-left">
               <div className="col-lg-12 mb-2">
                  {cms?.status ? ReactHtmlParser(cms?.data[0]?.pagecontent) : ""}
               </div>
            </div>
         </div>
      </Fragment>

   )
}
export default Privacy;