import React from 'react'
import dangerIcon from '../../assets/icons/danger.svg';
import { Alert } from 'reactstrap';
import { Dots } from '../../lib/dots';
import { useModalContext } from '../../contexts/ModalContext';
import { Link, useLocation } from 'react-router-dom';
import { ButtonLoader } from '../../Components/Loader/buttonLoad';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';

export function Submitbutton({ buttonText, loading, Status, message }) {

    const { profileData } = useModalContext();
    const { pathname } = useLocation();

    if (sessionStorage.getItem("type") == "admin" || Cookies.get('sdgsdsadgt')) {
        return (
            <Alert color="danger">
                <div className="d-flex align-items-center">
                    <div className="m-4">
                        <img src={dangerIcon} width="34" height="34" alt="dangerIcon" />
                    </div>
                    <div>
                        <p>Access Denied !</p>
                        <p>Login as User for further performance.</p>
                    </div>
                </div>
            </Alert>
        )
    }
    else if(profileData?.admin_buy_access){
        
            return (
                <Alert color="danger">
                    <div className="d-flex align-items-center">
                        <div className="m-4">
                            <img src={dangerIcon} width="34" height="34" alt="dangerIcon" />
                        </div>
                        <div>
                            <h3>ACTION DENIED :</h3>
                            <p>Temporarily you are not allowed to perform any transactions.</p>
                        </div>
                    </div>
                </Alert>
            )
        } else if ((profileData?.kyc_status !== 3 && Status && (pathname === "/wallet" || pathname === "/withdraw" || pathname === "/bussiness-ico" || pathname === "/participate" || pathname == "/participate-store"))) {
            return (
                <Link className="btn btn-11545 btnGold" to='/kyc'>
                    Complete KYC
                </Link>
            )
        } else if (Status === false) {
            return (
                <Alert color="danger">
                    <div className="d-flex align-items-center">
                        <div className="m-4">
                            <img src={dangerIcon} width="34" height="34" alt="dangerIcon" />
                        </div>
                        <div>
                            <h3>NOTE :</h3>
                            <p>{message}.</p>
                        </div>
                    </div>
                </Alert>
            )
        }
        else if (Status === true) {
            return (
                <button className="btn btn-11545 btnGold" id="btn" disabled={loading} type='submit'>
                    {loading ? <Dots>Processing</Dots> : buttonText}
                </button>
            )
        } else return (
            <button className="btn btn-11545 btnGold" disabled>
                <ButtonLoader />
            </button>
        )
    
}

Submitbutton.propTypes = {
    buttonText : PropTypes.any,
    loading: PropTypes.any,
    Status: PropTypes.any,
    message: PropTypes.any,
}