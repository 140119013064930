import React, { useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Dots } from '../../../lib/dots';
import { useChangeMPIN } from '../api/fetchprofile';
import EyeSlashIcon from '../../../assets/images/Auth/eye-slash.png'
import EyeIcon from '../../../assets/images/Auth/eye.png'
import { WaitingLoader } from '../../../Components/Loader/buttonLoad';
import Cookies from "js-cookie"
import { Submitbutton } from '../../common/Submitbutton';
import { generateSecureRandomNumber } from '../../../hook/generateSecureRandomNumber';
const schema = yup.object().shape({
    currentMPIN: yup.string().required('MPIN is Required'),
    newMPIN: yup.string()
    .notOneOf([yup.ref('currentMPIN'), null], "New MPIN can't be same as current MPIN")
    .required('MPIN is Required'),
    confirmMPIN: yup.string()
        .oneOf([yup.ref('newMPIN'), null], "Confirm MPIN doesn't match")
        .required('Confirm MPIN is Required'),
})

function ChangeMPIN() {

    const [show, setShow] = useState(true);
    const [shownew, setShownew] = useState(true);
    const [showCon, setShowCon] = useState(true);
    const [loading, setLoading] = useState(false)

    const changeMPINmutation = useChangeMPIN();

    const { control, handleSubmit, formState: { errors, isValid, isDirty }, reset } = useForm({
        mode: 'onChange',
        resolver: yupResolver(schema),
        defaultValues: {
            currentMPIN: '',
            newMPIN: "",
            confirmMPIN: "",
        }
    });


    const handleKeyDown = (e) => {
     
        const regex = /^\d{0,6}$/;
      
        const value = e.target.value + e.key; 
       
        if (!regex.test(value)) {
          e.preventDefault();
        }
      };

    const onSubmit = async (data) => {
        setLoading(true)
        let random = Math.floor(generateSecureRandomNumber() * 10000)
        sessionStorage.setItem('random', random)
        data["random"] = random
        await changeMPINmutation.mutateAsync(data)
        setLoading(false)
        reset()
    }

    const toggleShow = async (e) =>{
        if (e.key === "Enter" || e.nativeEvent.pointerType === "mouse" || e.nativeEvent.pointerType === "touch") {
            setShow(!show)
        }
    }

    const toggleShowNew = async (e) =>{
        if (e.key === "Enter" || e.nativeEvent.pointerType === "mouse" || e.nativeEvent.pointerType === "touch") {
            setShownew(!shownew)
        }
    }

    const toggleShowCon = async (e) =>{
        if (e.key === "Enter" || e.nativeEvent.pointerType === "mouse" || e.nativeEvent.pointerType === "touch") {
            setShowCon(!showCon)
        }
    }

    return (
        <div className="row">
            {loading && <WaitingLoader />}
            <div className="col-12">
                <div className="card">
                    <div className="cardInnerCnt">
                        <div className="cardHeader">
                            <h3>Change MPIN</h3>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label className="formLabel" htmlFor="mpnId1">Current MPIN</label>
                                        <div className="input-group mb-3">
                                            <Controller name='currentMPIN' control={control} render={({ field }) => (
                                                <input
                                                    {...field}
                                                    type={show ? "password" : "text"}
                                                    className="form-control"
                                                    maxLength={6}
                                                    onInput={handleKeyDown}
                                                    placeholder='Enter your Current MPIN'
                                                    autoComplete='off'
                                                />
                                            )} />
                                            <div className="input-group-append">
                                                <span className="input-group-text" type="button" onClick={(e) => toggleShow(e)} onKeyDown={(e) => toggleShow(e)}>
                                                    {show ? <img src={EyeSlashIcon} width="20" height="20" alt="eyeSlashIcon" /> :
                                                        <img src={EyeIcon} width="20" height="20" alt="eyeSlashIcon" />}
                                                </span>
                                            </div>
                                        </div>
                                        {errors.currentMPIN && <p className="errMsg">* {errors.currentMPIN.message}</p>}
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label className="formLabel" htmlFor="mpnId2">New MPIN</label>
                                        <div className="input-group mb-3">
                                            <Controller name='newMPIN' control={control} render={({ field }) => (
                                                <input
                                                    {...field}
                                                    type={shownew ? "password" : "text"}
                                                    className="form-control"
                                                    maxLength={6}
                                                    onInput={handleKeyDown}
                                                    placeholder='Enter your New MPIN'
                                                    autoComplete='off'
                                                />
                                            )} />
                                            <div className="input-group-append">
                                                <span className="input-group-text" type="button" onClick={(e) => toggleShowNew(e)} onKeyDown={(e) => toggleShowNew(e)}>
                                                    {shownew ? <img src={EyeSlashIcon} width="20" height="20" alt="eyeSlashIcon" /> :
                                                        <img src={EyeIcon} width="20" height="20" alt="eyeSlashIcon" />}
                                                </span>
                                            </div>
                                        </div>
                                        {errors.newMPIN && <p className="errMsg">* {errors.newMPIN.message}</p>}
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label className="formLabel" htmlFor="mpnId3">Confirm MPIN</label>
                                        <div className="input-group mb-3">
                                            <Controller name='confirmMPIN' control={control} render={({ field }) => (
                                                <input
                                                    {...field}
                                                    type={showCon ? "password" : "text"}
                                                    className="form-control"
                                                    maxLength={6}
                                                    onInput={handleKeyDown}
                                                    id="regId8" placeholder="Confirm your MPIN"
                                                    autoComplete='off'
                                                />
                                            )} />
                                            <div className="input-group-append">
                                                <span className="input-group-text" type="button" onClick={(e) => toggleShowCon(e)} onKeyDown={(e) => toggleShowCon(e)}>
                                                    {showCon ? <img src={EyeSlashIcon} width="20" height="20" alt="eyeSlashIcon" /> :
                                                        <img src={EyeIcon} width="20" height="20" alt="eyeSlashIcon" />}
                                                </span>
                                            </div>
                                        </div>
                                        {errors.confirmMPIN && <p className="errMsg">* {errors.confirmMPIN.message}</p>}
                                    </div>
                                </div>
                                <div className="col-lg-4 ml-auto d-flex align-items-center justify-content-end">
                                    {sessionStorage.getItem('type') != 'admin' && !Cookies.get('sdgsdsadgt') ? <button className="btn btn-11545 btnGold" disabled={changeMPINmutation.isLoading || !isValid || !isDirty}>
                                        {changeMPINmutation.isLoading ?
                                            <Dots>Submiting</Dots>
                                            :
                                            "Submit"
                                        }
                                    </button>: <Submitbutton
                                          buttonText="Submit"
                                          loading={changeMPINmutation.isLoading}
                                       />}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChangeMPIN;