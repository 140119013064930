
import { useMutation } from "react-query"
import { useToast } from "../../../hook/usetoast"
import { useNavigate } from "react-router-dom";
import DataService from '../../../config/DataService';
const { postRequest } = DataService()

export const useSignin = () => {
    const toast = useToast();

    return useMutation({
        mutationFn: data => {
            return postRequest("user/login", { ...data })
        },
        onSuccess: success => {
            if (success?.status) {
                return toast.success(success?.message)
            }
            return toast.error(success?.message)
        }
    })
}

export const useSigninTFA = () => {
    const toast = useToast();

    return useMutation({
        mutationFn: data => {
            return postRequest("user/tfa_login", { ...data })
        },
        onSuccess: success => {
            if (success?.status) {
                return toast.success(success?.message)
            }
            return toast.error(success?.message)
        }
    })
}
export const useVerifyLINK = () => {
    const toast = useToast();

    return useMutation({
        mutationFn: data => {
            return postRequest("user/verify_link", { ...data })
        },
        onSuccess: success => {
            if (success?.status) {
                return toast.success(success?.message)
            }
            return toast.error(success?.message)
        }
    })
}

export const useSignup = () => {
    const toast = useToast();

    return useMutation({
        mutationFn: data => {
            return postRequest("user/register", { ...data })
        },
        onSuccess: success => {
            if (!success?.status) {
                return toast.error(success?.message)
            }
        }
    })
}

export const useSendOTP = () => {
    const toast = useToast();

    return useMutation({
        mutationFn: data => {
            return postRequest("user/sent_otp", { ...data })
        },
        onSuccess: success => {
            if (!success?.status) {
                return toast.error(success?.message)
            }
        }
    })
}

export const useVerifyOTP = () => {
    const toast = useToast();

    return useMutation({
        mutationFn: data => {
            return postRequest("user/verify_otp", { ...data })
        },
        onSuccess: success => {
            if (success?.status) {
                return toast.success(success?.message)
            }
            return toast.error(success?.message)
        }
    })
}

// Forgot Password
export const useForgotPass = () => {
    const toast = useToast();
    return useMutation({
        mutationFn: data => {
            return postRequest("user/forgot_pin", { ...data })
        }, onSuccess: success => {
            if (!success?.status) {
                return toast.error(success?.message == 'jwt expired' ?
                    'Reset link expired' : success?.message
                )
            }
        }
    })
}

export const useResetPass = () => {
    const toast = useToast();

    return useMutation({
        mutationFn: data => {
            return postRequest("user/reset_password", { ...data })
        },
        onSuccess: success => {
            if (!success?.status) {
                return toast.error(success?.message == 'jwt expired' ?
                    'Reset link expired' : success?.message
                )
            }
        }
    })
}

export const useResetMPIN = () => {
    const toast = useToast();

    return useMutation({
        mutationFn: data => {
            return postRequest("user/reset_mpin", { ...data })
        },
        onSuccess: success => {
            if (!success?.status) {
                return toast.error(success?.message == 'jwt expired' ?
                    'Reset link expired' : success?.message
                )
            }
        }
    })
}

//checking_link
export const useCheckLink = () => {
    const toast = useToast();
    const navigate = useNavigate()

    return useMutation({
        mutationFn: data => {
            return postRequest("user/checking_link", { ...data })
        },
        onSuccess: success => {
            if (!success?.status) {
                toast.error(success?.message == 'jwt expired' ?
                    'Reset link expired' : success?.message
                )
                return navigate('/')
            }
        }
    })
}