import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import "../../assets/scss/Innerpages/Innerpages.scss";
import xlsIcon from "../../assets/images/xlsIcon.png"
import { usePoolbonusRewardReport } from './api/fetchBusinessStore';
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import Pagination from '../common/pagination/Pagination';
import Nodatafound from '../Nodata/Nodatafound';
import { TransferHistorySkeleton } from '../../Components/Loader/skeleton';
import moment from 'moment';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

const PoolBonusReport = () => {

   const type = useLocation()
   const [page, setPage] = useState(1)
   const size = 10
   const [sort, setSort] = useState('0')
   const status = false
   const [data, setData] = useState(null);
   const [isLoading, setIsLoading] = useState(false);

   const poolbonusreport = usePoolbonusRewardReport();

   useEffect(() => {
      fetchData()
   }, [page, size, sort])

   const fetchData = async () => {
      setIsLoading(true);

      let obj =
      {
         page: page,
         size: size,
         time: +sort,
         status: status,
         type:type?.state?.type,
         sort:"desc"
      }

      try {
         const fetchedData = await poolbonusreport.mutateAsync(obj);
         setData(fetchedData);
      } catch (error) {
      }

      setIsLoading(false);
   };

   const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
   const fileExtension = ".xlsx";

   const handleDownload = async () => {
      let fileName = "poolBonusRewardhistory";

      let obj =
      {
         page: page,
         size: size,
         time: +sort,
         status: true,
         type:type.state.type
      }
      const fetchedData = await poolbonusreport.mutateAsync(obj);
      const ws = XLSX.utils.json_to_sheet(fetchedData?.data);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
   }
   let displayText;
   if (sort === "7") {
       displayText = "1 Week Ago";
   } else if (sort === "30") {
       displayText = "1 Month Ago";
   } else {
       displayText = "Recently";
   }

   return (
      <div className="container">
         <h3 className="secHeading text-center">Pool Bonus Report</h3>

         <div className="row">
            <div className="col-12 mb-5">
               <div className="d-flex align-items-center flex-wrap">
                  <h3 className="cardTitle lgTitle">Pool Commission Report</h3>
                  <div className="d-flex align-items-center filterCnt mb-3 ml-auto flex-wrap flex-md-nowrap">
                    

                  <div className='octDrpdown dark mx-1'>
                     <UncontrolledDropdown>
                           <DropdownToggle caret>
                              {displayText}
                           </DropdownToggle>
                           <DropdownMenu>
                              <DropdownItem tag={"a"} type="button" onClick={() => setSort("0")}>Recently</DropdownItem>
                              <DropdownItem tag={"a"} type="button" onClick={() => setSort("7")}>1 Week Ago</DropdownItem>
                              <DropdownItem tag={"a"} type="button" onClick={() => setSort("30")}>1 Month Ago</DropdownItem>
                           </DropdownMenu>
                     </UncontrolledDropdown>
                  </div>

                     <button className="btn filterBtn my-1" type="button" onClick={handleDownload} disabled={Boolean(!data?.status)}><img src={xlsIcon} width="24" height="24" alt="xlsIcon" className="mr-2" />Export File</button>
                  </div>
               </div>

               <div className="card tableCard">
                  <div className="cardInnerCnt">
                     <div className="table-responsive">
                        <table className="table table-hover">
                           <thead>
                              <tr>
                                 <th>Day</th>
                                 <th>Date</th>
                                 <th>Qualification</th>
                                 <th>Reward</th>
                                 <th className="text-right">Remaining Days</th>
                              </tr>
                           </thead>
                           <tbody>
                              {isLoading ?
                                 <TransferHistorySkeleton />
                                 :
                                 data?.data?.length > 0 ?
                                    data?.data?.map((n, i) => (
                                       <tr key={n}>
                                          <td>{page == 1
                                             ? i + 1
                                             : (page - 1) * 10 +
                                             (i + 1)}</td>
                                          <td>{moment(n.createdAt).format('D/ MM/ YYYY, h:mm:ss')}</td>
                                          <td style={{textTransform:"capitalize"}}>{n.qualifications}</td>
                                          <td>{(!(n.qualifications).startsWith('direct')) && n.capping == 0 ? "NIL (Capping Reached)" : (n.amount)?.toFixed(2)} ANA</td>
                                          <td className="text-right">{n.remainingDays}</td>

                                       </tr>
                                    ))
                                    :
                                    <tr>
                                       <td colSpan={7}><Nodatafound message="No Transactions found." /> </td>
                                    </tr>
                              }
                           </tbody>
                        </table>
                     </div>
                  </div>
               </div>
               <Pagination
                  total={data?.count}
                  current={page}
                  size={size}
                  onPageChange={no => setPage(no)}
               />
            </div>
         </div>
      </div>
   )
}
export default PoolBonusReport;