import React, { useEffect, useState } from 'react';
import { useAchievement } from '../api/fetchBusinessStore';
import { WaitingLoader } from '../../../Components/Loader/buttonLoad';
import { useLocation } from 'react-router-dom';


const BusinessStoreTitan = () => {

   const { state } = useLocation()
   let totalMember = state ? state?.levels : '--'
 

   const premiumAchiement = useAchievement()

   const [data, setData] = useState('')
   const [loading, setLoading] = useState(false)
   const [qualification, setQualification] = useState('')
 


   useEffect(() => {
      const fetchPremium = async () => {
         try {
            setLoading(true)
            setQualification(totalMember)
            const response = await premiumAchiement.mutateAsync({ type: totalMember })
            
            if (response.status) {
               setLoading(false)
               setData(response.data)
            } else {
               setLoading(false)
            }
         } catch (error) {
            setLoading(false)
         }
      }

      fetchPremium()
   }, [totalMember])

 

   let qualificationLabel;

   switch (qualification) {
       case 'executive':
           qualificationLabel = "EXECUTIVE";
           break;
       case 'premium':
           qualificationLabel = "PREMIUM";
           break;
       default:
           qualificationLabel = "TITAN";
           break;
   }
   return (
      <div className="container">
         {loading && <WaitingLoader />}
         <div className="d-flex align-items-center flex-wrap justify-content-center">
            <h3 className="secHeading text-center mr-3">Business Store</h3>
         </div>


         <section className="BisTorsec cmntxt">
            <div className="ashBg mt-5">
               <div className="inrCnt">
                  <div className="row align-items-center justify-content-between borderbtm mx-2">
                     <div className="col-xl-6 col-lg-5 col-md-4 px-0">
                        {qualification === "moonstaker" ? <h4 className="pb-lg-0 pb-3">MOONSTAKER ELIGIBILITY CRITERIA</h4> :

                           <h4 className="pb-lg-0 pb-3">{`${qualificationLabel} DIRECTOR ELIGIBILITY CRITERIA`}</h4>}
                     </div>
                  </div>
                  {qualification === "moonstaker" ?
                     <div className="row mt20">
                        <div className="col-sm-6 col-xl-3 col-lg-4 ttnCnt">
                           <h5 className="heading">Self Stake</h5>
                           <h3 className="value">{data?.adminselfStake ?? "-"}</h3>
                        </div>
                     </div> :
                     <div className="row mt20">
                        <div className="col-sm-6 col-xl-3 col-lg-4 ttnCnt">
                           <h5 className="heading">Team Size</h5>
                           <h3 className="value">{data?.adminteamSize ?? "-"}</h3>
                        </div>
                        <div className="col-sm-6 col-xl-3 col-lg-4 ttnCnt">
                           <h5 className="heading">Direct Members</h5>
                           <h3 className="value">{data?.admindirectMembers ?? "-"}</h3>
                        </div>
                        <div className="col-sm-6 col-xl-3 col-lg-4 ttnCnt">
                           <h5 className="heading">Self Stake</h5>
                           <h3 className="value">{data?.adminselfStake ?? "-"}</h3>
                        </div>
                        {qualification === 'executive' ?
                           <div className="col-sm-6 col-xl-3 col-lg-4 ttnCnt">
                              <h5 className="heading">Leg Count</h5>
                              <h3 className="value">{data?.adminlegCount ?? "-"}</h3>
                           </div>
                           : ""}
                        <div className="col-sm-6 col-xl-3 col-lg-4 ttnCnt">
                           <h5 className="heading">Side Volume Count</h5>
                           <h3 className="value">{data?.adminSideVolumeCount ?? "-"}</h3>
                        </div>
                        <div className="col-sm-6 col-xl-3 col-lg-4 ttnCnt">
                           <h5 className="heading">Side Volume Business</h5>
                           <h3 className="value">{data?.adminSidevolumeBusiness ?? "-"}</h3>
                        </div>
                        {qualification === 'executive' ?
                           <div className="col-sm-6 col-xl-3 col-lg-4 ttnCnt">
                              <h5 className="heading">Leg Stake</h5>
                              <h3 className="value">{data?.adminlegStake ?? "-"}</h3>
                           </div>
                           : ""}
                        {qualification !== 'executive' && (qualification === "premium" || qualification === "titan") ?
                           <div className="col-sm-6 col-xl-3 col-lg-4 ttnCnt">
                              <h5 className="heading">Executive Director count</h5>
                              <h3 className="value">{data?.adminExecutiveCount ?? "-"}</h3>
                           </div>

                           : qualification !== 'executive' ?
                              <div className="col-sm-6 col-xl-3 col-lg-4 ttnCnt">
                                 <h5 className="heading">Premium Director count</h5>
                                 <h3 className="value">{data?.adminPremiumCount ?? "-"}</h3>
                              </div> : ""}
                        {qualification === 'titan' ?
                           <div className="col-sm-6 col-xl-3 col-lg-4 ttnCnt">
                              <h5 className="heading">Premium Director count</h5>
                              <h3 className="value">{data?.adminPremiumCount ?? "-"}</h3>
                           </div>
                           : ""}


                     </div>
                  }


               </div>
            </div>
            <div className="ashBg mt-3">
               <div className="inrCnt">
                  <div className="borderbtm ">
                  {qualification === "moonstaker" ? <h4 className="pb-lg-0 pb-3">MOONSTAKER MY ACHIEVEMENT</h4> :
                     <h4 className="pb-lg-0 py-3">{`${qualification === 'executive' ? "EXECUTIVE" : qualification === 'premium' ? "PREMIUM" : "TITAN"} DIRECTOR MY ACHIEVEMENT`}</h4>}
                  </div>
                  {qualification === "moonstaker" ?
                     <div className="row mt20">
                        <div className="col-sm-6 col-xl-3 col-lg-4 ttnCnt">
                           <h5 className="heading">Self Stake</h5>
                           <h3 className="value">{data?.selfStake ?? "-"}</h3>
                        </div>
                     </div>
                     :
                     <div className="row mt20">
                        <div className="col-sm-6 col-xl-3 col-lg-4 ttnCnt">
                           <h5 className="heading">Team Size</h5>
                           <h3 className="value">{data?.teamSize ?? "-"}</h3>
                        </div>
                        <div className="col-sm-6 col-xl-3 col-lg-4 ttnCnt">
                           <h5 className="heading">Direct Members</h5>
                           <h3 className="value">{data?.directMembers ?? "-"}</h3>
                        </div>
                        <div className="col-sm-6 col-xl-3 col-lg-4 ttnCnt">
                           <h5 className="heading">Self Stake</h5>
                           <h3 className="value">{data?.selfStake ?? "-"}</h3>
                        </div>
                        {qualification === 'executive' ?

                           <div className="col-sm-6 col-xl-3 col-lg-4 ttnCnt">
                              <h5 className="heading">Leg Count</h5>
                              <h3 className="value">{data?.legCount ?? "-"}</h3>
                           </div>
                           : ""}



                        <div className="col-sm-6 col-xl-3 col-lg-4 ttnCnt">
                           <h5 className="heading">Side Volume Count</h5>
                           <h3 className="value">{data?.sidevolumeCount ?? "-"}</h3>
                        </div>

                        <div className="col-sm-6 col-xl-3 col-lg-4 ttnCnt">
                           <h5 className="heading">Side Volume Business</h5>
                           <h3 className="value">{data?.sidevolumeBusiness ?? "-"}</h3>
                        </div>
                        {qualification === 'executive' ?

                        <div className="col-sm-6 col-xl-3 col-lg-4 ttnCnt">
                           <h5 className="heading">Leg Stake</h5>
                           <h3 className="value">{data?.legStake ?? "-"}</h3>
                        </div>
                        :""}
                        {qualification !== 'executive' && (qualification === "premium" || qualification === "titan") ?

                           <div className="col-sm-6 col-xl-3 col-lg-4 ttnCnt">
                              <h5 className="heading">Executive Director count</h5>
                              <h3 className="value">{data?.executiveCount ?? "-"}</h3>
                           </div>


                           : qualification !== 'executive' ?
                              <div className="col-sm-6 col-xl-3 col-lg-4 ttnCnt">
                                 <h5 className="heading">Premium Director Count </h5>
                                 <h3 className="value">{data?.premiumCount ?? "-"}</h3>

                              </div>
                              : ""
                        }
                        {qualification === 'titan' ?
                           <div className="col-sm-6 col-xl-3 col-lg-4 ttnCnt">
                              <h5 className="heading">Premium Director count</h5>
                              <h3 className="value">{data?.premiumCount ?? "-"}</h3>
                           </div>
                           : ""}
                     </div>
                  }
               </div>
            </div>
         </section>
      </div>
   )
}
export default BusinessStoreTitan;