const Converter = {

    formatDated: function (date) {
        date = new Date(date);
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let dates = date.getDate();
        let months = date.getMonth() + 1;
        let years = date.getFullYear();
        let ampm = hours >= 12 ? "PM" : "AM";
        hours = hours % 12;
       
        hours = hours < 10 ? "0" + hours : hours;
        minutes = minutes < 10 ? "0" + minutes : minutes;
        dates = dates < 10 ? "0" + dates : dates;
        months = months < 10 ? "0" + months : months;
        let strTime = hours + ":" + minutes + " " + ampm;
        return dates + "/" + months + "/" + years + " | " + strTime;
    },

    formatDateandMonth: function (date) {

        date = new Date(date);
        let dates = date.getDate();
        let months = date.getMonth() + 1;
        let years = date.getFullYear();
        dates = dates < 10 ? "0" + dates : dates;
        months = months < 10 ? "0" + months : months;
        return dates + "/" + months + "/" + years;
    },

    expireDate: function (date) {

        date = new Date(date);
        let dates = date.getDate();
        let months = date.getMonth() + 1;
        let years = date.getFullYear();
        dates = dates < 10 ? "0" + dates : dates;
        months = months < 10 ? "0" + months : months;

        return dates + "/" + months + "/" + years;
    },

    DurationStatus: function (temp) {

        let formate;
        let Enddate = new Date(temp).getTime()
        let countDownDate = Enddate * 1000;
        let now = new Date().getTime();
        let timeleft = countDownDate - now;

        if (timeleft < 0) {
            formate = "Completed"
        } else {
            formate = "Active"
        }
        return formate
    },

    ThousnadFormate: function (x) {
        let temp = Number(x).toLocaleString("en-US", { maximumFractionDigits: 2 })
        return temp?.toLocaleString() || '';
    },

    formattedValue : function (x) {
        let formatteValue = 0
        if (x !== undefined) {
            formatteValue = Number(x) % 1 === 0 ? x.toString() : Number(x).toFixed(6);
        } else {
            formatteValue = '0.000000'; // Or handle the case when value is undefined as you see fit
        }
        return formatteValue
    }
};

export default Converter;
