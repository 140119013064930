import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import "../../assets/scss/Innerpages/Innerpages.scss";
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import AnaWalletTransfer from '../StakeStore/stakeStore';
import RedeemStore from '../RedeemStore/redeemStore';
import TransferStore from '../Transfer/TransferStore';
import { useAnawalletBalance } from '../common/fetchBalance';
import { ButtonLoader } from '../../Components/Loader/buttonLoad';
import WalletHistory from './tabs/walletHistory';
import ReceivedHistory from './tabs/receivedHistory';

import { useGetIcoDetails } from './api/Anawalletapi';
import { useToast } from '../../hook/usetoast';

const AnaWallet = () => {

   const { data: getwalletBalance, isLoading } = useAnawalletBalance();
   const { data: getIco } = useGetIcoDetails();
   const toast = useToast()

   const [modalstake, setModalstake] = useState(false);
   const toggle = () => setModalstake(!modalstake);
   const [modalRedeem, setModalRedeem] = useState(false);
   const toggleRedeem = () => {
      setModalRedeem(false)
      return toast.error('Currently unavailable.')
   };
   const [modalTransfer, setModalTransfer] = useState(false);
   const toggleTransfer = () => {
      setModalTransfer(false)
      return toast.error('Currently unavailable.')
   }

   useEffect(() => {
      window.scrollTo(0, 0)
    }, [])

    const renderWalletBalance = () => {
      if (isLoading) {
          return <ButtonLoader />;
      }
  
      const anabalance = getwalletBalance?.balance?.anabalance;
      if (!anabalance) {
          return 0;
      }
  
      return anabalance.toString().includes('.') ? anabalance.toFixed(6) : anabalance;
  };

 
  const formatReceivedAna = (receivedAna) => {
   if (receivedAna) {
       return receivedAna.toString().includes('.') ? receivedAna.toFixed(6) : receivedAna;
   }
   return 0; 
};

const formatWalletBalance = (balance) => {
   if (!balance || balance.totalTransfered === undefined) {
       return 0;
   }
   const totalTransferred = balance.totalTransfered;
   return totalTransferred.toString().includes('.')
       ? totalTransferred.toFixed(6)
       : totalTransferred;
}

const formatStakeReceived = (balance) => {
   if (!balance || balance.totalStakereceived === undefined) {
       return 0;
   }
   
   const totalStakeReceived = balance.totalStakereceived;
   return totalStakeReceived.toString().includes('.')
       ? totalStakeReceived.toFixed(6)
       : totalStakeReceived;
}
const formatTotalRedeem = (balance) => {
   if (!balance || balance.totalRedeem === undefined) {
       return 0;
   }
   
   const TotalRedeem = balance.totalRedeem;
   return TotalRedeem.toString().includes('.')
       ? TotalRedeem.toFixed(6)
       : TotalRedeem;
}
   return (
      <div className="container animated_div">
         <h3 className="secHeading text-center">ANA Wallet</h3>

         <div className="row">
            <div className="col-lg-12">
               <div className="card">
                  <div className="cardInnerCnt">
                     <div className="row">
                        <div className="col-lg-4 bdrCnt">
                           <div className="mb-3">
                              <h5 className="cardLabel mb-0">Total ANA Available</h5>
                              <h5 className="cardValue mb-0">
                              {renderWalletBalance()}ANA</h5>
                           </div>
                           <div className="d-flex flex-column">
                              {getIco?.message == "All phases end" ? "" :<Link className="cardLink mb-2" onClick={toggle}>Move To Stake</Link>}
                              <Link className="cardLink mb-2" onClick={toggleTransfer}>Move To Transfer</Link>
                              <Link className="cardLink" onClick={toggleRedeem}>Move To Redeem Store</Link>
                           </div>
                        </div>
                        <div className="col-lg-4 bdrCnt px-lg-5">
                           <div className="mb-5">
                              <h5 className="cardLabel mb-0">Total ANA Received</h5>
                              <h5 className="cardValue mb-0">{isLoading ? <ButtonLoader /> : formatReceivedAna(getwalletBalance?.balance?.receivedAna)} ANA</h5>
                           </div>
                           <div>
                              <h5 className="cardLabel mb-0">ANA In Transfer Store</h5>
                              <h5 className="cardValue mb-0">{isLoading ? <ButtonLoader /> : formatWalletBalance(getwalletBalance?.balance)} ANA</h5>
                           </div>
                        </div>
                        <div className="col-lg-4 px-lg-5">
                           <div className="mb-5">
                              <h5 className="cardLabel mb-0">ANA In Stake Store</h5>
                              <h5 className="cardValue mb-0">{isLoading ? <ButtonLoader /> : formatStakeReceived(getwalletBalance?.balance)} ANA</h5>
                           </div>
                           <div>
                              <h5 className="cardLabel mb-0">ANA In Redeem Store</h5>
                              <h5 className="cardValue mb-0">{isLoading ? <ButtonLoader /> : formatTotalRedeem(getwalletBalance?.balance)} ANA</h5>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <WalletHistory />
            <ReceivedHistory />
         </div>


         <Modal isOpen={modalstake} toggle={toggle} modalClassName="authModal">
            <ModalHeader toggle={toggle}></ModalHeader>
            <ModalBody>
               <AnaWalletTransfer toggle={toggle} />
            </ModalBody>
         </Modal>
         <Modal isOpen={modalRedeem} toggle={toggleRedeem} modalClassName="authModal">
            <ModalHeader toggle={toggleRedeem}></ModalHeader>
            <ModalBody>
               <RedeemStore toggleRedeem={toggleRedeem} />
            </ModalBody>
         </Modal>
         <Modal isOpen={modalTransfer} toggle={toggleTransfer} modalClassName="authModal">
            <ModalHeader toggle={toggleTransfer}></ModalHeader>
            <ModalBody>
               <TransferStore toggleTransfer={toggleTransfer} />
            </ModalBody>
         </Modal>
      </div>
   )
}
export default AnaWallet;