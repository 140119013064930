import "../../assets/scss/CopyRight/CopyRight.scss"
import { useModalContext } from "../../contexts/ModalContext";

const CopyRight = () => {

   const { siteSettings } = useModalContext();
   return (
      <div className="copyRightTx text-center" data-scroll-section>
         <div className="container">
            <p>{siteSettings?.copyright}</p>
         </div>
      </div>
   )
}
export default CopyRight;