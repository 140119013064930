import React, { useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useChangePassword } from '../api/fetchprofile';
import { Dots } from '../../../lib/dots';
import EyeSlashIcon from '../../../assets/images/Auth/eye-slash.png'
import EyeIcon from '../../../assets/images/Auth/eye.png'
import { WaitingLoader } from '../../../Components/Loader/buttonLoad';
import Cookies from "js-cookie"
import { Submitbutton } from '../../common/Submitbutton';
import { generateSecureRandomNumber } from '../../../hook/generateSecureRandomNumber';
const schema = yup.object().shape({
    MPIN: yup.string().required('MPIN is Required'),
    currentPassword: yup.string().required('Password is Required'),
    newPassword: yup.string()
        .min(6, 'Password must be at least 6 characters')
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,30}$/, 'Password must contain at least one uppercase, one lowercase, one number and one special character')
        .max(30, 'Password must be at most 30 characters')
        .notOneOf([yup.ref('currentPassword'), null], "New Password can't be same old password")
        .required('Password is Required'),
    confirmNewPassword: yup.string()
        .oneOf([yup.ref('newPassword'), null], "Confirm Password doesn't match")
        .required('Confirm Password is Required'),
})

function ChangePassword() {

    const [show, setShow] = useState(true);
    const [shownew, setShownew] = useState(true);
    const [showCon, setShowCon] = useState(true);
    const [mpshow, setMpShow] = useState(true);
    const [loading, setLoading] = useState(false)

    const changePasswordmutation = useChangePassword()

    const { control, handleSubmit, formState: { errors, isValid, isDirty }, reset } = useForm({
        mode: 'onChange',
        resolver: yupResolver(schema),
        defaultValues: {
            MPIN: '',
            currentPassword: "",
            newPassword: "",
            confirmNewPassword: "",
        }
    });

    
    const handleKeyDown = (e) => {
     
        const regex = /^\d{0,6}$/;
      
        const value = e.target.value + e.key; 
       
        if (!regex.test(value)) {
          e.preventDefault();
        }
      };
    const onSubmit = async (data) => {
        setLoading(true)
        let random = Math.floor(generateSecureRandomNumber() * 10000)
        sessionStorage.setItem('random', random)
        data["random"] = random
        await changePasswordmutation.mutateAsync(data)
        setLoading(false)
        reset()
    }

    const toggleShow = async (e) => {
        if (e.key === "Enter" || e.nativeEvent.pointerType === "mouse" || e.nativeEvent.pointerType === "touch") {
            setShow(!show)
        }
    }

    const toggleShowNew = async (e) => {
        if (e.key === "Enter" || e.nativeEvent.pointerType === "mouse" || e.nativeEvent.pointerType === "touch") {
            setShownew(!shownew);
        }
    }

    const toggleShowCon = async (e) => {
        if (e.key === "Enter" || e.nativeEvent.pointerType === "mouse" || e.nativeEvent.pointerType === "touch") {
            setShowCon(!showCon)
        }
    }

    const toggleMpShow = async (e) =>{
        if (e.key === "Enter" || e.nativeEvent.pointerType === "mouse" || e.nativeEvent.pointerType === "touch") {
            setMpShow(!mpshow)
        }
    }

    return (
        <div className="row">
            {loading && <WaitingLoader />}
            <div className="col-12">
                <div className="card">
                    <div className="cardInnerCnt">
                        <div className="cardHeader">
                            <h3>Change Password</h3>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label className="formLabel" htmlFor="pwdId1">Current Password</label>
                                        <div className="input-group mb-3">
                                            <Controller name='currentPassword' control={control} render={({ field }) => (
                                                <input
                                                    {...field}
                                                    type={show ? "password" : "text"}
                                                    className="form-control"
                                                    placeholder='Enter your Current Password'
                                                    autoComplete='off'
                                                />
                                            )} />
                                            <div className="input-group-append">
                                                <span className="input-group-text" type="button" onClick={(e) => toggleShow(e)} onKeyDown={(e) => toggleShow(e)}>
                                                    {show ? <img src={EyeSlashIcon} width="20" height="20" alt="eyeSlashIcon" /> :
                                                        <img src={EyeIcon} width="20" height="20" alt="eyeSlashIcon" />}
                                                </span>
                                            </div>
                                        </div>
                                        {errors.currentPassword && <p className="errMsg">* {errors.currentPassword.message}</p>}
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label className="formLabel" htmlFor="pwdId1">New Password</label>
                                        <div className="input-group mb-3">
                                            <Controller name='newPassword' control={control} render={({ field }) => (
                                                <input
                                                    {...field}
                                                    type={shownew ? "password" : "text"}
                                                    className="form-control"
                                                    placeholder='Enter your New Password'
                                                    autoComplete='off'
                                                />
                                            )} />
                                            <div className="input-group-append">
                                                <span className="input-group-text" type="button" onClick={(e) => toggleShowNew(e)} onKeyDown={(e) => toggleShowNew(e)}>
                                                    {shownew ? <img src={EyeSlashIcon} width="20" height="20" alt="eyeSlashIcon" /> :
                                                        <img src={EyeIcon} width="20" height="20" alt="eyeSlashIcon" />}
                                                </span>
                                            </div>
                                        </div>
                                        {errors.newPassword && <p className="errMsg">* {errors.newPassword.message}</p>}
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label className="formLabel" htmlFor="pwdId2">Confirm Password</label>
                                        <div className="input-group mb-3">
                                            <Controller name='confirmNewPassword' control={control} render={({ field }) => (
                                                <input
                                                    {...field}
                                                    type={showCon ? "password" : "text"}
                                                    className="form-control"
                                                    id="regId8" placeholder="Confirm your Password"
                                                    autoComplete='off'
                                                />
                                            )} />
                                            <div className="input-group-append">
                                                <span className="input-group-text" type="button" onClick={(e) => toggleShowCon(e)} onKeyDown={(e) => toggleShowCon(e)}>
                                                    {showCon ? <img src={EyeSlashIcon} width="20" height="20" alt="eyeSlashIcon" /> :
                                                        <img src={EyeIcon} width="20" height="20" alt="eyeSlashIcon" />}
                                                </span>
                                            </div>
                                        </div>
                                        {errors.confirmNewPassword && <p className="errMsg">* {errors.confirmNewPassword.message}</p>}
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label className="formLabel" htmlFor="pwdId3">MPIN</label>
                                        <div className="input-group mb-3">
                                            <Controller name='MPIN' control={control} render={({ field }) => (
                                                <input
                                                    {...field}
                                                    type={mpshow ? "password" : "text"}
                                                    className="form-control"
                                                    onInput={handleKeyDown}
                                                    maxLength={6}
                                                    id="regId9" placeholder="Enter your MPIN"
                                                    autoComplete='off'
                                                />
                                            )} />
                                            <div className="input-group-append">
                                                <span className="input-group-text" type="button" onClick={(e)=>toggleMpShow(e)} onKeyDown={(e)=>toggleMpShow(e)}>
                                                    {mpshow ? <img src={EyeSlashIcon} width="20" height="20" alt="eyeSlashIcon" /> :
                                                        <img src={EyeIcon} width="20" height="20" alt="eyeSlashIcon" />}
                                                </span>
                                            </div>
                                        </div>
                                        {errors.MPIN && <p className="errMsg">* {errors.MPIN.message}</p>}
                                    </div>
                                </div>
                                <div className="col-lg-4 ml-auto d-flex align-items-center justify-content-end">
                                    {sessionStorage.getItem('type') != 'admin' && !Cookies.get('sdgsdsadgt') ? <button className="btn btn-11545 btnGold" disabled={changePasswordmutation.isLoading || !isValid || !isDirty}>
                                        {changePasswordmutation.isLoading ?
                                            <Dots>Submiting</Dots>
                                            :
                                            "Submit"
                                        }
                                    </button> : <Submitbutton
                                        buttonText="Submit"
                                        loading={changePasswordmutation.isLoading}
                                    />}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChangePassword