import { useMutation } from "react-query"
import StakeService from "../../../config/StakeService";
const { stakepost } = StakeService()


export const useCodeHistory = () => {
    return useMutation({
        mutationFn: (data) => {
            return stakepost("user/getCoupon", { ...data })
        },
        select: res => res.data
    });
};


export const useBusinessvolume = () => {
    return useMutation({
        mutationFn: (data) => {
            return stakepost("user/BusinnesReportDate", { ...data })
        },
        select: res => res.data
    });
};