import { Link } from "react-router-dom";
import "../../../../assets/scss/Home/Banner/Banner.scss";
import PropTypes from 'prop-types'; 
import { BounceInDownButton } from "../../../../Components/animation/bouncein";


import pdfs from './ANACOIN.pdf'
const Banner = ({ data }) => {
   let token = sessionStorage.getItem('loginno')
   return (
      <div className="bnr d-lg-flex justify-content-center align-items-center">
         <div className="container">
            <div className="bnrTx text-center text-lg-left">
               <div className="row">
                  <div className="col-lg-6 pr-0 pr-lg-4">
                     <h3>
                        <span>
                           {data?.status ? data?.data[0]?.title : ''}
                        </span>
                     </h3>
                     <h1 data-aos="fade-up" data-aos-duration="1000" data-aos-anchor-placement="top-bottom">
                        <span>
                           {data?.status ? data?.data[0]?.title1 : ''}
                        </span>
                     </h1>
                     <p dangerouslySetInnerHTML={{ __html: data?.status ? data?.data[0]?.pagecontent : '' }} />
                     <BounceInDownButton>
                        <div className="d-flex justify-content-center justify-content-lg-start align-items-center flex-wrap">
                           <a href={pdfs} download="ANACOIN.pdf" style={{textDecoration: 'none'}}>                           <button  className="btn btnGoldOutline mr-3 my-1">Whitepaper</button>
                           </a>
                           <Link to="/dashboard" className="btn btnGold my-1">{token?"Dashboard":"Get Started"}</Link>
                        </div>
                     </BounceInDownButton>
                  </div>
               </div>
            </div>
         </div>
         <div className="bannerRightCnt">
            <div className="bannerInnerCnt">
               <video autoPlay muted loop>
                     <source src="https://octaverselive.s3.amazonaws.com/basc-coin-copy.mp4" type="video/mp4" />
               </video>
            </div>
         </div>
      </div>
   )
}
Banner.propTypes ={
   data: PropTypes.shape({
         status: PropTypes.any,
         data: PropTypes.arrayOf(
            PropTypes.shape({
               title: PropTypes.any,
               title1: PropTypes.any,
               pagecontent : PropTypes.any
            })
        ),
    }), 
}
export default Banner;