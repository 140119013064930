import "../../assets/scss/Loader/Loader.scss";
import logoLg from "../../assets/images/logoLg.png";
import PropTypes from 'prop-types';

const Loader = ({ loaded }) => {

   return (
      <div className={`siteLoaderCnt ${loaded ? "" : "loaded"}`.trim()}>
         <div className="loaderInnerDiv">
            <div className="d-flex justify-content-center align-items-center loaderCoin">
               <img src={logoLg} width="200" height="200" alt="Loader" className="img-fluid" />
            </div>
            <div className="d-flex justify-content-center align-items-center bnrTx">
               <div className="spinner"></div>
            </div>
         </div>

      </div>
   )
}
Loader.propTypes ={
   loaded : PropTypes.any
}
export default Loader;