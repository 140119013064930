import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import "../../assets/scss/Innerpages/Innerpages.scss";
import logoLg from "../../assets/images/logoLg.png";
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import EyeSlashIcon from '../../assets/images/Auth/eye-slash.png'
import EyeIcon from '../../assets/images/Auth/eye.png'
import { useGetCurrentPhase, useUpdateStakeStore, useYieldDetails } from './api/fetchstakestore';
import { useBalance } from '../common/fetchBalance';
import { ButtonLoader, WaitingLoader } from '../../Components/Loader/buttonLoad';
import { BounceInDownButton } from '../../Components/animation/bouncein';
import { useModalContext } from '../../contexts/ModalContext';
import { Submitbutton } from '../common/Submitbutton';
import Converter from '../../lib/converter';
import { useToast } from '../../hook/usetoast';
import Ended from '../Phasecompletion/Ended';
import { useGetIcoDetails } from '../ANAwallet/api/Anawalletapi';
import { generateSecureRandomNumber } from '../../hook/generateSecureRandomNumber';

const StakingStore = () => {

   const [yeild, setYeild] = useState("0.0")
   const { data: getIco } = useGetIcoDetails();
   const [yieldValue, setYieldValue] = useState("---");
   const useYieldDetailsz = useYieldDetails()
   const updatestakemutation = useUpdateStakeStore();
   const { data: getBalance, isLoading } = useBalance();
   const { data: currentPhasemutation, isLoading: phaseLoading } = useGetCurrentPhase()


   const schema = yup.object().shape({
      stakeChart: yup.number()
         .required('Stake amount is required')
         .typeError('Stake amount is required')
         .test((val, idBack) => {
            if (getBalance?.totalStaked === 0) {
               if (val >= currentPhasemutation?.minStake ) {
                  let value = val / 100;
                  if (!Number.isInteger(value) && currentPhasemutation?.minStake != val) {
                     return idBack.createError({ message: `Amount must be multiples of 100` });
                  }
                  return val;
               } else {
                  return idBack.createError({ message: `First time stake must be at least ${currentPhasemutation.minStake}` });
               }
            } else {
               // For other cases when totalStaked is not 0
               let value = val / 100;
               if (!Number.isInteger(value)) {
                  return idBack.createError({ message: `Amount must be multiples of 100` });
               }
               return val;
            }
         })
         .test(
            'greater-than',
            'Insufficient balance',
            function (value) {
               if (getBalance && getBalance?.totalavailable !== undefined) {
                  return +value <= getBalance?.totalavailable;
               }
               return true;
            }
         )
         .max(Number(sessionStorage.getItem("maxStake")),
            `Stake amount exceeds maximum stake of ${sessionStorage.getItem("maxStake")}`)
      ,
      noOfDays: yup.string()
         .required("Number of days is required"),
      MPIN: yup.string()
         .required('MPIN is Required')
         .matches(/^\d{6}$/, 'MPIN must be 6 digits'),
   })

   const { siteSettings } = useModalContext();

   const [dropdownOpenOne, setDropdownOpenOne] = useState(false);
   const toggleOne = () => setDropdownOpenOne((prevState) => !prevState);

   const [show, setShow] = useState(true);
   const toggleShow = (e) => { if (e.key === "Enter" || e.nativeEvent.pointerType === "mouse" || e.nativeEvent.pointerType === "touch") { setShow(!show) } }

   const { control, handleSubmit, formState: { errors }, reset, watch, setValue, getValues } = useForm({
      mode: 'onChange',
      resolver: yupResolver(schema),
      defaultValues: {
         stakeChart: '',
         noOfDays: "",
         MPIN: "",
      }
   });

   const watchpersonalId = watch("noOfDays")
   


   const handleKeyDown = (e) => {
     
      const regex = /^\d{0,10}/;
    
      const value = e.target.value + e.key; 
     
      if (!regex.test(value)) {
        e.preventDefault();
      }
    };
    
   useEffect(() => {

      if (currentPhasemutation) {
         sessionStorage.setItem("maxStake", currentPhasemutation?.maxStake)
         setValue("previousStake", getBalance?.previousStake)
      }
   }, [currentPhasemutation])

   const handleChange = (stake, days) => {
      setYeild(days);
      if (stake && days) {
         let value = Number(days) / 100;
         value = value * Number(stake)
         setYieldValue(value)
      }
   }

   let x = Math.floor(generateSecureRandomNumber() * 90000) + 10000;
   const toast = useToast()
   const onsubmit = async (value) => {
      try {
         if (getBalance?.totalavailable < value?.stakeChart) {
            toast.error("Insufficient Balance")
         } else {
            
            const currentStake = value?.stakeChart;
           
               const data = {
                  MPIN: value?.MPIN,
                  noOfdays: Number(value.noOfDays),
                  stakeValue: currentStake,
                  phase: currentPhasemutation?.phase,
                  yield: yeild
               }
               data["randomId"] = x.toString();
               let res = await updatestakemutation.mutateAsync(data);
               if (res.status) {
                  reset();
               }
            
         }
      } catch (error) {

      }

   }

   const endRoute = "/stake-view"
   
   let balanceDisplay;

   if (getBalance) {
       if (getBalance.totalavailable > 0) {
           balanceDisplay = Converter.formattedValue(getBalance.totalavailable) + " ANA";
       } else if (getBalance.totalavailable === 0) {
           balanceDisplay = "0 ANA";
       } else {
           balanceDisplay = "";
       }
   } else {
       balanceDisplay = "";
   }
   
   return (
      <>
         {getIco?.message == "All phases end" ? <Ended endRoute={endRoute} /> : <div className="container animated_div">
            {phaseLoading || updatestakemutation.isLoading && <WaitingLoader />}
            <h3 className="secHeading text-center">Staking Store</h3>
            <div className="row justify-content-center">
               <div className="col-lg-10">
                  <div className="row">
                     <div className="col-lg-4 col-xl-3">
                        <div className="card p-3 w-100">
                           <div className="cardInnerCnt">
                              <h3 className="cardHeading text-center">Stake Vault</h3>
                              <div className="goldCnt">
                                 <h5 className="cardLabel mb-0">Total available</h5>
                                 <h3 className="cardValue mb-0 d-flex align-items-center">
                                    <img src={logoLg} width="22" height="22" alt="logoLg" className="mr-2" />
                                    {isLoading ?
                                       <ButtonLoader /> 
                                       : balanceDisplay
                                    }
                                 </h3>
                              </div>
                              <div className="coinInfoCnt unHgt">
                                 <div className="coinInfoRow d-flex align-items-start">
                                    <div className="d-flex flex-column">
                                       <h5 className="cardLabel mb-0">Total Staked</h5>
                                       {isLoading ?
                                          <h5 className="cardLabel whiteTxt mb-0"><ButtonLoader /></h5> :
                                          <h5 className="cardLabel whiteTxt mb-0">{getBalance?.totalStaked > 0 ? Converter.formattedValue(getBalance?.totalStaked) + " ANA" : getBalance?.totalStaked == 0 ? "0 ANA" : ""}</h5>}
                                    </div>
                                 </div>
                                 <hr className="my-1" />
                                 <div className="coinInfoRow d-flex align-items-start">
                                    <div className="d-flex flex-column">
                                       <h5 className="cardLabel mb-0">Total Received</h5>
                                       {isLoading ?
                                          <h5 className="cardLabel whiteTxt mb-0"><ButtonLoader /></h5> :
                                          <h5 className="cardLabel whiteTxt mb-0">{getBalance?.totalreceived > 0 ? Converter.formattedValue(getBalance?.totalreceived) + " ANA" : getBalance?.totalreceived == 0 ? "0 ANA" : ""} </h5>}
                                    </div>
                                 </div>
                                 <hr className="my-1" />
                                 <div className="coinInfoRow d-flex align-items-start">
                                    <div className="d-flex flex-column">
                                       <h5 className="cardLabel mb-0">Maximum Stake Limit</h5>
                                       {phaseLoading ?
                                          <h5 className="cardLabel mb-0"><ButtonLoader /></h5> :
                                          <h5 className="cardLabel mb-0">{currentPhasemutation?.maxStake > 0 ? Converter.formattedValue(currentPhasemutation?.maxStake) + "ANA" : currentPhasemutation?.maxStake == 0 ? "0 ANA" : ""} </h5>}
                                    </div>
                                 </div>
                                 <hr className="my-1" />
                                 <div className="coinInfoRow d-flex align-items-start">
                                    <div className="d-flex flex-column">
                                       <h5 className="cardLabel mb-0">Possible Stake</h5>
                                       {phaseLoading ?
                                          <h5 className="cardLabel mb-0"><ButtonLoader /></h5> :
                                          <h5 className="cardLabel mb-0">{currentPhasemutation?.maxStake ? currentPhasemutation?.maxStake > 0 || getBalance?.activeStake > 0 ? Converter.formattedValue(currentPhasemutation?.maxStake - getBalance?.activeStake) + " ANA " : currentPhasemutation?.maxStake == 0 && getBalance?.activeStake == 0 ? "0 ANA" : "" : ""}</h5>}
                                    </div>
                                 </div>

                              </div>
                           </div>
                        </div>
                     </div>

                     <div className="col-lg-8 col-xl-9">
                        <BounceInDownButton>
                           <div className="card walletCard">
                              <div className="px-3 cardInnerCnt">
                                 <div className="cardHeader">
                                    <div className="d-flex flex-column flex-sm-row align-items-center">
                                       <h3>Stake</h3>
                                       <div className="d-flex ml-sm-auto">
                                          <Link to="/stake-view" className="outlinedBtn m-1">Stake View</Link>
                                       </div>
                                    </div>
                                 </div>
                                 <form onSubmit={handleSubmit(onsubmit)}>
                                    <div className="form-group">
                                       <label className="formLabel" htmlFor="Id1">Stake Amount</label>
                                       <div className="input-group">
                                          <Controller name='stakeChart' control={control} render={({ field }) => (
                                             <input
                                                {...field}
                                                type="number"
                                                className={`form-control  ${errors.stakeChart && "is-invalid"}`}
                                                placeholder="Enter stake amount"
                                                maxLength={6}
                                                onChange={e => {
                                                   field.onChange(e.target.value);
                                                   handleChange(e.target.value, yeild);
                                                }}
                                                onWheel={(e) => e.target.blur()}
                                                onInput={handleKeyDown}
                                                autoComplete='off'
                                             />
                                          )} />
                                          <span className="input-group-text">
                                             <img src={logoLg} width="20" height="20" alt="usdt" />
                                             <span className="ml-2 whiteTxt">ANA</span>
                                          </span>
                                       </div>
                                    </div>
                                    {errors.stakeChart && <p className="errMsg">* {errors.stakeChart.message}</p>}
                                    <div className="form-group">
                                       <label className="formLabel" htmlFor="Id2">Number of Days</label>
                                       <div className='octDrpdown'>
                                          <Dropdown isOpen={dropdownOpenOne} toggle={toggleOne}>
                                             <DropdownToggle caret>{getValues("noOfDays") || "Select Days"}</DropdownToggle>
                                             <DropdownMenu>
                                                {useYieldDetailsz?.data && useYieldDetailsz?.data?.map((data =>
                                                   <DropdownItem onClick={() => {
                                                      setValue("noOfDays", data?.days);
                                                      handleChange(getValues("stakeChart"), data.percentage);
                                                   }
                                                   }>{data?.days} Days</DropdownItem>
                                                ))}
                                             </DropdownMenu>
                                          </Dropdown>
                                       </div>
                                       {!watchpersonalId && errors.noOfDays && <p className="errMsg">* {errors.noOfDays.message}</p>}
                                    </div>

                                    <div className="form-group">
                                       <label className="formLabel" htmlFor="loginId2">MPIN</label>
                                       <div className="input-group mb-3">
                                          <Controller name='MPIN' control={control} render={({ field }) => (
                                             <input
                                                {...field}
                                                type={show ? "password" : "text"}
                                                className={`form-control  ${errors.MPIN && "is-invalid"}`}
                                                placeholder="Enter Your MPIN"
                                                maxLength={6}
                                                onInput={handleKeyDown}
                                                autoComplete='off'
                                             />
                                          )} />
                                          <div className="input-group-append">
                                             <span className="input-group-text" type="button">
                                                {show ?
                                                   <button style={{ all: 'unset', cursor: 'pointer' }} onClick={(e) => toggleShow(e)} onKeyDown={(e) => toggleShow(e)}>
                               
                                                      <img src={EyeSlashIcon} width="20" height="20" alt="eyeSlashIcon"  />
                                                   </button>
                                                   :
                                                   <button style={{ all: 'unset', cursor: 'pointer' }} onClick={(e) => toggleShow(e)} onKeyDown={(e) => toggleShow(e)}>
                               
                                                      <img src={EyeIcon} width="20" height="20" alt="eyeSlashIcon"  />
                                                   </button>
                                                }
                                             </span>
                                          </div>
                                       </div>
                                       {errors.MPIN && <p className="errMsg">* {errors.MPIN.message}</p>}
                                    </div>
                                    <div className="d-flex align-items-center">
                                       <h5 className="cardLabel my-1">Yield Percentage</h5>
                                       <h5 className="cardLabel whiteTxt my-1 ml-sm-auto">{yeild} %</h5>
                                    </div>
                                    <div className="d-flex align-items-center">
                                       <h5 className="cardLabel my-1">Yield Value</h5>
                                       <h5 className="cardLabel whiteTxt my-1 ml-sm-auto">{yieldValue}</h5>
                                    </div>
                                    <div className="d-flex justify-content-center">
                                       <Submitbutton
                                          buttonText="Confirm"
                                          loading={updatestakemutation.isLoading}
                                          Status={siteSettings?.stakeStore_status}
                                          message="Stake option disabled. Users can't stake temporarily"
                                       />
                                    </div>
                                 </form>

                              </div>
                           </div>
                        </BounceInDownButton>
                     </div>
                  </div>
               </div>
            </div>
         </div>}
      </>
   )
}
export default StakingStore;