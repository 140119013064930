import React from 'react';
import paginationPrev from "../../../assets/images/paginationPrev.png";
import { usePaginationRange } from './usePagination';
import PropTypes from 'prop-types';

const Pagination = ({ total, size, current, onPageChange = 1 }) => {

    const totalPages = Math.ceil(total / size);
    const paginationRange = usePaginationRange({ total, current , size });

    const handlePrevClick = () => {
        if (current > 1) {
            onPageChange(current - 1);
        }
    };

    const handleNextClick = () => {
        if (current < totalPages) {
            onPageChange(current + 1);
        }
    };

    if (paginationRange?.length <= 1) {
        return null
    }

    return (
        <div className="paginationCnt">
            <ul className="pagination">
                <li className={`page-item ${current === 1 ? 'disabled' : ''}`}>
                    <button onClick={handlePrevClick} disabled={Boolean(current <= 1)} className='btn'>
                        <img src={paginationPrev} width="12" height="11" alt="prev" />
                    </button>
                </li>

                {paginationRange?.map((p) => (
                    p === "..." ?
                        <li key={p} className="page-item">
                            <button className="btn" disabled>{p}</button>
                        </li>
                        :
                        <li key={p} className="page-item">
                            <button
                                className={`btn ${current === p && "active"}`}
                                onClick={() => onPageChange(p)}
                                disabled={Boolean(current === p)}
                            >{p}</button>
                        </li>
                ))}


                <li className={`page-item next ${current === totalPages ? 'disabled' : ''}`}>
                    <button onClick={handleNextClick} disabled={Boolean(current === totalPages)} className='btn'>
                        <img src={paginationPrev} width="12" height="11" alt="next" />
                    </button>
                </li>
            </ul>
        </div>
    );
};
Pagination.propTypes = {
    total : PropTypes.any,
    size: PropTypes.any,
    current: PropTypes.any,
    onPageChange: PropTypes.any,
}
export default Pagination;