
import { useMutation } from "react-query"

import DataService from "../../../config/DataService";
const { postRequest } = DataService()





export const useSendOTP = () => {

    return useMutation({
        mutationFn: (data) => {
            return postRequest('user/withdraw_otp', { ...data })
        },
        select: res => res.data
    })
}