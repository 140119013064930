import "../../../../assets/scss/Innerpages/Innerpages.scss";
import { useLocation } from 'react-router-dom';
import { UsegetOneNews } from "../../api/fetchHomecon";
import { useEffect, useState } from "react";
import ReactHtmlParser from 'react-html-parser';
import { WaitingLoader } from "../../../../Components/Loader/buttonLoad";
const News = () => {

    const price = useLocation()
    const [resdata, setResdata] = useState()
    const { data: GetOne, isLoading } = UsegetOneNews(price?.state?.readnews)

    useEffect(() => {
        if (GetOne?.status) {
            setResdata(GetOne?.data)
        }
    }, [GetOne?.data])

    return (
        <>
            {isLoading && <WaitingLoader />}
            <div className="container">
                <h3 className="secHeading text-center">{resdata?.newstitle}</h3>
                <p className="text-center">{resdata?.newsdescription}</p>
            </div>
            <section className="mt-5 newSec">
                <div className="container">
                    <div className="row text-center">
                        {resdata?.newsbanner ?
                            <div className="col-lg-12" >
                                <img src={resdata?.newsbanner} className="img-fluid my-1 img-new" alt="news"/>
                            </div>
                            : ''}
                    </div>
                </div>
                <div className="container mt-3">
                    {ReactHtmlParser(resdata?.newspagecontent)}
                </div>
            </section>
        </>

    )
}
export default News;