import { useMutation, useQuery } from "react-query"
import StakeService from "../../../config/StakeService";
import { useToast } from "../../../hook/usetoast";
import { queryClient } from "../../../config";
const { stakeget, stakepost } = StakeService();

export const useReferralReport = () => {

    return useQuery({
        queryKey: ['get-referral-report'],
        queryFn: () => {
            return stakeget("user/referralReport")
        }
    })
}

export const useBusinessHistory = () => {
    return useMutation({
        mutationFn: (data) => {
            return stakepost('user/getBusinessStore', { ...data })
        },
        select: res => res.data
    })
}

export const useBusinessRoleHistory = () => {
    return useMutation({
        mutationFn: (data) => {
            return stakepost('user/businessQualified', { ...data })
        },
        select: res => res.data
    })
}


export const useGetReferealBonusReport = () => {

    return useQuery({
        queryKey: ['refereal_bonus_report'],
        queryFn: () => {
            return stakeget("user/referralBonusReport")
        },
        select: res => res?.data
    })
}

export const useStoreReferralClaim = () => {
    const toast = useToast();

    return useMutation({
        mutationFn: (data) => {
            return stakepost('user/referralClaim', data)
        },
        onSuccess: success => {
            if (success?.status) {
                queryClient.refetchQueries('refereal_bonus_report')
                return toast.success(success?.message)
            }
            return toast.error(success?.message)
        }
    })
}

export const useReferalHistory = () => {
    return useMutation({
        mutationFn: (data) => {
            return stakepost('user/referralClaimHistory', data)
        },
    })
}

export const useGetRedeemReport = () => {

    return useQuery({
        queryKey: ['redeem_report'],
        queryFn: () => {
            return stakeget("user/redeemReport")
        },
        select: res => res?.data
    })
}


export const useVolumeReport = () => {

    return useQuery({
        queryKey: ['get-volume-report'],
        queryFn: () => {
            return stakeget("user/volumeReport")
        }
    })
}


export const useIndividualReport = () => {

    return useQuery({
        queryKey: ['get-individual-team'],
        queryFn: () => {
            return stakeget("user/getAllIndividualTeam")
        }
    })
}

export const useIndividualTeamReportHistory = () => {

    return useMutation({
        mutationFn: (data) => {
            return stakepost('user/individualTeamTotalValue', data)
        },
    })
}


export const usePoolBonusReport = () => {

    return useQuery({
        queryKey: ['get-pool-bonus-report'],
        queryFn: () => {
            return stakeget("user/poolBonusReport")
        }
    })
}

export const usePoolbonusRewardReport = () => {
    
    return useMutation({
        mutationFn: (data) => {
            return stakepost('user/poolBonusRewardReport', data)
        },
    })
}

export const usePoolBonusClaim = () => {
    const toast = useToast();
    return useMutation({
        mutationFn: (data) => {
            return stakepost('user/poolClaim', data)
        },
        onSuccess: success => {
            if (success?.status) {
                queryClient.refetchQueries('get-pool-bonus-report')

                return toast.success(success?.message)
            }
            return toast.error(success?.message)
        }
    })
}

export const usePoolBonusClaimHistory = () => {

    return useMutation({
        mutationFn: (data) => {
            return stakepost('user/poolClaimHistory', data)
        },
    })
}

// one-time-reward history
export const useOnerewardHistory = () => {
    return useMutation({
        mutationFn: (data) => {
            return stakepost('user/getOnerewardHistory', { ...data })
        },
        select: res => res.data
    })
}
export const useLevelCommissionReport = () => {
    return useMutation({
        mutationFn: (data) => {
            return stakepost('user/levelCommissionReport', data)
        },
    })
}


export const useLevelMember = () => {

    return useQuery({
        queryKey: ['get-level-member'],
        queryFn: () => {
            return stakeget("user/getDownlineList")
        }
    })
}

export const useAchievement = () => {

    return useMutation({
        mutationFn: (data) => {
            return stakepost('user/premiumCondition', data)
        },
    })
}

