import React, { useEffect, useState } from 'react';
import "../../../assets/scss/Innerpages/Innerpages.scss";
import Pagination from '../../common/pagination/Pagination';
import { useCodeHistory } from '../api/index.js'
import { TransferHistorySkeleton } from '../../../Components/Loader/skeleton.jsx';
import Nodatafound from '../../Nodata/Nodatafound.js';
import Converter from '../../../lib/converter.jsx';
const ProgramCode = () => {

    const size = 10;
    const [page, setPage] = useState(1)

    const programcodeMutation = useCodeHistory()
    const [data, setData] = useState('')

    useEffect(() => {
        fetchCodeHistory()
    }, [page, size])

    const fetchCodeHistory = async () => {
        let obj = {
            page: page,
            size: size,
            sortActive:"desc"
        }
        const res = await programcodeMutation.mutateAsync(obj)
        if (res.status) {
            setData(res)
        }
        else {
            setData('')
        }

    }

    return (
        <div className="row">
            <div className="col-12">
                <div className="d-flex align-items-center flex-wrap">
                    <h3 className="cardTitle lgTitle">Program code</h3>
                </div>
            </div>
            <div className="col-12">
                <div className="card tableCard">
                    <div className="cardInnerCnt">
                        <div className="table-responsive">
                            <table className="table table-hover mb-0">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Date & Time</th>
                                        <th>Program Code</th>
                                        <th>Self Business</th>
                                        <th>Direct (Lv1) Business Volume</th>
                                        <th>Side Volume Business</th>
                                        <th>Coupon code</th>
                                        <th className="text-right">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {programcodeMutation?.isLoading ?
                                        <TransferHistorySkeleton />
                                        :
                                        data?.data?.length > 0 ?
                                            data?.data?.map((n, i) => (
                                                <tr tabIndex={i} key={n}>
                                                    <td>{page == 1
                                                        ? i + 1
                                                        : (page - 1) * 10 +
                                                        (i + 1)}</td>
                                                    <td>{Converter.formatDated(n.createdAt)} </td>
                                                    <td>{n.programCode}</td>
                                                    <td>{n.selfBusiness} ANA</td>
                                                    <td>{n.directLv1} ANA</td>
                                                    <td>{n.sideVolume} ANA</td>
                                                    <td>{n.programcoupon}</td>
                                                    {n.couponUsed != 1 ?
                                                        <td className="text-right greyTxt" style={{ color: "grey" }}>Pending</td>
                                                        :
                                                        <td className="text-right text-success">Used</td>
                                                    }
                                                </tr>
                                            ))
                                            :
                                            <tr>
                                                <td colSpan={8}>
                                                    <Nodatafound message="No Transactions found." />
                                                </td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <Pagination
                    total={data.count}
                    current={page}
                    size={size}
                    onPageChange={no => setPage(no)}
                />
            </div>
        </div>

    )
}
export default ProgramCode;