import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../../assets/scss/Header/Header.scss";
import InnerHeader from './InnerHeader';
import { useModalContext } from '../../contexts/ModalContext';
import { useFetchSiteSettings, useGetProfile } from './api';
import { io } from 'socket.io-client';
import { useToast } from '../../hook/usetoast';


const Header = (props) => {

   const { pathname } = useLocation();
   const { data: getProfile, isLoading } = useGetProfile();
   const { data: siteSettings } = useFetchSiteSettings();
   
   const navigate = useNavigate()
   let token = sessionStorage.getItem('token_ana')

   const { setSiteSettings, setProfileData, setIsLoading, setIsLoggedIn } = useModalContext();
   const toast = useToast()
   useEffect(() => {
      if (!siteSettings?.maintenance) {
         setIsLoading(isLoading)
         setProfileData(getProfile)
         if (siteSettings) {
            setSiteSettings(siteSettings)
         }
      }else{
         navigate("/UnderMaintenance")
      }
   }, [getProfile, isLoading, siteSettings])

   const tokenomics = (focus) => {
      (document.getElementById(focus))?.scrollIntoView({ behavior: "smooth" });
      navigate('/')
   };

   const sbShow = () => {
      document.querySelector('body').classList.toggle('sbMenu');
      document.querySelector('.smClose').classList.toggle('active');
   }
   let hasProcessedChangeCredentials = false; 
   const socket = io(process.env.REACT_APP_SOCKET_URL);

   socket.on('forgot-mail', async (data) => {
      if (sessionStorage.getItem('random') == data?.random && !hasProcessedChangeCredentials) {
         hasProcessedChangeCredentials = true
         sessionStorage.removeItem("random")
         sessionStorage.removeItem("loginno")
         navigate('/')
         toast.success(data?.message)
      }
   })

   socket.on('change-credentials', async (data) => {
      if (sessionStorage.getItem('random') == data?.random && !hasProcessedChangeCredentials) {
         hasProcessedChangeCredentials = true
         sessionStorage.removeItem("random")
         sessionStorage.removeItem("token_ana")
         sessionStorage.removeItem("loginno")
         sessionStorage.removeItem("type")
         setIsLoggedIn("")
         navigate('/login')
         toast.success(data?.message)
      }
   })

   return (

         <header className={`indHd ${pathname == "/" ? "home" : ""}`.trim()}>
            <div className="container">
               <div className="row align-items-center">
                  <div className="col-3 col-lg-2 col-xl-3">
                     <div className="hdLogo">
                        <Link to="/">
                           <img src={require("../../assets/images/logo.png")} alt="logo" className="img-fluid d-none d-sm-inline-block" />
                           <img src={require("../../assets/images/logo-mobile.png")} alt="logo-mobile" className="img-fluid d-inline-block d-sm-none" />
                        </Link>
                     </div>
                  </div>
                  <div className="col-6 col-lg-7 col-xl-6 mx-auto">
                     <div className="hdMenuPt">
                        <ul className="hdMenu">
                           <li>
                              <Link to="/" className={pathname === "/" ? "active" : ''}>Home</Link>
                           </li>
                           <li>
                              <Link to="/about" className={pathname === "/about" ? "active" : ''}>About us</Link>
                           </li>
                           {!token && <><li>
                              <Link onClick={()=>tokenomics('tokenomicsSec')} className={pathname === "/token" ? "active" : ''}>Tokenomics</Link>
                           </li>
                           <li>
                              <Link onClick={()=>tokenomics('roadmapSec')} className={pathname === "/token" ? "active" : ''}>Road map</Link>
                           </li>
                          
                           </>}
                           {!token ? <li>
                              <Link onClick={()=>tokenomics('stakingSec')} className={pathname === "/stake-store" ? "active" : ''}>Staking</Link>
                           </li> : <li>
                              <Link to="/stake-store" className={pathname === "/stake-store" ? "active" : ''}>Staking</Link>
                           </li> }
                           {!token && <li>
                              <Link to="/faq" className={pathname === "/FAQ" ? "active" : ''}>FAQ</Link>
                           </li>}
                           {token && 
                     
                           <li>
                              <Link to="/bussiness-ico" className={pathname === "/bussiness-ico" ? "active" : ''}>Buy ANA</Link>
                           </li>
                           }
                        </ul>

                     </div>
                  </div>
                  {token ?
                     <div className="col-3 col-lg-2 col-xl-3">
                        <InnerHeader sbShow={sbShow} />
                     </div>
                     :
                     <div className="col-3">
                        <div className="hdRgt">
                           <Link to="/register" className="btn btnLink p-0">
                              Register
                           </Link>

                           <Link to="/login" className="btn btn-11545 btnGold">
                              Login
                           </Link>
                           <div className="mobMenu d-lg-none">
                              <button className="smClose" onClick={sbShow}>
                                 <svg id="closeicon" viewBox="0 0 800 600">
                                    <path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="top"></path>
                                    <path d="M300,320 L460,320" id="middle"></path>
                                    <path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" id="bottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318) "></path>
                                 </svg>
                              </button>
                           </div>
                        </div>
                     </div>
                  }
               </div>
            </div>
         </header>
   )
}
export default Header;