import React, { Fragment} from 'react'
import dangerIcon from "../../assets/icons/danger.svg";
import successIcon from "../../assets/icons/tick.png";
import { Alert } from 'reactstrap';
import PropTypes from 'prop-types'; 

function KycStatus({ getKycDetails }) {
    const { kycStatus,
        selfieImageStatus,
        selfieImageReason,
        nationalFrontStatus,
        nationalBackStatus,
        personalFrontStatus,
        personalBackStatus,
        nationalFrontReason,
        nationalBackReason,
        personalFrontReason,
        personalBackReason
    } = getKycDetails;

    if (kycStatus === 3) {
        return (
            <Alert color="success">
                <div className="d-flex align-items-center">
                    <div className="m-4">
                        <img src={successIcon} width="34" height="34" alt="dangerIcon" />
                    </div>
                    <div>
                        <h3>Approved</h3>
                        <p>KYC Verification have been completed.</p>
                    </div>
                </div>
            </Alert>
        )
    } else if (kycStatus === 1) {
        return (
            <Alert color="danger">
                <div className="d-flex align-items-center">
                    <div className="m-4">
                        <img src={dangerIcon} width="34" height="34" alt="dangerIcon" />
                    </div>
                    <div>
                        <h3>Pending</h3>
                        <p>Waiting for Approval.</p>
                    </div>
                </div>
            </Alert>
        )
    } else if (kycStatus === 2) {
        return (
            <Alert color="danger">
                <div className="d-flex align-items-center">
                    <div className="m-4">
                        <img src={dangerIcon} width="34" height="34" alt="dangerIcon" />
                    </div>
                    <div>
                        {selfieImageStatus === 2 ?
                            <div className="mb-2">
                                <h3>Selfie Rejected</h3>
                                <p>{selfieImageReason}</p>
                            </div> : null}

                        {nationalFrontStatus === 2 ?
                            <div className="mb-2">
                                <h3>National ID Front Rejected</h3>
                                <p>{nationalFrontReason}</p>
                            </div> : null
                        }
                        {nationalBackStatus === 2 ?
                            <div className="mb-2">
                                <h3>National ID Back Rejected</h3>
                                <p>{nationalBackReason}</p>
                            </div> : null
                        }
                        {personalFrontStatus === 2 ?
                            <div className="mb-2">
                                <h3>Personal ID Front Rejected</h3>
                                <p>{personalFrontReason}</p>
                            </div> : null
                        }

                        {personalBackStatus === 2 ?
                            <Fragment className="mb-2">
                                <h3>Personal ID Back Rejected</h3>
                                <p>{personalBackReason}</p>
                            </Fragment> : null
                        }

                    </div>
                </div>
            </Alert>
        )
    }
    else return null
}
KycStatus.propTypes ={
   getKycDetails: PropTypes.any
}
export default KycStatus;