import React, { useEffect, useState } from 'react';
import "../../../assets/scss/Innerpages/Innerpages.scss";
import xlsIcon from "../../../assets/images/xlsIcon.png";
import * as XLSX from "xlsx";
import { useBusinessHistory } from '../api/fetchBusinessStore';
import { useDebounce } from '../../../hook/useDounce';
import Nodatafound from '../../Nodata/Nodatafound';
import Pagination from '../../common/pagination/Pagination';
import { TransferHistorySkeleton } from '../../../Components/Loader/skeleton';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { DropdownItem, DropdownMenu, UncontrolledDropdown, DropdownToggle } from 'reactstrap';
import { useExportApi } from '../../StakeStore/api/fetchstakestore';


const BusinessStoreHistory = () => {

    const { level } = useParams();
    const { state } = useLocation()
    let totalMember = state ? state?.count : '--'
    let basedOnLevel = ("l" + level).toString()
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const searchText = useDebounce(search);
    const [historyData, setHistoryData] = useState([])
    const [reward, setReward] = useState({})
    const [time, setTime] = useState(0)
    const exportmutation = useExportApi()
    const navigate = useNavigate()

    const handleDownload = async () => {
        let res = await exportmutation.mutateAsync({
            type: "levelCommission", level: level, "filter": searchText,
            "dayFilter": time.toString()
        });
        if (res?.status) {
            const header = Object.keys(res.data[0]);
            const sheet = XLSX.utils.json_to_sheet(res.data, { header });

            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, sheet, "Sheet1");
            XLSX.writeFile(wb, `Level-${level}-Business-Store.xlsx`);
        }
    };
    const businessHistoryMutation = useBusinessHistory()

    useEffect(() => {
        fetchData();
    }, [page, searchText, time])

    const fetchData = async () => {
        let obj = {

            "level": level,
            "page": page,
            "size": 10,
            "filter": searchText,
            "time": time
        }
        let res = await businessHistoryMutation.mutateAsync(obj);
        
        setHistoryData(res?.data);
        setReward(res)
    }

    const levelMember = (level) => {
        navigate('/level-member-history', { state: { level: level } });
    }

    let displayText;
    if (time === "7") {
        displayText = "1 Week Ago";
    } else if (time === "30") {
        displayText = "1 Month Ago";
    } else {
        displayText = "Recently";
    }
    return (
        <div className="container">
            <h3 className="secHeading text-center">Business Reward</h3>
            <div className="row">
                <div className="col-12">
                    <div className="d-flex align-items-center flex-wrap">
                        <h3 className="cardTitle lgTitle">Level {basedOnLevel?.slice(-1) || '---'}</h3>
                        <div className="d-flex align-items-center filterCnt mb-3 ml-auto flex-wrap flex-md-nowrap">
                            <div className="d-flex flex-column filtValueCnt">
                                <h5 className="cardLabel whiteTxt mb-0">Total Member</h5>

                                <h5 className="cardLabel prmyTxt mb-0"  >{totalMember || 0}
                                <span className='ml-2 text-yellow ' onClick={() => levelMember(+level - 1)} style={{ cursor: "pointer" }}>View</span>

                                </h5>


                            </div>
                            <div className="d-flex flex-column filtValueCnt">
                                <h5 className="cardLabel whiteTxt mb-0">Total Amount</h5>
                                <h5 className="cardLabel prmyTxt mb-0">{reward.status ? reward?.totalRewardAmt?.referralRewardStake[basedOnLevel] : '---'} ANA</h5>
                            </div>
                            <input type="search" placeholder="Search MemberID" className={(search ? "form-control my-1" : "form-control searchbox my-1")} onChange={e => setSearch(e.target.value)} />

                            <div className='octDrpdown dark mx-1'>
                                <UncontrolledDropdown>
                                    <DropdownToggle caret>
                                        {displayText}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem tag={"a"} type="button" onClick={() => setTime(0)}>Recently</DropdownItem>
                                        <DropdownItem tag={"a"} type="button" onClick={() => setTime(7)}>1 Week Ago</DropdownItem>
                                        <DropdownItem tag={"a"} type="button" onClick={() => setTime(30)}>1 Month Ago</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </div>

                            {historyData?.length > 0 && <button className="btn filterBtn my-1" type="button" onClick={() => handleDownload()}><img src={xlsIcon} width="24" height="24" alt="xlsIcon" className="mr-2" />Export File</button>}
                        </div>
                    </div>

                    <div className="card tableCard">
                        <div className="cardInnerCnt">
                            <div className="table-responsive">
                                <table className="table table-hover mb-0">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Date & Time</th>
                                            {level == 1 && <th>Username</th>}
                                            <th>Member ID</th>
                                            <th>Stake amount</th>
                                            <th>Reward Commissions</th>
                                            <th>Moved to LP</th>
                                            <th className="text-right">Earned Reward Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {businessHistoryMutation.isLoading ?
                                            <TransferHistorySkeleton />
                                            :
                                            historyData?.length > 0 ?
                                                historyData?.map((n, i) => (
                                                    <tr key={n}>
                                                        <td>{(page - 1) * 10 + (i + 1)}</td>
                                                        <td>{moment(n.createdAt).format('D/ MM/ YYYY, h:mm:ss')}</td>
                                                        {level == 1 &&<td>{n?.username}</td>}
                                                        <td>{n?.memberID}</td>
                                                        <td>{n.stakeValue} ANA</td>
                                                        <td>{n?.commission}%</td>
                                                        <td>{n?.lpvalue > 0 ? n?.lpvalue : '-'}</td>

                                                        <td className="text-right">{n.rewardAmt} ANA</td>
                                                    </tr>
                                                ))
                                                :
                                                <tr>
                                                    <td colSpan={8}> <Nodatafound /></td>
                                                </tr>}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <Pagination
                        total={reward?.count || 0}
                        current={page}
                        size={10}
                        onPageChange={no => setPage(no)}
                    />
                </div>
            </div>
        </div>
    )
}
export default BusinessStoreHistory;