import { useNavigate } from "react-router-dom";
import "../../../../assets/scss/Home/News/News.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import { useFetchLatestNewsCms } from "../../api/fetchHomecon";
import Nodatafound from "../../../Nodata/Nodatafound";
import { NewsLoader } from "../../../../Components/Loader/skeleton";
import { Parallax } from "react-scroll-parallax";

const News = () => {
   const { data, isLoading } = useFetchLatestNewsCms()
   const navigate = useNavigate()
   let settings = {
      dots: false,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      responsive: [
         {
            breakpoint: 1024,
            settings: {
               slidesToShow: 3,
               slidesToScroll: 1,
               infinite: true,
               dots: true
            }
         },
         {
            breakpoint: 600,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 1,
               initialSlide: 2
            }
         },
         {
            breakpoint: 480,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1
            }
         }
      ]
   };
   const readnews = (e,id) => {
      if (e.key === "Enter" || e.nativeEvent.pointerType === "mouse" || e.nativeEvent.pointerType === "touch") {
      navigate("/Readmore", { state: { readnews: id } })
      }
   }
   return (
      <section className="newsSec">
         <div className="container">
            <div className="abtHd">
               <div className="row">
                  <div className="col-lg-12">
                     <div className="abtHdTls text-center">
                        <Parallax speed={3}>
                           <h2 className="secTl my-4">
                              <span className="txt-gradient">Latest News</span>
                           </h2>
                        </Parallax>                        
                     </div>
                  </div>
               </div>
            </div>
            {isLoading ?
               <NewsLoader />
               :
               data?.data?.length > 0 ?
                  <Slider {...settings} className="mt-5">
                     {data?.data?.map((news) => (
                        <div className="" key={news._id}>
                           <div className="newsCnt">
                              <img src={news?.newsbanner} width="272" height="207" className="img-fluid newsBanner" alt="news"/>
                              <h3>{news?.newstitle}</h3>
                              <h5>{new Date(news.createdAt).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</h5>
                              <p dangerouslySetInnerHTML={{ __html: news?.newsdescription || '' }} />
                              <p style={{ color: "#FFE388", textDecoration: "underline" ,cursor:"pointer"}} onClick={(e) => readnews(e,news._id)} onKeyDown={(e) => readnews(e,news._id)}>Read More</p>
                           </div>
                        </div>
                     ))}
                  </Slider> : <Nodatafound />}
         </div>
      </section>
   )
}
export default News;