
import "../../assets/scss/Home/Home.scss";

import CopyRight from "../../Components/CopyRight/CopyRight";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import About from "./tabs/About/About";
import Banner from "./tabs/Banner/Banner";
import Tokenomics from "./tabs/Tokenomics/Tokenomics";
import Roadmap from "./tabs/Roadmap/Roadmap";
import News from "./tabs/News/News";
import HomeStaking from "./tabs/HomeStaking/HomeStaking";
import Loader from "../../Components/Loader/Loader";

import bannerBg from '../../assets/images/Home/Banner/bannerGif.gif';
import bannerShadow from '../../assets/images/Home/Banner/bannerShadow.png';

import { useFetchCms } from './api/fetchHomecon'

const Home = () => {
   const { data, isLoading } = useFetchCms()

   const scrolly = false;
   
   return (
      <div className={`home_wrap ${scrolly ? "scrolled" : ""}`.trim()}>
         <Loader loaded={isLoading} />
         <section className="homeBannerSec" data-scroll-section>
            <Header />
            <Banner data={data} />
            <img src={bannerShadow} className="bnrShadow" width="761" height="333" alt="bannerShadow" />
            <img src={bannerBg} className="bnrBg" width="1237" height="560" alt="bannerBg" />
         </section>
         <About data={data} />
         <Tokenomics data={data} />
         <HomeStaking data={data} />
         <Roadmap />
         <News />
         <Footer arrdata={data}/>
         <CopyRight data={data}/>
      </div>
   )
}
export default Home;