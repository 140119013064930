import "../../assets/scss/Innerpages/Innerpages.scss";
import aboutLogo from "../../assets/images/cms/aboutLogo.png";
import { Fragment, useEffect } from "react";
import { useCms } from "./api";
import { WaitingLoader } from "../../Components/Loader/buttonLoad";

const About = () => {

   useEffect(() => {
      window.scrollTo({
         top: 0,
         behavior: 'smooth'
      })
   }, [])

   const { data: cms, isLoading } = useCms("about")

   return (
      <Fragment>
         {isLoading && <WaitingLoader />}

         <h3 className="secHeading text-center">About Us</h3>
         <section className="aboutSec">
            <div className="container">
               <div className="row text-center text-lg-left">
                  <div className="col-lg-6">
                     <h3 className="secHeading">{cms?.status ? cms?.data[0]?.page_title : ""}</h3>

                     <p dangerouslySetInnerHTML={{ __html: cms?.status ? cms?.data[0]?.pagecontent : ''}} />
                  </div>
                  <div className="col-lg-6 d-flex justify-content-center align-items-center">
                     <img src={aboutLogo} className="img-fluid" alt="about"/>
                  </div>
               </div>
            </div>
         </section>
         <div className="container">
            <div className="row text-center text-lg-left">
               <div className="col-lg-12">
                  <h3 className="secHeading">{cms?.status ? cms?.data[1]?.page_title : ""}</h3>
                  <p dangerouslySetInnerHTML={{ __html: cms?.status ? cms?.data[1]?.pagecontent : ''}} />
               </div>
            </div>
         </div>
      </Fragment>

   )
}
export default About;