import { Link } from "react-router-dom";
import "../../assets/scss/Innerpages/Innerpages.scss";
import bannerCoin from '../../assets/images/Home/bannerCoin.gif';
import txtLogo from '../../assets/images/txtLogo.svg';
import PropTypes from 'prop-types'; 

const Ended = ({endRoute}) => {
   return (
         <div className="">
            <section className="innerPagesCnt notFdCnt">
               <div className="container">
                  <div className="row justify-content-center">
                     <div className="col-lg-8">
                        <div className="d-flex justify-content-center">
                           <img src={bannerCoin} width="300" height="300" className="img-fluid" alt="banner"/>
                        </div>
                        <div className="d-flex justify-content-center mb-4">
                           <img src={txtLogo} width="295" height="43" className="img-fluid" alt="txtLogo"/>
                        </div>
                        <h3 className="secHeading text-center xlTxt">{endRoute == "/stake-view" ? "Stake" :"ICO"} Ended</h3>
                        <div className="d-flex justify-content-center">
                           <Link to={endRoute} className="btn btn-11545 btnLg btnGold" type="button">Go to {endRoute == "/anawallet" ? "ANA Wallet" : endRoute == "/participate-store" ? "Participate Store" : "Stake View"}</Link>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
         </div>
   )
}

Ended.propTypes ={
    endRoute: PropTypes.any,
}

export default Ended;