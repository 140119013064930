import React from 'react';
import { useKycDetails } from './api/kycValidate'
import { KYCSkeleton } from '../../Components/Loader/skeleton'
import KycDetails from './kycDetails'
import KycStatus from './kycStatus';
import { useGetProfile } from '../../Components/Header/api';

function Kyc() {

    const { data: getKycDetails, isLoading } = useKycDetails();
    const { data: getProfile, isFetching } = useGetProfile();
    

    if ((isLoading && !getKycDetails) || isFetching) {
        return (
            <div className="container animated_div">
                <KYCSkeleton />
            </div>
        )
    }
    return (
        <div className="container">
            <h3 className="secHeading text-center">KYC Verification</h3>
            <KycStatus getKycDetails={getKycDetails} />
            <KycDetails getKycDetails={getKycDetails} country={getProfile?.country || ''}/>
        </div>
    )
}

export default Kyc;