import React, { useEffect, useState } from 'react';
import "../../assets/scss/Innerpages/Innerpages.scss";
import xlsIcon from "../../assets/images/xlsIcon.png"
import Nodatafound from '../Nodata/Nodatafound';
import moment from 'moment';
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { useLiquidityPoolReport } from './api';
import { TransferHistorySkeleton } from '../../Components/Loader/skeleton';
import Pagination from '../common/pagination/Pagination';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';


const LiquidityPoolReport = () => {
   const [page, setPage] = useState(1)
   const size = 10

   const [sort, setSort] = useState('0')
   const [data, setData] = useState(null);
  
   const [isLoading, setIsLoading] = useState(false);

   const liquidityPoolReport = useLiquidityPoolReport();

   useEffect(() => {
      fetchData()
   }, [page, size, sort])

   const fetchData = async () => {
      setIsLoading(true);

      let obj =
      {
         page: page,
         size: size,
         time: sort,
         status: false,
      }

      try {
         const fetchedData = await liquidityPoolReport.mutateAsync(obj);
         setData(fetchedData);
      } catch (error) {
      }

      setIsLoading(false);
   };

   const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
   const fileExtension = ".xlsx";

   const handleDownload = async () => {
      let fileName = "Liquidity-Pool-Report";

      let obj =
      {
         page: page,
         size: size,
         time: sort,
         status: true,
      }
      const fetchedData = await liquidityPoolReport.mutateAsync(obj);
      const ws = XLSX.utils.json_to_sheet(fetchedData?.data);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
   }

   let displayText;
   if (sort === "7") {
       displayText = "1 Week Ago";
   } else if (sort === "30") {
       displayText = "1 Month Ago";
   } else {
       displayText = "Recently";
   }

   let tableContent;

   if (isLoading) {
      tableContent = <TransferHistorySkeleton />;
   } else if (data?.data?.length > 0) {
      tableContent = data.data.map((n, i) => (
         <tr key={n}>
         <td>{i + 1}</td>
         <td>{moment(n.createdAt).format('D/ MM/ YYYY, h:mm:ss')}</td>
         <td>{n?.toMemberId}</td>
         
         <td>{n?.donatedToAdmin} ANA</td>
         <td className="text-right" >{n?.level == 0 ? "Non-Staker" : "Level " + n?.level}</td>
     </tr>
      ));
   } else {
      tableContent = (
         <tr>
                                                       <td colSpan={7}><Nodatafound /></td>
         </tr>
      );
   }
   return (
      <div className="container">
         <h3 className="secHeading text-center">Liquidity Pool</h3>

         <div className="row">
            <div className="col-12">
               <div className="d-flex align-items-center flex-wrap">
                  <h3 className="cardTitle lgTitle">Incoming history</h3>
                  <div className="d-flex align-items-center filterCnt mb-3 ml-auto flex-wrap flex-md-nowrap">
                  <div className='octDrpdown dark mx-1'>
                     <UncontrolledDropdown>
                           <DropdownToggle caret>
                              {displayText}
                           </DropdownToggle>
                           <DropdownMenu>
                              <DropdownItem tag={"a"} type="button" onClick={() => setSort("0")}>Recently</DropdownItem>
                              <DropdownItem tag={"a"} type="button" onClick={() => setSort("7")}>1 Week Ago</DropdownItem>
                              <DropdownItem tag={"a"} type="button" onClick={() => setSort("30")}>1 Month Ago</DropdownItem>
                           </DropdownMenu>
                     </UncontrolledDropdown>
                     </div>
                    

                     <button className="btn filterBtn my-1" type="button" onClick={handleDownload} disabled={!data?.status}><img src={xlsIcon} width="24" height="24" alt="xlsIcon" className="mr-2" />Export File</button>
                  </div>
               </div>

               <div className="card tableCard">
                  <div className="cardInnerCnt">
                     <div className="table-responsive">
                        <table className="table table-hover">
                           <thead>
                              <tr>
                                 <th>S.No</th>
                                 <th>Date and Time</th>
                                 <th>Member ID</th>
                                 <th>Amount Sent to Liquidity Pool</th>
                                 <th className="text-right">From Level</th>
                              </tr>
                           </thead>
                           <tbody>
                              {tableContent}
                           </tbody>
                        </table>
                     </div>
                  </div>
               </div>
               <div className="paginationCnt">
                  <Pagination
                     total={data?.count}
                     current={page}
                     size={size}
                     onPageChange={no => setPage(no)}
                  />
               </div>
            </div>
         </div>
      </div>
   )
}
export default LiquidityPoolReport;