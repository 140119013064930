import { useMutation } from "react-query"
import { queryClient } from "../../../config"
import { useToast } from "../../../hook/usetoast"
import StakeService from "../../../config/StakeService";
import DataService from "../../../config/DataService";
const { stakepost } = StakeService();
const { postRequest } = DataService();


export const useTransfer = () => {
    const toast = useToast()
    return useMutation({
        mutationFn: (data) => {
            return stakepost("user/businessTransfer", { ...data })
        },
        onSuccess: success => {
            if (success.status) {
                queryClient.refetchQueries('userBalance')
                return toast.success(success.message)
            }
            return toast.error(success.message)
        }
    })
}

export const useTransferFee = () => {
    return useMutation({
        mutationFn: (data) => {
            return postRequest("admin/getPlatformFee", { ...data })
        },
    })
}


export const useTransferHistory = () => {

    return useMutation({
        mutationFn: (data) => {
            return stakepost('user/businessTransferHistory', { ...data })
        },
        select: res => res.data
    })

}

export const useExportDocs = () => {

    return useMutation({
        mutationFn: (data) => {
            return stakepost('user/exportApi', data)
        },
        select: res => res.data
    })

}