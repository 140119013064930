import { Link } from "react-router-dom";
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForgotPass } from "./api/authmutations";
import { Dots } from "../../lib/dots";
import { BounceInDownButton } from "../../Components/animation/bouncein";
import { useState } from "react";
import { WaitingLoader } from "../../Components/Loader/buttonLoad";
import { generateSecureRandomNumber } from "../../hook/generateSecureRandomNumber";
const EMAILID_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const schema = yup.object().shape({
    email: yup.string()
        .matches(EMAILID_REGEX, "Invalid Email")
        .max(140, 'Email must be at most 140 characters')
        .required('Email is Required'),
})


const ForgotMPIN = () => {

    const forgotPasswordmutation = useForgotPass();
    const [loading, setLoading] = useState(false)

    const { control, handleSubmit, formState: { errors }, reset } = useForm({
        mode: 'onChange',
        resolver: yupResolver(schema),
        defaultValues: {
            email: '',
        }
    });


    const onSubmit = async (data) => {
        setLoading(true)
        let random = Math.floor(generateSecureRandomNumber() * 10000)
        sessionStorage.setItem('random', random)
        data["random"] = random
        data["type"] = "MPIN";
        await forgotPasswordmutation.mutateAsync(data)
        sessionStorage.setItem("frgmail", JSON.stringify(data))
        setLoading(false)
        reset()
    }

    return (
        <BounceInDownButton>
            {loading && <WaitingLoader />}
            <div className="row justify-content-center animated_div">
                <div className="col-lg-6 col-xl-4">
                    <h3 className="modalHeading">Welcome Back</h3>
                    <p className="modalSubHeading">Forgot MPIN</p>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                            <label className="formLabel" htmlFor="loginId1">Email id</label>
                            <Controller name='email' control={control} render={({ field }) => (
                                <input
                                    {...field}
                                    type="text"
                                    autoFocus
                                    className="form-control"
                                    placeholder='Enter your email'
                                    autoComplete='off'
                                />
                            )} />

                            {errors.email && <p className="errMsg">* {errors.email.message}</p>}
                        </div>

                        <div className="d-flex justify-content-center my-4">
                            <button className="btn btn-11545 btnGold" disabled={forgotPasswordmutation.isLoading}>
                                {forgotPasswordmutation.isLoading ?
                                    <Dots>Loading</Dots>
                                    :
                                    "Submit"
                                }
                            </button>
                        </div>
                        <p className="text-center">
                            <Link to='/login' className="yellowTxt mx-2">Back to Login</Link>
                        </p>
                    </form>
                </div>
            </div>
        </BounceInDownButton>
    )
}
export default ForgotMPIN;