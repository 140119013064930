import React from 'react';
import "../../../assets/scss/Innerpages/Innerpages.scss";
import Nodatafound from '../../Nodata/Nodatafound';
import { TransferHistorySkeleton } from '../../../Components/Loader/skeleton'
import Converter from '../../../lib/converter';
import { useLevelMember } from '../api/fetchBusinessStore';
import { useLocation } from 'react-router-dom';
const LevelMemberHistory = () => {

    const page = 1
    const state = useLocation()

    const { data: getLevelMember, isLoading } = useLevelMember()

    

    return (
        <div className="container animated_div">
            <h3 className="secHeading text-center">Business Member Report</h3>

            <div className="row">
                <div className="col-12">

                    <div className="card tableCard">
                        <div className="cardInnerCnt">
                            <div className="table-responsive">
                                <table className="table table-hover mb-0">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Date and Time</th>
                                            <th>Member ID</th>
                                            {state?.state?.level == 0 &&
                                            <><th>UserName</th>
                                            <th>Email</th></>}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {isLoading ?
                                            <TransferHistorySkeleton />
                                            :
                                            getLevelMember?.data[0]?.referrals?.filter(data => state?.state?.level == data.level)
                                                ?.length > 0 ?
                                                getLevelMember?.data[0]?.referrals?.filter(data => state?.state?.level == data.level).map((n, i) => (
                                                    <tr key={n}>
                                                        <td>{page == 1
                                                            ? i + 1
                                                            : (page - 1) * 10 +
                                                            (i + 1)}</td>
                                                        <td>{Converter.formatDated(n.createdAt)}</td>
                                                        <td>{n.memberID}</td>
                                                        {state?.state?.level == 0 &&
                                                        <><td>{n.username}</td>
                                                        <td>{n.email}</td></>}

                                                    </tr>
                                                ))
                                                :
                                                <tr>
                                                    <td colSpan={5}><Nodatafound message="No Data found." /> </td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default LevelMemberHistory;