import React from 'react'
import noItemfoundImage from './noItemfoundImage.svg'
import PropTypes from 'prop-types'; 

export const NotFound = ({ message }) => {
    return (
        <div className='noItemFound'>
            <div className='text-center'>
                <img src={noItemfoundImage} alt='' className='noItemfoundImage' />
            </div>
            <div className='nofounttxt mb-4'>
                {message || 'No Data Found.'}
            </div>
        </div>
    )
}
NotFound.propTypes ={
   message: PropTypes.any,
}

const Nodatafound = ({ message }) => {
    return (
        <div className='noItemFound'>
            <div className='text-center'>
                <img
                    src={noItemfoundImage}
                    alt='noItemfoundImage'
                    className='img-fluid'
                />
            </div>
            <div className='nofounttxt mb-4'>
                {message || 'Nothing to display.'}
            </div>
        </div>
    )
}

Nodatafound.propTypes ={
    message: PropTypes.any,
}

export default Nodatafound
