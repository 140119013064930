import { useMutation } from "react-query"
import StakeService from "../../../config/StakeService"
import { useToast } from "../../../hook/usetoast";
import { queryClient } from "../../../config";

const { stakepost } = StakeService();

export const useRedeemMove = () => {
    const toast = useToast();

    return useMutation({
        mutationFn: (data) => {
            return stakepost("user/redeem", { ...data })
        },
        onSuccess: success => {
            if (success.status) {
                queryClient.refetchQueries("userBalance")
                return toast.success(success.message)
            }
            return toast.error(success.message)
        }
    })
}

export const useRedeemHistory = () => {
    return useMutation({
        mutationFn: (data) => {
            return stakepost("user/redeemHistory", { ...data })
        }
    })
}

