import Login from "../Pages/Auth/Login";
import Register from "../Pages/Auth/Register";
import ResetMPIN from "../Pages/Auth/resetMPIN";
import ForgotPass from "../Pages/Auth/forgotPassword";
import ResetPassword from "../Pages/Auth/resetPassword";
import AuthLayout from "../Layout/AuthLayout";
import ForgotMPIN from "../Pages/Auth/forgotmpin";
import { SuccessRegister } from "../Pages/success";
import Adminview from "../Pages/Adminview/Adminview";

export const publicRoutes = [
    {
        path: "/",
        element: <AuthLayout />,
        children: [
            {
                path: "login",
                element: <Login />
            },
            {
                path: "register",
                element: <Register />
            },
            {
                path: "referral/:referalid/:referalname",
                element: <Register />
            },
            {
                path: "forget-password",
                element: <ForgotPass />
            },
            {
                path: "forget-mpin",
                element: <ForgotMPIN />
            },
            {
                path: "reset-password/:token",
                element: <ResetPassword />,
            },
            {
                path: "reset-mpin/:token",
                element: <ResetMPIN />
            },
            {
                path: "register-success",
                element: <SuccessRegister />
            },
            {
                path: "admin-view/:token",
                element: <Adminview />
            },
           


        ],
    },
]
