import { useIdle } from '@mantine/hooks'
import { useEffect } from 'react'

import { useLocation, useNavigate, useRoutes } from 'react-router-dom'
import { publicRoutes } from './public'
import Home from '../Pages/Home/Home'
import { ProtectedRoutes } from './protected'
import { CmsRoutes } from './cms'
import Notfound from '../Pages/common/Notfound'
import UnAuthorized from '../Pages/common/unAuthorized'
import UnderMaintenance from '../Pages/common/UnderMaintenance'
import { useLogout } from '../Components/Header/api'
import Cookies from 'js-cookie'
import { useModalContext } from '../contexts/ModalContext'

export const AppRoutes = () => {
    const idle = useIdle(1000 * 60 * 30, { initialState: false });
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const { setIsLoggedIn } = useModalContext();

    const logout = useLogout()

    const logOut = async () => {
        if (sessionStorage.getItem("type") == "admin" || Cookies.get('sdgsdsadgt')) {
            sessionStorage.clear()
            Cookies.remove('sdgsdsadgt')
            navigate('/login')
        } else {
            await logout.mutateAsync({})
            sessionStorage.removeItem("token_ana")
            sessionStorage.removeItem("loginno")
            sessionStorage.removeItem("type")
            Cookies.remove('sdgsdsadgt')
            setIsLoggedIn("")
        }
    }

    useEffect(() => {
        if (idle) {
            const handleIdle = async () => {
                logOut()
            }
            handleIdle()
        }
    }, [idle])

    useEffect(() => {
        try {
           
                try {
                    const tabKey = 'myAppTabOpen';
                    const alertShownKey = 'alertShown'; // Key to track if alert has been shown

                    // Check if the tab is already open
                    const isTabOpen = sessionStorage.getItem(tabKey);

                    if (isTabOpen) {
                        try {
                            if (!sessionStorage.getItem(alertShownKey)) {
                                try {
                                    sessionStorage.setItem(alertShownKey, 'true'); // Set flag to prevent alert again
                                    window.location = "https://google.com";
                                    sessionStorage.removeItem(alertShownKey)
                                } catch (error) {
                                    return true
                                }
                            }
                        } catch (error) {
                            return true
                        }
                        // Check if the alert has already been shown

                    } else {
                        try {
                            // Set the key to indicate that this tab is open
                            sessionStorage.setItem(tabKey, 'true');

                            // Cleanup function to remove the key on tab close
                            const handleBeforeUnload = () => {
                                try {
                                    sessionStorage.removeItem(tabKey);
                                    sessionStorage.removeItem(alertShownKey); // Clear alert flag on unload
                                } catch (error) {
                                    return true
                                }
                            };

                            window.addEventListener('beforeunload', handleBeforeUnload);
                            return () => {
                                try {
                                    handleBeforeUnload();
                                    window.removeEventListener('beforeunload', handleBeforeUnload);
                                } catch (error) {
                                    return true
                                }
                            };
                        } catch (error) {
                            return true
                        }
                    }
                } catch (error) {
                    return true
                }

            
        } catch (error) {
            return true
        }
    }, [pathname]);


    const commonRoutes = [
        {
            path: '/',
            element: <Home />
        },
        {
            path: "*",
            element: <Notfound />
        },
        {
            path: "/unauthorized-request",
            element: <UnAuthorized />
        },
        {
            path: "/UnderMaintenance",
            element: <UnderMaintenance />
        }
    ]
    const routes = [...publicRoutes, ...ProtectedRoutes, ...CmsRoutes]
    const element = useRoutes([...commonRoutes, ...routes])

    return element
}
