import React, { useEffect, useState } from 'react';
import "../../assets/scss/Innerpages/Innerpages.scss";
import uploadIcon from "../../assets/images/uploadIcon.png";
import { Alert, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useUpdateKyc } from './api/kycValidate';
import { Dots } from '../../lib/dots';
import { WaitingLoader } from '../../Components/Loader/buttonLoad';
import dangerIcon from '../../assets/icons/danger.svg';
import Cookies from "js-cookie"
import { useToast } from "../../hook/usetoast";
import PropTypes from 'prop-types'; 


const REGEX = /^.{1,30}$/


const schema = yup.object().shape({
   personalId: yup.string()
      .required('Personal ID is required'),
   personalIdNo: yup.string()
      .when(["personalId"], (val, schema) => {
         if (val[0] === "PAN Card")
            return yup.string()
               .matches(REGEX, 'Enter valid PAN number')
               .required("PAN number is required")
         else if (val[0] === "License") return yup.string()
            .matches(REGEX, 'Enter valid License number')
            .required("License number is required")
         else return yup.string()
            .min(9, 'Enter valid ID number')
            .required("ID number is required")
      })
      .required("ID Number is Required"),
   nationalId: yup.string()
      .required('Proof ID is Required'),
   nationalIdName: yup.string()
      .when(["nationalId"], (val, schema) => {
         if (val[0] === "Others")
            return yup.string()
               .required("ID name is Required");
         else return yup.string().notRequired()
      }),
   nationalIdNo: yup.string()
      .when(["nationalId"], (val, schema) => {
         if (val[0] === "Aadhar")
            return yup.string()
               .matches(REGEX, 'Enter valid Aadhar number')
               .required("Aadhar number is required")
         else if (val[0] === "Voter ID") return yup.string()
            .matches(REGEX, 'Enter valid Voter ID number')
            .required("Voter ID number is required")
         else if (val[0] === "DrivingLicence") return yup.string()
            .matches(REGEX, 'Enter valid License number')
            .required("License number is required")
         else yup.string()
            .matches(REGEX, 'Enter valid naition id')
            .required("Naition ID number is required")
      })
      .required('Proof Number is Required'),
   personalFrontImage: yup.mixed()
      .test("required", "ID Front Image required", (file) => Boolean(file)),
   personalBackImage: yup.mixed()
      .test("required", "ID Back Image required", (file) => Boolean(file)),
   nationalFrontImage: yup.mixed()
      .test("required", "Proof Front Image required", (file) => Boolean(file)),
   nationalBackImage: yup.mixed()
      .test("required", "Proof Back Image required", (file) => Boolean(file)),
   selfieImage: yup.mixed()
      .test("required", "Selfie Image required", (file) => Boolean(file)),
})

const KycDetails = ({ getKycDetails , country }) => {

   const toast = useToast();
   const [idFront, setIdFront] = useState();
   const [idBack, setIdBack] = useState();
   const [proofFront, setProofFront] = useState();
   const [proofBack, setProofBack] = useState();
   const [selfie, setSelfie] = useState();
   const [others, setOthers] = useState(false);

   const navigate = useNavigate();
   const updateKycmutation = useUpdateKyc();

   const [dropdownOpen, setDropdownOpen] = useState(false);
   const toggle = () => setDropdownOpen((prevState) => !prevState);

   const [dropdownOpenOne, setDropdownOpenOne] = useState(false);
   const toggleOne = () => setDropdownOpenOne((prevState) => !prevState);


   const { control, handleSubmit, formState: { errors }, watch, reset, setError, clearErrors, setValue, getValues } = useForm({
      mode: 'onChange',
      resolver: yupResolver(schema),
      defaultValues: {
         personalId: '',
         personalIdNo: "",
         personalFrontImage: "",
         personalBackImage: "",
         nationalId: "",
         nationalIdName: "",
         nationalIdNo: "",
         nationalFrontImage: "",
         nationalBackImage: "",
         selfieImage: "",
      }
   });
   const watchFields = watch("nationalId")
   const watchpersonalId = watch("personalId")

   useEffect(() => {
      if (getKycDetails?.kycStatus !== 0) {
         if (getKycDetails?.selfieImageStatus !== 2) setValue("selfieImage", getKycDetails?.selfieImage)
         if (getKycDetails?.nationalFrontStatus !== 2) setValue("nationalFrontImage", getKycDetails?.nationalFrontImage)
         if (getKycDetails?.nationalBackStatus !== 2) setValue("nationalBackImage", getKycDetails?.nationalBackImage)
         if (getKycDetails?.personalFrontStatus !== 2) setValue("personalFrontImage", getKycDetails?.personalFrontImage)
         if (getKycDetails?.personalBackStatus !== 2) setValue("personalBackImage", getKycDetails?.personalBackImage)
         setValue("personalId", getKycDetails?.personalId)
         setValue("personalIdNo", getKycDetails?.personalIdNo)
         setValue("nationalId", getKycDetails?.nationalId)
         setValue("nationalIdName", getKycDetails?.nationalIdName)
         setValue("nationalIdNo", getKycDetails?.nationalIdNo)
         setIdFront(getKycDetails?.personalFrontImage)
         setIdBack(getKycDetails?.personalBackImage)
         setProofFront(getKycDetails?.nationalFrontImage)
         setProofBack(getKycDetails?.nationalBackImage)
         setSelfie(getKycDetails?.selfieImage)
      }
   }, [getKycDetails])

   const checkIDnumChar = (e) => {
   
      if (e.target.value.length === 0 && e.key === ' ') {
         e.preventDefault();
      }
   };

   const checkname = (e) => {
      if (e.target.value.length === 0 && e.key === ' ') {
         e.preventDefault();
      }
   };

   const readFileAsync = (file) => {
      return new Promise(
         (resolve, reject) => {
            if (!file) return

            const reader1 = new FileReader()
            reader1.onload = () => {
               resolve(`data:${file.type};base64,${btoa(reader1.result)}`)
            }
            reader1.onerror = reject
            reader1.readAsBinaryString(
               file
            )
         }
      )
   }

   const idFrontImg = (event) => {
      let img = event.target.files[0];
      const reader = new FileReader();

      reader.onload = (event) => {
         const imgCheck = new Image();
         imgCheck.src = event.target.result;
         imgCheck.onload = async () => {
            if (imgCheck.width <= 6000 && imgCheck.height <= 6000) {

               if (img.size < 2 * 1024 * 1024) {
                  const newImage1 = await readFileAsync(img)
                  setIdFront(newImage1);
                  setValue("personalFrontImage", img)
                  toast.success("Image Uploaded")
               } else {
                  setError("personalFrontImage", { message: "Image Size Must be Less than 2MB." });
               }
            } else {
               setError("personalFrontImage", { message: "Image Dimensions should be 6000 x 6000 Pixels." });
            }
         };

         imgCheck.onerror = (error) => {

         };
      }
      reader.onerror = (error) => {

      };
      reader.readAsDataURL(img);
   }

   const idBackImg = (event) => {
      let img = event.target.files[0];
      const reader = new FileReader();

      reader.onload = (event) => {
         const imgCheck = new Image();
         imgCheck.src = event.target.result;
         imgCheck.onload = async () => {
            if (imgCheck.width <= 6000 && imgCheck.height <= 6000) {

               if (img.size < 2 * 1024 * 1024) {
                  const newImage1 = await readFileAsync(img)
                  setIdBack(newImage1);
                  setValue("personalBackImage", img)
                  toast.success("Image Uploaded")
               } else {
                  setError("personalBackImage", { message: "Image Size Must be Less than 2MB." });
               }
            } else {
               setError("personalBackImage", { message: "Image Dimensions should be 6000 x 6000 Pixels." });
            }
         };

         imgCheck.onerror = (error) => {

         };
      }
      reader.onerror = (error) => {

      };
      reader.readAsDataURL(img);
   }

   const proofFrontImg = (event) => {
      let img = event.target.files[0];
      const reader = new FileReader();

      reader.onload = (event) => {
         const imgCheck = new Image();
         imgCheck.src = event.target.result;
         imgCheck.onload = async () => {
            if (imgCheck.width <= 6000 && imgCheck.height <= 6000) {

               if (img.size < 2 * 1024 * 1024) {
                  const newImage1 = await readFileAsync(img)
                  setProofFront(newImage1);
                  setValue("nationalFrontImage", img)
                  toast.success("Image Uploaded")
               } else {
                  setError("nationalFrontImage", { message: "Image Size Must be Less than 2MB." });
               }
            } else {
               setError("nationalFrontImage", { message: "Image Dimensions should be 6000 x 6000 Pixels." });
            }
         };

         imgCheck.onerror = (error) => {

         };
      }
      reader.onerror = (error) => {

      };
      reader.readAsDataURL(img);
   }

   const proofBackImg = (event) => {
      let img = event.target.files[0];
      const reader = new FileReader();

      reader.onload = (event) => {
         const imgCheck = new Image();
         imgCheck.src = event.target.result;
         imgCheck.onload = async () => {
            if (imgCheck.width <= 6000 && imgCheck.height <= 6000) {

               if (img.size < 2 * 1024 * 1024) {
                  const newImage1 = await readFileAsync(img)
                  setProofBack(newImage1);
                  setValue("nationalBackImage", img)
                  toast.success("Image Uploaded")
               } else {
                  setError("nationalBackImage", { message: "Image Size Must be Less than 2MB." });
               }
            } else {
               setError("nationalBackImage", { message: "Image Dimensions should be 6000 x 6000 Pixels." });
            }
         };

         imgCheck.onerror = (error) => {

         };
      }
      reader.onerror = (error) => {

      };
      reader.readAsDataURL(img);
   }

   const SelfieImg = (event) => {
      let img = event.target.files[0];
      const reader = new FileReader();

      reader.onload = (event) => {
         const imgCheck = new Image();
         imgCheck.src = event.target.result;
         imgCheck.onload = async () => {
            if (imgCheck.width <= 6000 && imgCheck.height <= 6000) {

               if (img.size < 2 * 1024 * 1024) {
                  const newImage1 = await readFileAsync(img)
                  setSelfie(newImage1);
                  setValue("selfieImage", img)
                  toast.success("Image Uploaded")
               } else {
                  setError("selfieImage", { message: "Image Size Must be Less than 2MB." });
               }
            } else {
               setError("selfieImage", { message: "Image Dimensions should be 6000 x 6000 Pixels." });
            }
         };

         imgCheck.onerror = (error) => {

         };
      }
      reader.onerror = (error) => {

      };
      reader.readAsDataURL(img);
   }

   const onsubmit = async (data) => {
      const formData = new FormData()

      formData.append("personalId", data.personalId)
      formData.append("personalIdNo", data.personalIdNo)
      formData.append("personalFrontImage", data.personalFrontImage)
      formData.append("personalBackImage", data.personalBackImage)
      formData.append("nationalId", data.nationalId)
      formData.append("nationalIdName", data.nationalIdName)
      formData.append("nationalIdNo", data.nationalIdNo)
      formData.append("nationalFrontImage", data.nationalFrontImage)
      formData.append("nationalBackImage", data.nationalBackImage)
      formData.append("selfieImage", data.selfieImage)

      let res = await updateKycmutation.mutateAsync(formData)
      if (res?.status) {
         reset()
         navigate('/dashboard')
      }
   }
   const borderColor = 
   getKycDetails?.personalBackStatus === 3 ? "green" : 
   getKycDetails?.personalBackStatus === 2 ? "red" : "";

   return (
      <>
         {updateKycmutation.isLoading && <WaitingLoader />}
         <form onSubmit={handleSubmit(onsubmit)}>
            <div className="row">
               <div className="col-12">
                  <h3 className="secMdHeading">Personal ID</h3>
               </div>
               <div className="col-lg-5">
                  <p className="greyTxt">Only the following documents listed below will be accepted, all other documents will be rejected.</p>
                  <div className="col-lg-9 px-0">
                     <div className="form-group">
                        <label className="formLabel" htmlFor="Id1">{country == "India" ? "Select" : "Enter"}</label>
                        {getKycDetails?.country === "India" || getKycDetails?.country === "IN" ?
                           <div className='octDrpdown'>
                              <Dropdown isOpen={dropdownOpen} toggle={toggle}
                                 disabled={Boolean(
                                    (getKycDetails?.personalFrontStatus === 3 || getKycDetails?.personalFrontStatus === 1) &&
                                    (getKycDetails?.personalBackStatus === 3 || getKycDetails?.personalBackStatus === 1)
                                 )}>
                                 <DropdownToggle>{getValues("personalId") == "License" ? "Licence" : getValues("personalId") || "Select Personal ID"} </DropdownToggle>
                                 <DropdownMenu>
                                    <DropdownItem onClick={() => setValue("personalId", "PAN Card")}>PAN Card</DropdownItem>
                                    <DropdownItem onClick={() => setValue("personalId", "License")}>Licence</DropdownItem>
                                 </DropdownMenu>
                              </Dropdown>
                           </div>
                           :
                           <Controller name='personalId' control={control} render={({ field }) => (
                              <input
                                 {...field}
                                 type="text"
                                 maxLength={30}
                                 disabled={Boolean(
                                    (getKycDetails?.personalFrontStatus === 3 || getKycDetails?.personalFrontStatus === 1) &&
                                    (getKycDetails?.personalBackStatus === 3 || getKycDetails?.personalBackStatus === 1)
                                 )}
                                 className="form-control"
                                 onKeyPress={checkname}
                                 placeholder="Enter your ID Number"
                                 autoComplete='off'
                              />
                           )} />}
                        {!watchpersonalId && errors.personalId && <p className="errMsg">* {errors.personalId.message}</p>}
                     </div>
                  </div>
                  <div className="col-lg-9 px-0">
                     <div className="form-group">
                        <label className="formLabel" htmlFor="Id1">ID Number</label>
                        <Controller name='personalIdNo' control={control} render={({ field }) => (
                           <input
                              {...field}
                              type="text"
                              maxLength={30}
                              disabled={Boolean(
                                 (getKycDetails?.personalFrontStatus === 3 || getKycDetails?.personalFrontStatus === 1) &&
                                 (getKycDetails?.personalBackStatus === 3 || getKycDetails?.personalBackStatus === 1)
                              )}
                              className="form-control"
                              onKeyPress={checkIDnumChar}
                              placeholder="Enter your ID Number"
                              autoComplete='off'
                           />
                        )} />
                        {errors.personalIdNo && <p className="errMsg">* {errors.personalIdNo.message}</p>}
                     </div>
                  </div>
               </div>

               <div className="col-lg-7">
                  <div className="d-flex flex-wrap">
                     <div className="form-group mr-3">
                        <label className="formLabel" htmlFor="Id1">Front Side</label>
                        <div className="uplCard"
                           style={{ borderColor }}
                        >
                           <div className="uplCnt">
                              {idFront ?
                                 <div className="d-flex flex-column align-items-center">
                                    <img src={idFront} width="150" height="150" alt="uploadIcon" />
                                 </div>
                                 :
                                 <div className="d-flex flex-column align-items-center">
                                    <img src={uploadIcon} width="50" height="40" alt="uploadIcon" />
                                    <p className="text-center greyTxt">Drag & Drop file or <br />Browser Your computer</p>
                                    <span className="prmyTxt">Click Here</span>
                                 </div>
                              }
                              <input type="file"
                                 disabled={Boolean(getKycDetails?.personalFrontStatus === 3 || getKycDetails?.personalFrontStatus === 1)}
                                 name='personalFrontImage'
                                 accept=".png,.jpg,.jpeg"
                                 onChange={async (e) => {
                                    try {
                                       let img = e.target.files[0]
                                       const reader = new FileReader();
                                       clearErrors("personalFrontImage")
                                       setValue("personalFrontImage", null)
                                       setIdFront(null)
                                       reader.onload = (event) => {
                                          const bytes = new Uint8Array(event.target.result);
                                          if (bytes[0] === 0x89 && bytes[1] === 0x50 || bytes[0] === 0xFF && bytes[1] === 0xD8) { // png, apng, jpg, jpeg
                                             idFrontImg(e)
                                          } else {
                                             setError("personalFrontImage", { message: 'Please select image file' })
                                          }
                                       };
                                       reader.onerror = (error) => {

                                       };
                                       reader.readAsArrayBuffer(img);
                                    } catch (e) { }
                                 }}
                              />
                           </div>
                        </div>
                        {errors.personalFrontImage && <p className="errMsgKyc">* {errors.personalFrontImage.message}</p>}
                     </div>
                     <div className="form-group">
                        <label className="formLabel" htmlFor="Id1">Back Side</label>
                        <div className="uplCard"
                           style={{ borderColor }}
                        >
                           <div className="uplCnt">
                              {idBack ?
                                 <div className="d-flex flex-column align-items-center">
                                    <img src={idBack} width="150" height="150" alt="uploadIcon" />
                                 </div>
                                 :
                                 <div className="d-flex flex-column align-items-center">
                                    <img src={uploadIcon} width="50" height="40" alt="uploadIcon" />
                                    <p className="text-center greyTxt">Drag & Drop file or <br />Browser Your computer</p>
                                    <span className="prmyTxt">Click Here</span>
                                 </div>
                              }
                              <input type="file" name='personalBackImage'
                                 disabled={Boolean(getKycDetails?.personalBackStatus === 3 || getKycDetails?.personalBackStatus === 1)}
                                 accept=".png,.jpg,.jpeg"
                                 onChange={async (e) => {
                                    try {
                                       let img = e.target.files[0]
                                       const reader = new FileReader();
                                       clearErrors("personalBackImage")
                                       setValue("personalBackImage", null)
                                       setIdBack(null)
                                       reader.onload = (event) => {
                                          const bytes = new Uint8Array(event.target.result);
                                          if (bytes[0] === 0x89 && bytes[1] === 0x50 || bytes[0] === 0xFF && bytes[1] === 0xD8) { // png, apng, jpg, jpeg
                                             idBackImg(e)
                                          } else {
                                             setError("personalBackImage", { message: 'Please select image file' })
                                          }
                                       };
                                       reader.onerror = (error) => {

                                       };
                                       reader.readAsArrayBuffer(img);
                                    } catch (e) { }
                                 }} />
                           </div>
                        </div>
                        {errors.personalBackImage && <p className="errMsgKyc">* {errors.personalBackImage.message}</p>}
                     </div>
                  </div>

               </div>
            </div>
            <hr className="mb-5" />
            <div className="row">
               <div className="col-12">
                  <h3 className="secMdHeading">National ID</h3>
               </div>

               <div className="col-lg-5">
                  <p className="greyTxt">Only the following documents listed below will be accepted, all other documents will be rejected.</p>
                  <div className="col-lg-9 px-0">

                     <div className="form-group">
                        <label className="formLabel" htmlFor="Id3">Select</label>
                        <div className='octDrpdown'>
                           <Dropdown isOpen={dropdownOpenOne} toggle={toggleOne}
                              disabled={Boolean(
                                 (getKycDetails?.nationalFrontStatus === 3 || getKycDetails?.nationalFrontStatus === 1) &&
                                 (getKycDetails?.nationalBackStatus === 3 || getKycDetails?.nationalBackStatus === 1)
                              )}
                           >
                              <DropdownToggle>{getValues("nationalId") == "DrivingLicence" ? "Driving Licence" : getValues("nationalId") || "Select National ID"}</DropdownToggle>
                              <DropdownMenu>

                                 <DropdownItem onClick={() => { setOthers(false); setValue("nationalId", "Passport") }}>Passport</DropdownItem>


                                 {getKycDetails?.country === "India" || getKycDetails?.country === "IN" ?
                                    <>
                                       <DropdownItem onClick={() => { setOthers(false); setValue("nationalId", "Aadhar") }}>Aadhar</DropdownItem>
                                       <DropdownItem onClick={() => { setOthers(false); setValue("nationalId", "Voter ID") }}>Voter ID</DropdownItem>

                                    </>
                                    : <>
                                       <DropdownItem onClick={() => { setOthers(false); setValue("nationalId", "DrivingLicence") }}>Driving Licence</DropdownItem>
                                       <DropdownItem onClick={() => { setOthers(true); setValue("nationalId", "Others") }}>Others</DropdownItem>

                                    </>}
                              </DropdownMenu>
                           </Dropdown>
                        </div>
                        {!watchFields && errors.nationalId && <p className="errMsg">* {errors.nationalId.message}</p>}
                     </div>

                  </div>
                  {others &&
                     <div className="col-lg-9 px-0">
                        <div className="form-group">
                           <label className="formLabel" htmlFor="Id4">ID Name</label>
                           <Controller name='nationalIdName' control={control} render={({ field }) => (
                              <input
                                 {...field}
                                 type="text"
                                 maxLength={30}
                                 disabled={Boolean(
                                    (getKycDetails?.nationalFrontStatus === 3 || getKycDetails?.nationalFrontStatus === 1) &&
                                    (getKycDetails?.nationalBackStatus === 3 || getKycDetails?.nationalBackStatus === 1)
                                 )}
                                 className="form-control"
                                 onKeyPress={checkname}
                                 placeholder="Enter your ID Name"
                                 autoComplete='off'
                              />
                           )}
                           />
                        </div>
                        {errors.nationalIdName && <p className="errMsg">* {errors.nationalIdName.message}</p>}
                     </div>}
                  <div className="col-lg-9 px-0">
                     <div className="form-group">
                        <label className="formLabel" htmlFor="Id5">ID Number</label>
                        <Controller name='nationalIdNo' control={control} render={({ field }) => (
                           <input
                              {...field}
                              type="text"
                              className="form-control"
                              disabled={Boolean(
                                 (getKycDetails?.nationalFrontStatus === 3 || getKycDetails?.nationalFrontStatus === 1) &&
                                 (getKycDetails?.nationalBackStatus === 3 || getKycDetails?.nationalBackStatus === 1)
                              )}
                              maxLength={30}
                              onKeyPress={checkIDnumChar}
                              placeholder="Enter your ID Number"
                              autoComplete='off'
                           />
                        )} />
                        {errors.nationalIdNo && <p className="errMsg">* {errors.nationalIdNo.message}</p>}
                     </div>
                  </div>
               </div>

               <div className="col-lg-7">
                  <div className="d-flex flex-wrap">
                     <div className="form-group mr-3">
                        <label className="formLabel" htmlFor="Label1">Front Side</label>
                        <div className="uplCard"
                           style={{ borderColor }}
                        >
                           <div className="uplCnt">
                              {proofFront ?
                                 <div className="d-flex flex-column align-items-center">
                                    <img src={proofFront} width="150" height="150" alt="uploadIcon" />
                                 </div>
                                 :
                                 <div className="d-flex flex-column align-items-center">
                                    <img src={uploadIcon} width="50" height="40" alt="uploadIcon" />
                                    <p className="text-center greyTxt">Drag & Drop file or <br />Browser Your computer</p>
                                    <span className="prmyTxt">Click Here</span>
                                 </div>
                              }
                              <input type="file" name='nationalFrontImage'
                                 disabled={Boolean(getKycDetails?.nationalFrontStatus === 3 || getKycDetails?.nationalFrontStatus === 1)}
                                 accept=".png,.jpg,.jpeg"
                                 onChange={async (e) => {
                                    try {
                                       let img = e.target.files[0]
                                       const reader = new FileReader();
                                       clearErrors("nationalFrontImage")
                                       setValue("nationalFrontImage", null)
                                       setProofFront(null)
                                       reader.onload = (event) => {
                                          const bytes = new Uint8Array(event.target.result);
                                          if (bytes[0] === 0x89 && bytes[1] === 0x50 || bytes[0] === 0xFF && bytes[1] === 0xD8) { // png, apng, jpg, jpeg
                                             proofFrontImg(e)
                                          } else {
                                             setError("nationalFrontImage", { message: 'Please select image file' })
                                          }
                                       };
                                       reader.onerror = (error) => {

                                       };
                                       reader.readAsArrayBuffer(img);
                                    } catch (e) { }
                                 }} />
                           </div>
                        </div>
                        {errors.nationalFrontImage && <p className="errMsgKyc">* {errors.nationalFrontImage.message}</p>}
                     </div>
                     <div className="form-group">
                        <label className="formLabel" htmlFor="Label2">Back Side</label>
                        <div className="uplCard"
                           style={{ borderColor }}
                        >
                           <div className="uplCnt">
                              {proofBack ?
                                 <div className="d-flex flex-column align-items-center">
                                    <img src={proofBack} width="150" height="150" alt="uploadIcon" />
                                 </div>
                                 :
                                 <div className="d-flex flex-column align-items-center">
                                    <img src={uploadIcon} width="50" height="40" alt="uploadIcon" />
                                    <p className="text-center greyTxt">Drag & Drop file or <br />Browser Your computer</p>
                                    <span className="prmyTxt">Click Here</span>
                                 </div>
                              }
                              <label htmlFor="upl2"></label>
                              <input type="file" name='nationalBackImage'
                                 id='upl2'
                                 disabled={Boolean(getKycDetails?.nationalBackStatus === 3 || getKycDetails?.nationalBackStatus === 1)}
                                 accept=".png,.jpg,.jpeg"
                                 onChange={async (e) => {
                                    try {
                                       let img = e.target.files[0]
                                       const reader = new FileReader();
                                       clearErrors("nationalBackImage")
                                       setValue("nationalBackImage", null)
                                       setProofBack(null)
                                       reader.onload = (event) => {
                                          const bytes = new Uint8Array(event.target.result);
                                          if (bytes[0] === 0x89 && bytes[1] === 0x50 || bytes[0] === 0xFF && bytes[1] === 0xD8) { // png, apng, jpg, jpeg
                                             proofBackImg(e)
                                          } else {
                                             setError("nationalBackImage", { message: 'Please select image file' })
                                          }
                                       };
                                       reader.onerror = (error) => {

                                       };
                                       reader.readAsArrayBuffer(img);
                                    } catch (e) { }
                                 }} />
                           </div>
                        </div>
                        {errors.nationalBackImage && <p className="errMsgKyc">* {errors.nationalBackImage.message}</p>}
                     </div>
                  </div>
               </div>
            </div>
            <hr className="mb-5" />
            <div className="row">
               <div className="col-lg-5">
                  <p className="greyTxt">User needs to upload their selfie taken along with the proof mentioned above and date visible</p>
                  <ol className="greyTxt pl-3">
                     <li>No glasses are to be worn.</li>
                     <li>No hats are to be worn.</li>
                     <li>The face should be clearly visible.</li>
                     <li>Ensure that there is good lighting on your face.</li>
                     <li>Look directly at the camera.</li>
                     <li>Click a Selfie</li>
                  </ol>
               </div>

               <div className="col-lg-7">
                  <div className="form-group">
                     <label className="formLabel" htmlFor="Label2">Upload Selfie with proof</label>
                     <div className="uplCard lgUpl"
                        style={{
                           borderColor:
                              getKycDetails?.selfieImageStatus === 3 ? "green" : getKycDetails?.selfieImageStatus === 2 ? "red" : ""
                        }}>
                        <div className="uplCnt">
                           {selfie ?
                              <div className="d-flex flex-column align-items-center">
                                 <img src={selfie} width="150" height="150" alt="uploadIcon" />
                              </div>
                              :
                              <div className="d-flex flex-wrap align-items-center">
                                 <div className="mr-2 mr-sm-5">
                                    <img src={uploadIcon} width="50" height="40" alt="uploadIcon" />
                                 </div>
                                 <div className="d-flex flex-column align-items-center">
                                    <p className="text-center greyTxt">Drag & Drop file or <br />Browser Your computer</p>
                                    <span className="prmyTxt">Click Here</span>
                                 </div>
                              </div>
                           }
                           <label htmlFor="upl3"></label>
                           <input type="file" name='selfieImage'
                              id='upl3'
                              disabled={Boolean(getKycDetails?.selfieImageStatus === 3 || getKycDetails?.selfieImageStatus === 1)}
                              accept=".png,.jpg,.jpeg"
                              onChange={async (e) => {
                                 try {
                                    let img = e.target.files[0]
                                    const reader = new FileReader();
                                    clearErrors("selfieImage")
                                    setValue("selfieImage", null)
                                    setSelfie(null)
                                    reader.onload = (event) => {
                                       const bytes = new Uint8Array(event.target.result);
                                       if (bytes[0] === 0x89 && bytes[1] === 0x50 || bytes[0] === 0xFF && bytes[1] === 0xD8) { // png, apng, jpg, jpeg
                                          SelfieImg(e)
                                       } else {
                                          setError("selfieImage", { message: 'Please select image file' })
                                       }
                                    };
                                    reader.onerror = (error) => {

                                    };
                                    reader.readAsArrayBuffer(img);
                                 } catch (e) { }
                              }} />
                        </div>
                     </div>
                     {errors.selfieImage && <p className="errMsg">* {errors.selfieImage.message}</p>}
                  </div>
               </div>
               <SubmitButton getKycDetails={getKycDetails} loading={updateKycmutation.isLoading} />

            </div>
         </form>
      </>
   )
}

KycDetails.propTypes ={
   getKycDetails: PropTypes.shape({
         kycStatus: PropTypes.any,
         selfieImageStatus :  PropTypes.any,
         selfieImage :  PropTypes.any,
         nationalFrontStatus : PropTypes.any,
         nationalFrontImage : PropTypes.any,
         nationalBackStatus : PropTypes.any,
         nationalBackImage : PropTypes.any,
         personalFrontStatus : PropTypes.any,
         personalFrontImage : PropTypes.any,
         personalBackStatus: PropTypes.any,
         personalBackImage: PropTypes.any,
         personalId: PropTypes.any,
         personalIdNo: PropTypes.any,
         nationalId: PropTypes.any,
         nationalIdName: PropTypes.any,
         nationalIdNo : PropTypes.any,          
         country : PropTypes.any,          
         
    }),
    country : PropTypes.any 
}

export default KycDetails;

const SubmitButton = ({ getKycDetails, loading }) => {
   const { kycStatus } = getKycDetails;

   if (kycStatus === 3 || kycStatus === 1) return null
   else if (sessionStorage.getItem('type') == 'admin' || Cookies.get('sdgsdsadgt')) return (
      <Alert color="danger">
         <div className="d-flex align-items-center">
            <div className="m-4">
               <img src={dangerIcon} width="34" height="34" alt="dangerIcon" />
            </div>
            <div>
               <p>Access Denied !</p>
               <p>Login as User for further performance.</p>
            </div>
         </div>
      </Alert>
   )
   else return (
      <div className="col-12 d-flex justify-content-center mt-3">
         <button className="btn btn-11545 btnLg btnGold" disabled={loading}>
            {loading ?
               <Dots>Updating</Dots>
               :
               "Submit"
            }
         </button>
      </div>
   )
}

SubmitButton.propTypes ={
   getKycDetails: PropTypes.any,
   loading : PropTypes.any,
}