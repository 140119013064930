import { useQuery } from "react-query"
import StakeService from "../../config/StakeService";

const { stakeget } = StakeService();

export const useBalance = () => {
    return useQuery({
        queryKey: ["userBalance"],
        queryFn: () => {
            return stakeget('user/anaBalance')
        },
        select: res => res.balance
    })
}

export const useAnawalletBalance = () => {
    return useQuery({
        queryKey: ["ana-Wallet-Balance"],
        queryFn: (data) => {
            return stakeget('user/anaWalletBalance', {})
        },
    })
}