import { useMutation } from "react-query";
import DataService from "../../../config/DataService";
const { postRequest } = DataService()

export const useCheckAdminJwt = () => {
    return useMutation({
        mutationFn: (data) => {
            return postRequest("user/check_admin_view", { ...data })
        }
    })
}