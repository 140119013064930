export const usePaginationRange = ({ total, current, size }) => {
    let pagination = [], i = 1;

    let NofoPages = total / size
    NofoPages = Math.ceil(NofoPages)

    while (i <= NofoPages) {
        if (i <= 1 ||
            i >= total - 2 ||
            i >= current - 1 && i <= current + 1) {
            pagination.push(i);
            i++;
        } else {
            pagination.push('. . .');
            i = i < current ? current - 1 : total - 2;
        }
    }

    return pagination;
}