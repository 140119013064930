import { useMutation } from "react-query"
import StakeService from "../../../config/StakeService";
const { stakepost } = StakeService();


export const useLiquidityPoolReport = () => {
    return useMutation({
        mutationFn: (data) => {
            return stakepost('user/liquidityPoolReport', data)
        },
    })
}
