import React, { useState } from 'react'
import { useLoginHistory } from '../api/fetchDashboard'
import { HistorySkeleton } from '../../../Components/Loader/skeleton'
import { NotFound } from '../../Nodata/Nodatafound'
import Converter from '../../../lib/converter'
import Pagination from '../../common/pagination/Pagination'

function LoginHistory() {

    const [page, setPage] = useState(1);

    const { data: getLoginHistory, isLoading } = useLoginHistory({ page: page, size: 10 })

    let tableContent;

    if (isLoading) {
       tableContent = <HistorySkeleton />;
    } else if ( getLoginHistory?.data?.length > 0) {
       tableContent = getLoginHistory?.data.map((n, i) => (
        <tr key={n}>
        <td>{(page - 1) * 10 + (i + 1)}</td>
        <td>{Converter.formatDated(n.createdAt)}</td>
        <td>{n.os}</td>
        <td>{n.ip_address}</td>
        <td className="text-right">{n.location || "Unknown"}</td>
    </tr>
       ));
    } else {
       tableContent = (
          <tr>
                                                        <td colSpan={4}><NotFound message="No Data Found" /></td>          </tr>
       );
    }
    return (
            <div className="container animated_div">
                <h3 className="secHeading text-center">Login History</h3>
                <div className="row">
                    <div className="col-12">
                        <div className="card tableCard">
                            <div className="cardInnerCnt">
                                <div className="table-responsive">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th>S.NO</th>
                                                <th>Date and Time</th>
                                                <th>Device</th>
                                                <th>IP Address</th>
                                                <th className="text-right">Location</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tableContent
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <Pagination
                            total={getLoginHistory?.count}
                            current={page}
                            size={10}
                            onPageChange={no => setPage(no)}
                        />
                    </div>
                </div>
            </div>
    )
}

export default LoginHistory