import "../../assets/scss/Innerpages/Innerpages.scss";
import { Fragment, useEffect } from "react";
import { useCms } from "./api";
import { WaitingLoader } from "../../Components/Loader/buttonLoad";
import ReactHtmlParser from 'react-html-parser';


const Faq = () => {

   useEffect(() => {
      window.scrollTo({
         top: 0,
         behavior: 'smooth'
      })
   }, [])
   const { data: cms, isLoading } = useCms("faq")

   return (
      <Fragment>
         {isLoading && <WaitingLoader />}

         <h3 className="secHeading text-center">Frequently Asked Questions</h3>

         <div className="container pt-4">
            <div className="faqContainer">
               {cms?.status && cms?.data.map((data, index) => (
                  <div className="faqRow" key={data}>
                     <h3 className="cmsHeading">{index + 1}. {data?.page_title}</h3>
                     {ReactHtmlParser(data?.pagecontent)}
                  </div>
               ))}
            </div>
         </div>
      </Fragment>

   )
}
export default Faq;