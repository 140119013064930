import { useQuery, useMutation } from 'react-query'
import DataService from '../../../config/DataService'
import { useToast } from '../../../hook/usetoast'
import { useNavigate } from 'react-router-dom'
const { getRequest, postRequest } = DataService()
export const fetchSiteSettings = async () => {
    return getRequest('home/site-settings')
}

export const useFetchSiteSettings = () => {
    const navigate = useNavigate()

    return useQuery({
        queryKey: ['fetchSiteSettings'],
        queryFn: () => fetchSiteSettings(),
        select: res => res.data,
        staleTime: 0,
        refetchOnWindowFocus: true,
        
        onSuccess: (success) => {

            if (success?.maintenance) {
                return navigate('/UnderMaintenance')
            }
        }
    })
}

export const fetchDocs = async () => {
    return getRequest('home/get_doc')
}

export const useFetchDocs = () => {
    return useQuery({
        queryKey: ['fetchDocs'],
        queryFn: () => fetchDocs()
    })
}

export const useGetProfile = () => {
    const token = sessionStorage.getItem('token_ana')
    const type = sessionStorage.getItem('type')
    const check = (token && type)
    return useQuery({
        queryKey: ['get-profile'],
        queryFn: () => {
            return getRequest("user/get_profile_details")
        },
        enabled : !!check,
        select: res => res?.data
    })
}

export const useLogout = () => {
    const toast = useToast();
    const navigate = useNavigate()
    return useMutation({
        mutationFn: () => {
            return postRequest("user/logout")
        },
        onSuccess: success => {
            if (success?.status) {
                toast.success('Logout successfully')
                navigate('/login')
            }
        }
    })
}

export const useCheckLogin = () => {

    return useMutation({
        mutationFn: () => {
            return postRequest("user/checkLogin")
        },
    })
}


export const useJwtLink = () => {
    const toast = useToast();
    const navigate = useNavigate()
    const logout = useLogout();

    return useMutation({
        mutationFn: data => {
            return postRequest("user/checking_Jwt", { ...data })
        },
        onSuccess: async (success) => {
            if (!success?.status) {
                await logout.mutateAsync({})
                sessionStorage.removeItem("token_ana")
                sessionStorage.removeItem("loginno")
                toast.error(success?.message == 'Link expired' ?
                    'Session expired' : success?.message
                )
                return navigate('/login')
            }
        }
    })
}
