import React from 'react'
import { NotFound } from '../../Nodata/Nodatafound'
import Pagination from '../../common/pagination/Pagination'



function TransactionHistory() {





    return (
            <div className="container animated_div">
                <h3 className="secHeading text-center">Transaction History</h3>
                <div className="row">
                    <div className="col-12">
                        <div className="card tableCard">
                            <div className="cardInnerCnt">
                                <div className="table-responsive">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th>S.No</th>
                                                <th>Date & Time</th>
                                                <th>Type</th>
                                                <th>Address</th>
                                                <th>Amount</th>
                                                <th>Transaction ID</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                          
                                                    <tr>
                                                        <td colSpan={6}><NotFound message="No Data Found" /></td>
                                                    </tr>
                                            
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <Pagination
                            total={10}
                            current={0}
                            size={10}
                        />
                    </div>
                </div>
            </div>
    )
}

export default TransactionHistory