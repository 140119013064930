import React, { useState, useEffect } from 'react'
import { useModalContext } from '../../../contexts/ModalContext';
import { Submitbutton } from '../../common/Submitbutton';


const Deposite = () => {


  const [error, setError] = useState(false);
  const { siteSettings} = useModalContext();


  useEffect(() => {
      defaultcurrency()
  }, [])

  const defaultcurrency = async () => {

    setError(true)
  }


  return (
    <form className="form-row">

         <div className="col-12 d-flex justify-content-center mt-3">
                            <Submitbutton
                                buttonText="Submit"
                                Status={siteSettings?.withdrawStatus}
                                message="Deposit disabled. Users cannot be able to Deposit temporarily"
    
                            />
    
                        </div>

    </form>
  )
}

export default Deposite