import notFound from '../../assets/images/cms/4001.png';
import logo from '../../assets/images/logo.png';
import { BounceInDownButton } from '../../Components/animation/bouncein';


const UnAuthorized = () => {
    
    return (
        <div className="innerpagesSec">
            <section className="innerPagesCnt notFdCnt">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10 err_pg">
                            <div className="d-flex justify-content-center">
                                <img src={logo} className="img-fluid" alt='logo'/>
                            </div>
                            <img src={notFound} className="img-fluid err_img" alt='notFound'/>
                            <BounceInDownButton>
                                <h3 className="secHeading text-center">Unauthorized Request</h3>
                            </BounceInDownButton>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default UnAuthorized;