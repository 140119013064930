import { ColorRing } from 'react-loader-spinner';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

export const NewsLoader = () => {
    let arr = [1, 2, 3, 4]
    return (
        <div className='row'>
            {arr.map(n => (
                <div className='col-md-3' key={n}>
                    <SkeletonTheme baseColor="#202020" highlightColor="#444" borderRadius={23}>
                        <Skeleton height={196} />
                        <div className='my-2'>
                            <Skeleton height={25} count={2} className='w-50' />
                        </div>
                        <Skeleton height={20} count={2} />
                        <Skeleton height={25} className='mt-3 w-50' />
                    </SkeletonTheme>
                </div>
            ))}
        </div>
    );
}

export const HistorySkeleton = () => {
    let arr = [1, 2, 3, 4, 5,6,7,8,9]
    return (
        arr.map(n => (
            <SkeletonTheme baseColor="#202020" highlightColor="#444" key={n}>
                <tr>
                    <td><Skeleton height={25} /></td>
                    <td><Skeleton height={25} /></td>
                    <td><Skeleton height={25} /></td>
                    <td><Skeleton height={25} /></td>
                    <td className="text-right"><Skeleton height={25} /></td>
                </tr>
            </SkeletonTheme>
        ))
    )
}

export const KYCSkeleton = () => {
    let arr = [1, 2]
    return (
        <SkeletonTheme baseColor="#1c1c1c" highlightColor="#1e1e1e" borderRadius={12}>
            <div className='text-center'>
                <Skeleton height={60} className='mb-4 w-50' />
            </div>
            {arr.map(item => (
                <>
                    <div className='row align-items-center my-4' key={item}>
                        <div className='col-md-5'>
                            <Skeleton height={30} width={150} className='mb-4' />
                            <Skeleton height={20} />
                            <Skeleton height={20} className='w-50 mb-4' />
                            <Skeleton height={30} width={150} className='mb-2' />
                            <Skeleton height={50} className='w-75 mb-2' />
                            <Skeleton height={30} width={150} className='mb-2' />
                            <Skeleton height={50} className='w-75 mb-2' />
                        </div>
                        <div className='col-md-7 row'>
                            <div className='col-md-6'>
                                <Skeleton height={30} width={150} className='mb-4' />
                                <Skeleton height={200} borderRadius={25} />
                            </div>
                            <div className='col-md-6'>
                                <Skeleton height={30} width={150} className='mb-4' />
                                <Skeleton height={200} borderRadius={25} />
                            </div>
                        </div>
                    </div>
                    <hr />
                </>
            ))}
            <div className='row my-5'>
                <div className='col-md-6'>
                    <Skeleton height={20} className='mb-2' />
                    <Skeleton height={20} className='w-50 mb-4' />
                    <Skeleton height={20} count={5} className='mb-2 w-75' />
                </div>
                <div className='col-md-6'>
                    <Skeleton height={30} width={150} className='mb-4' />
                    <Skeleton height={200} borderRadius={25} />
                </div>
            </div>
        </SkeletonTheme>
    )
}

export const TransferHistorySkeleton = () => {
    let arr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
    return (
        arr.map(n => (
            <SkeletonTheme baseColor="#202020" highlightColor="#444" key={n}>
                <tr>
                    <td><Skeleton height={25} /></td>
                    <td><Skeleton height={25} /></td>
                    <td><Skeleton height={25} /></td>
                    <td><Skeleton height={25} /></td>
                    <td><Skeleton height={25} /></td>
                    <td><Skeleton height={25} /></td>
                    <td><Skeleton height={25} /></td>
                    <td><Skeleton height={25} /></td>

                </tr>
            </SkeletonTheme>
        ))
    )
}

export const StakeViewSkeleton = () => {
    let arr = [0, 1, 2, 3]
    return (
        arr.map(n => (
            <SkeletonTheme baseColor="#202020" highlightColor="#444" key={n}>
                <div className="stakeViewRow">
                    <div className="stakeViewInnerCnt">
                        <div className="stakeValueCnt">
                            <Skeleton height={25} />
                            <Skeleton height={25} />
                        </div>
                        <div className="stakeValueCnt">
                            <Skeleton height={25} />
                            <Skeleton height={25} />
                        </div>
                        <div className="stakeValueCnt">
                            <Skeleton height={25} />
                            <Skeleton height={25} />
                        </div>
                        <div className="stakeValueCnt">
                            <Skeleton height={25} />
                            <Skeleton height={25} />
                        </div>
                        <div className="stakeValueCnt">
                            <Skeleton height={25} />
                            <Skeleton height={25} />
                        </div>
                        <div className="stakeValueCnt">
                            <Skeleton height={25} />
                            <Skeleton height={25} />
                        </div>
                        <div className="stakeValueCnt">
                            <Skeleton height={25} />
                            <Skeleton height={25} />
                        </div>
                        <div className="stakeValueCnt">
                            <Skeleton height={30} width={30} borderRadius={50} />
                        </div>
                    </div>
                </div>
            </SkeletonTheme>
        ))
    )
}

export const CoinListLoader = () => {
    return (
        <span className="d-flex justify-content-center align-items-center" style={{ minHeight: "30vh" }}>
            <ColorRing
                visible={true}
                height="40"
                width="40"
                ariaLabel="color-ring-loading"
                wrapperStyle={{}}
                wrapperClass="color-ring-wrapper"
                colors={['#F8DC8F', '#F8DC8F', '#F8DC8F', '#F8DC8F', '#F8DC8F']}
            />
        </span>
    )
}