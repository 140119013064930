import { Link, useNavigate , useLocation } from 'react-router-dom';
import successGif from '../../assets/images/Auth/successGif.gif';
import { useEffect } from 'react';

export const SuccessBuy = () => {

    const price = useLocation()       
    return (
        <section className="authPagesCnt" style={{ height: "100vh" }}>
            <div className="row justify-content-center">
                <div className="col-lg-10">
                    <div className="d-flex justify-content-center">
                        <img src={successGif} className="img-fluid" alt="successGif" width="185" height="185" />
                    </div>
                    <h3 className="modalHeading">ANA - Buy Successful</h3>
                    <h5 className="cardLabel whiteTxt text-center mb-2">Buy Amount</h5>
                    <h5 className="cardValue text-center mb-3">{parseFloat(price?.state?.participateprice)} ANA</h5>
                    <p className="modalSubHeading">Your Request has been confirmed. You can track its
                        progress on the participate store</p>

                    <div className="d-flex justify-content-center">
                        <Link to="/participate" className="btn btn-11545 btnGold mr-2" type="button">Back to Participate</Link>
                        <Link to="/participate-store" className="btn btn-11545 btnGold" type="button">Go to Participate Store</Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export const SuccessRegister = () => {
    const navigate = useNavigate()
    useEffect(() => {
        let interval = setTimeout(() => {
            navigate('/login')
        }, 1000);

        return (() => clearInterval(interval))
    })
    return (
        <div className="row justify-content-center animated_div">
            <div className="col-lg-8">
                <h3 className="modalHeading">Your Account Has Been Registered</h3>
                <p className="modalSubHeading">Your Registered Login Member ID is sent via Mail ID</p>
                <div className="d-flex justify-content-center">
                    <img src={successGif} className="img-fluid" alt="successGif" width="250" height="250" />
                </div>
                <div className="d-flex justify-content-center">
                    <Link to="/login" className="btn btn-11545 btnGold">Login</Link>
                </div>
            </div>
        </div>
    )
}