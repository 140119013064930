import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useSigninTFA } from './api/authmutations';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Dots } from '../../lib/dots';
import { BounceInDownButton } from '../../Components/animation/bouncein';
import { WaitingLoader } from '../../Components/Loader/buttonLoad';
import PropTypes from 'prop-types';
import { generateSecureRandomNumber } from '../../hook/generateSecureRandomNumber';
const schema = yup.object().shape({
   code: yup.string()
      .matches(/^\d{6}$/, 'Invalid OTP Code')
      .required('OTP Code is Required'),
})

const TFASettings = ({ email }) => {

   const tfaLoginmutation = useSigninTFA()
   const navigate = useNavigate();
   const [loading, setLoading] = useState(false)

   const { control, handleSubmit, formState: { errors }, reset } = useForm({
      mode: 'onChange',
      resolver: yupResolver(schema),
      defaultValues: {
         code: '',
      }
   });

   const handleKeyDown = (e) => {
     
      const regex = /^\d{0,6}$/;
    
      const value = e.target.value + e.key; 
     
      if (!regex.test(value)) {
        e.preventDefault();
      }
    };
    
   const onSubmit = async (data) => {
      setLoading(true)
      let login_no = Math.floor((generateSecureRandomNumber() * 10000) + 1);
      data["email"] = email;
      data['login_no'] = login_no
      let res = await tfaLoginmutation.mutateAsync(data)
      if (res?.status) {
         sessionStorage.setItem("loginno", login_no)
         sessionStorage.setItem("email", email)
         sessionStorage.setItem("token_ana", res.token)
         sessionStorage.setItem('type', 'user')
         setLoading(false)
         reset()
         navigate('/dashboard')
      }
      else {
         setLoading(false)
      }
   }

   return (
      <BounceInDownButton>
         {loading && <WaitingLoader />}
         <div className="row justify-content-center align-items-center animated_div">
            <div className="col-lg-8">
               <h3 className="modalHeading">Welcome Back</h3>
               <p className="modalSubHeading">OTP Verification</p>
               <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row justify-content-center">
                     <div className="col-lg-6">
                        <div className="form-group">
                           <label className="formLabel" htmlFor="regId1">OTP code</label>
                           <Controller name='code' control={control} render={({ field }) => (
                              <input
                                 {...field}
                                 type="text"
                                 className="form-control"
                                 maxLength={6}
                                 onInput={handleKeyDown}
                                 id="regId1" placeholder="Enter your code"
                                 autoComplete='off'
                              />
                           )} />
                           {errors.code && <p className="errMsg">* {errors.code.message}</p>}
                        </div>
                     </div>
                  </div>
                  <div className="d-flex justify-content-center">
                     <button className="btn btn-11545 btnGold" disabled={tfaLoginmutation.isLoading}>
                        {tfaLoginmutation.isLoading ?
                           <Dots>Loading</Dots>
                           :
                           "Submit"}
                     </button>
                  </div>
               </form>
            </div>
         </div>
      </BounceInDownButton>
   )
}

TFASettings.propTypes = {
   email: PropTypes.any
}
export default TFASettings;