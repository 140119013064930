import moment from 'moment';

const Datecomponent = (dateString) => {
    if(dateString){
    const date = moment(dateString, "ddd MMM DD YYYY HH:mm:ss ZZ");
    const month = date.format("MM");
    const day = date.format("D");
    const year = date.format("YYYY");
    return day + "/" + month + "/" + year
    }else{
        return 
    }

}

export default Datecomponent;