import "../../../../assets/scss/Home/Tokenomics/Tokenomics.scss";
import tokenmicsGraph from '../../../../assets/images/Home/Tokenomics/tokenmicsGraph.png';
import PropTypes from 'prop-types'; 
import { Parallax } from "react-scroll-parallax";
import Tokenomicrobosvg from "./Tokenomicsrobo";


const Tokenomics = ({data}) => {
   return (
      <section className="tokenomicsSec" id="tokenomicsSec">
         <div className="tknmcsUpperCnt">
            <div className="container">
               <div className="abtHd">
                  <div className="row">
                     <div className="col-lg-12">
                        <div className="abtHdTls text-center">
                           <div className="secTlTg">
                              <span>Anacoin</span>
                           </div>
                           <Parallax speed={3}>
                              <h2 className="secTl my-4">
                                 <span className="txt-gradient">Tokenomics</span>
                              </h2>
                           </Parallax>                           
                        </div>
                     </div>
                  </div>
               </div>
               <div className="row">
                  <div className="col-12">
                     <div className="tokenomicsCnt borderedCnt lgCnt">
                        <div className="row">
                           <div className="col-lg-6 pr-lg-0 d-flex justify-content-start align-items-center">
                              <div className="coinStructureCnt">
                                 <h3 className="mb-2">{data?.status ? data?.data[6]?.title : ''}</h3>
                                 <p dangerouslySetInnerHTML={{ __html: data?.status ? data?.data[6]?.pagecontent : '' }} />
                                 <div className="d-flex flex-wrap">
                                    <div className="coinStructCnt">
                                       <div>Name</div>
                                       <h3>ANACOIN</h3>
                                    </div>
                                    <div className="coinStructCnt">
                                       <div>Ticker</div>
                                       <h3>ANA</h3>
                                    </div>
                                    <div className="coinStructCnt">
                                       <div>Decimals</div>
                                       <h3>18</h3>
                                    </div>
                                    <div className="coinStructCnt">
                                       <div>Network</div>
                                       <h3>Binance smart chain BEP[20]</h3>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="col-lg-6 d-flex justify-content-center align-items-center pl-lg-0 tokenomicsRoboImgCnt">
                              <Tokenomicrobosvg />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className="tknmcsBottomCnt">
            <div className="container">
               <div className="row">
                  <div className="col-lg-6 d-flex justify-content-center">
                     <div className="tknmcsGraphCnt my-3">
                        <div className="tokenBanner">
                           <img src={tokenmicsGraph} className="img-fluid" width="" height="" alt="tokenmicsGraph" />
                        </div>                           
                     </div>                     
                  </div>
                  <div className="col-lg-6 supplyCnt">
                     <h3 className="heading text-center mb-3">Total Supply <span>1,000,000,000 ANA</span></h3>
                     <div className="tokenomicsCnt borderedCnt">
                        <div className="d-flex flex-wrap align-items-center justify-content-between supplyRow">
                           <span className="typeCnt">Direct stacking</span>
                           <span className="percCnt">25%</span>
                           <span className="valueCnt">250,000,000 ANA</span>
                           <span className="grpLegends" style={{background: "#FF0000"}}></span>
                        </div>
                        <div className="d-flex flex-wrap align-items-center justify-content-between supplyRow">
                           <span className="typeCnt">Indirect Staking</span>
                           <span className="percCnt">15%</span>
                           <span className="valueCnt">150,000,000 ANA</span>
                           <span className="grpLegends" style={{background: "#00C537"}}></span>
                        </div>
                        <div className="d-flex flex-wrap align-items-center justify-content-between supplyRow">
                           <span className="typeCnt">Yield Release</span>
                           <span className="percCnt">8%</span>
                           <span className="valueCnt">80,000,000 ANA</span>
                           <span className="grpLegends" style={{background: "#FF782D"}}></span>
                        </div>
                        <div className="d-flex flex-wrap align-items-center justify-content-between supplyRow">
                           <span className="typeCnt">Research</span>
                           <span className="percCnt">5%</span>
                           <span className="valueCnt">50,000,000 ANA</span>
                           <span className="grpLegends" style={{background: "#BD00A4"}}></span>
                        </div>
                        <div className="d-flex flex-wrap align-items-center justify-content-between supplyRow">
                           <span className="typeCnt">Marketing</span>
                           <span className="percCnt">5%</span>
                           <span className="valueCnt">50,000,000 ANA</span>
                           <span className="grpLegends" style={{background: "#959595"}}></span>
                        </div>
                        <div className="d-flex flex-wrap align-items-center justify-content-between supplyRow">
                           <span className="typeCnt">Liquidity</span>
                           <span className="percCnt">10%</span>
                           <span className="valueCnt">100,000,000 ANA</span>
                           <span className="grpLegends" style={{background: "#959595"}}></span>
                        </div>
                        <div className="d-flex flex-wrap align-items-center justify-content-between supplyRow">
                           <span className="typeCnt">Public Sale</span>
                           <span className="percCnt">10%</span>
                           <span className="valueCnt">100,000,000 ANA</span>
                           <span className="grpLegends" style={{background: "#00C537"}}></span>
                        </div>
                        <div className="d-flex flex-wrap align-items-center justify-content-between supplyRow">
                           <span className="typeCnt">Advisory Directors</span>
                           <span className="percCnt">4%</span>
                           <span className="valueCnt">40,000,000 ANA</span>
                           <span className="grpLegends" style={{background: "#000066"}}></span>
                        </div>
                        <div className="d-flex flex-wrap align-items-center justify-content-between supplyRow">
                           <span className="typeCnt">Premium Partners</span>
                           <span className="percCnt">5%</span>
                           <span className="valueCnt">50,000,000 ANA</span>
                           <span className="grpLegends" style={{background: "#2E2E2E"}}></span>
                        </div>
                        <div className="d-flex flex-wrap align-items-center justify-content-between supplyRow">
                           <span className="typeCnt">Founding Team</span>
                           <span className="percCnt">3%</span>
                           <span className="valueCnt">30,000,000 ANA</span>
                           <span className="grpLegends" style={{background: "#FF0000"}}></span>
                        </div>
                        <div className="d-flex flex-wrap align-items-center justify-content-between supplyRow">
                           <span className="typeCnt">Air Drops</span>
                           <span className="percCnt">10%</span>
                           <span className="valueCnt">100,000,000 ANA</span>
                           <span className="grpLegends" style={{background: "#00CCFF"}}></span>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
   )
}
Tokenomics.propTypes ={
   data: PropTypes.shape({
         status: PropTypes.any,
         data: PropTypes.arrayOf(
            PropTypes.shape({
               title: PropTypes.any,
               pagecontent : PropTypes.any
            })
        ),
    }), 
}
export default Tokenomics;