import { Navigate, Outlet } from "react-router-dom";
import "../assets/scss/Innerpages/Innerpages.scss";
import CopyRight from "../Components/CopyRight/CopyRight";
import Header from "../Components/Header/Header";


const Innerpages = () => {
   let token = sessionStorage.getItem('token_ana')

   return (
      token ?
         <div className="innerpagesSec">
            < Header />
            <section className="innerPagesCnt">
               <Outlet />
            </section>
            <CopyRight />
         </div>
         :
         <Navigate to='/login' />
   )
}
export default Innerpages;