import { useMutation, useQuery } from "react-query";
import { useToast } from "../../../hook/usetoast";
import { useNavigate } from "react-router-dom";
import DataService from "../../../config/DataService";
import { useLogout } from "../../../Components/Header/api";
const { getRequest, postRequest } = DataService()


export const useLoginHistory = ({ page, size }) => {

    return useQuery({
        queryKey: ["login-History", page, size],
        queryFn: () => {
            return getRequest(`user/get_login_history?page=${page}&size=${size}`)
        },
    })
}

export const useGetTFA = () => {

    return useQuery({
        queryKey: ["get-TFA"],
        queryFn: () => {
            return getRequest("user/get_TFA")
        },
        select: res => res.data
    })
}


export const useTfaSettings = () => {
    const toast = useToast();
    const navigate = useNavigate();
    const logout = useLogout();

    return useMutation({
        mutationFn: (data) => {
            return postRequest("user/enable_disable_tfa", { ...data })
        },
        onSuccess: async (success) => {
            if (success?.status) {
                await logout.mutateAsync({})
                sessionStorage.removeItem("token_ana")
                sessionStorage.removeItem("loginno")
                toast.success(success?.message)
                return navigate('/')
            }
            return toast.error(success?.message)
        }
    })
}