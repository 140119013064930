import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useCheckLink, useResetMPIN } from './api/authmutations';
import { Dots } from '../../lib/dots';
import { Link, useParams } from 'react-router-dom';
import { BounceInDownButton } from '../../Components/animation/bouncein';
import EyeSlashIcon from '../../assets/images/Auth/eye-slash.png'
import EyeIcon from '../../assets/images/Auth/eye.png'
import { WaitingLoader } from '../../Components/Loader/buttonLoad';
import { generateSecureRandomNumber } from '../../hook/generateSecureRandomNumber';
const schema = yup.object().shape({
    NewMPIN: yup.string().required('MPIN is Required'),
    ConfirmMPIN: yup.string()
        .oneOf([yup.ref('NewMPIN'), null], "Confirm MPIN doesn't match")
        .required('Confirm MPIN is Required'),
})

const ResetMPIN = () => {

    const checklinkmutation = useCheckLink()
    const resetmpinMutation = useResetMPIN()
    const { token } = useParams();
    const [loading, setLoading] = useState(false)

    const [show, setShow] = useState(true);
    const [showCon, setShowCon] = useState(true);

    const { control, handleSubmit, formState: { errors, isValid, isDirty }, reset } = useForm({
        mode: 'onChange',
        resolver: yupResolver(schema),
        defaultValues: {
            NewMPIN: '',
            ConfirmMPIN: "",
        }
    });

    useEffect(() => {
        CheckvalidLink();
    }, [])

    const CheckvalidLink = async () => {
        await checklinkmutation.mutateAsync({ link: token })
    }

    
    const handleKeyDown = (e) => {
     
        const regex = /^\d{0,6}$/;
      
        const value = e.target.value + e.key; 
       
        if (!regex.test(value)) {
          e.preventDefault();
        }
      };
    const onSubmit = async (data) => {
        setLoading(true)
        let random = Math.floor(generateSecureRandomNumber() * 10000)
        sessionStorage.setItem('random', random)
        data["link"] = token
        data["random"] = random
        await resetmpinMutation.mutateAsync(data)
        setLoading(false)
        reset()
    }

    const showPassword = e =>{
        if(e.key === "Enter" || e.nativeEvent.pointerType === "mouse" || e.nativeEvent.pointerType === "touch"){
           setShow(!show)
        }
    }

    const showConPassword = e =>{
        if(e.key === "Enter" || e.nativeEvent.pointerType === "mouse" || e.nativeEvent.pointerType === "touch" ){
          setShowCon(!showCon)
        }
     }

    return (
        <BounceInDownButton>
            {loading && <WaitingLoader />}
            <div className="row justify-content-center animated_div">
                <div className="col-lg-6 col-xl-4">
                    <h3 className="modalHeading">Reset MPIN</h3>
                    <p className="modalSubHeading">Create New MPIN</p>
                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className="form-group">
                            <label className="formLabel" htmlFor="regId9">MPIN</label>
                            <div className="input-group mb-3">
                                <Controller name='NewMPIN' control={control} render={({ field }) => (
                                    <input
                                        {...field}
                                        type={show ? "password" : "text"}
                                        className="form-control"
                                        maxLength={6}
                                        onInput={handleKeyDown}
                                        id="regId9" placeholder="Enter your MPIN"
                                        autoComplete='off'
                                    />
                                )} />
                                <div className="input-group-append">
                                    <span className="input-group-text" type="button" onClick={(e) => showPassword(e)} onKeyDown={(e) => showPassword(e)}>
                                        {show ? <img src={EyeSlashIcon} width="20" height="20" alt="eyeSlashIcon" /> :
                                            <img src={EyeIcon} width="20" height="20" alt="eyeSlashIcon" />}
                                    </span>
                                </div>
                            </div>
                            {errors.NewMPIN && <p className="errMsg">* {errors.NewMPIN.message}</p>}
                        </div>
                        <div className="form-group">
                            <label className="formLabel" htmlFor="regId8">Confirm MPIN</label>
                            <div className="input-group mb-3">
                                <Controller name='ConfirmMPIN' control={control} render={({ field }) => (
                                    <input
                                        {...field}
                                        type={showCon ? "password" : "text"}
                                        className="form-control"
                                        maxLength={6}
                                        onInput={handleKeyDown}
                                        id="regId8" placeholder="Confirm your MPIN"
                                        autoComplete='off'
                                    />
                                )} />
                                <div className="input-group-append">
                                    <span className="input-group-text" type="button" onClick={(e) => showConPassword(e)} onKeyDown={(e) => showConPassword(e)}>
                                        {showCon ? <img src={EyeSlashIcon} width="20" height="20" alt="eyeSlashIcon" /> :
                                            <img src={EyeIcon} width="20" height="20" alt="eyeSlashIcon" />}
                                    </span>
                                </div>
                            </div>
                            {errors.ConfirmMPIN && <p className="errMsg">* {errors.ConfirmMPIN.message}</p>}
                        </div>

                        <div className="d-flex justify-content-center mb-4">
                            <button className="btn btn-11545 btnGold" disabled={!isValid || !isDirty || !resetmpinMutation}>
                                {resetmpinMutation.isLoading ? <Dots>Loading</Dots> : "Reset MPIN"}
                            </button>
                        </div>
                        <p className="text-center">
                            <Link to='/login' className="yellowTxt mx-2">Back to Login</Link>
                        </p>
                    </form>
                </div>
            </div>
        </BounceInDownButton>
    )
}
export default ResetMPIN;