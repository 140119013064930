import eyeSlashIcon from '../../assets/images/Auth/eye-slash.png';
import eyeIcon from "../../assets/images/Auth/eye.png";

import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Dots } from '../../lib/dots';
import { useBalance } from "../common/fetchBalance";
import { useState } from "react";
import { ButtonLoader } from "../../Components/Loader/buttonLoad";
import { useGetCurrentPhase, useMovetoStore } from "../StakeStore/api/fetchstakestore";
import Converter from "../../lib/converter";
import { Submitbutton } from '../common/Submitbutton';
import { useModalContext } from '../../contexts/ModalContext';
import { useToast } from '../../hook/usetoast';
import { formatAmount } from '../../lib/formatAmount';
import PropTypes from 'prop-types';
import { generateSecureRandomNumber } from '../../hook/generateSecureRandomNumber';

const RedeemStore = ({ toggleRedeem }) => {

    const { data: getBalance, isLoading } = useBalance();
    const schema = yup.object().shape({
        ana: yup.number()
            .typeError('Amount is Required')
            .required('Amount is Required')
            .positive('Amount must be in positive values')
            .min(1,'Minimum amount must be 1')
            .test('not-zero', 'The price should be at least Zero above', (value) => value > 0)
            .test('max-decimal-places', 'The amount must have at most 6 decimal places', value => {
                if (value) {
                    const numString = formatAmount(value.toString());
                    const [, decimal] = numString.includes('.') ? numString.split('.') : [numString, ''];
                    return !decimal || decimal.length >= 6;
                }
                return true;
            }).test(
                'greater-than',
                'Insufficient balance',
                function (value) {
                   if (getBalance && getBalance?.anabalance !== undefined) {
                      return +value <= getBalance?.anabalance;
                   }
                   return true;
                }
             ),
        MPIN: yup.string()
            .required('MPIN is Required')
            .matches(/^\d{6}$/, 'MPIN must be 6 digits'),
    })
    const stakeStoremutation = useMovetoStore()
    const { data: currentPhasemutation } = useGetCurrentPhase()
    const toast = useToast()
    const [show, setShow] = useState(false)
    const toggleShow = (e) => {
        if (e.key === "Enter" || e.nativeEvent.pointerType === "mouse" || e.nativeEvent.pointerType === "touch") {setShow(!show)}}
    const { profileData , hisupdated, setHisupdated } = useModalContext();

    const { control, handleSubmit, formState: { errors }, reset } = useForm({
        mode: 'onChange',
        resolver: yupResolver(schema),
        defaultValues: {
            ana: null,
            MPIN: "",
        }
    });


    const handleKeyDown = (e) => {
     
        const regex = /^\d{0,6}$/;
      
        const value = e.target.value + e.key; 
       
        if (!regex.test(value)) {
          e.preventDefault();
        }
      };

    let x = Math.floor(generateSecureRandomNumber() * 90000) + 10000;
    const onSubmit = async (data) => {
        try {
            if (getBalance?.anabalance < data?.ana) {
                toast.error('Insufficient Balance')
            } else {
                data["randomId"] = x.toString();
                data["type"] = "redeem";
                data["phase"] = currentPhasemutation?.phase;

                let res = await stakeStoremutation.mutateAsync(data)
                if (res?.status) {
                    setHisupdated(!hisupdated)
                    reset()
                    toggleRedeem()
                }
            }
        } catch (error) {

        }

    }

    return (
        <section className="authPagesCnt animated_div">
            <div className="row justify-content-center w-100">
                <div className="col-lg-6 col-xl-4">
                    <h3 className="modalHeading">Redeem Store</h3>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                            <label className="formLabel" htmlFor="loginId3">ANACOIN</label>
                            <div className="input-group mb-3">
                                <Controller name='ana' control={control} render={({ field }) => (
                                    <input
                                        {...field}
                                        type='number'
                                        className={`form-control  ${errors.ana && "is-invalid"}`}
                                        maxLength={6}
                                        placeholder="Enter amount"
                                        autoComplete='off'
                                        onChange={e => {
                                            const value = e.target.value;
                                            const regex = /^\d*(\.\d{0,6})?$/;
                                            if (regex.test(value) || value === "") {
                                                field.onChange(value);
                                            }
                                        }}
                                        onWheel={(e) => e.target.blur()}
                                        onKeyDown={(e) => {
                                            if (e.key === '-' || e.key === 'e' || e.key === 'E') {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                )} />
                                <div className="input-group-append">
                                    <span className="input-group-text">
                                        <span className="mx-2 prmyTxt">ANA</span>
                                    </span>
                                </div>
                            </div>
                            {errors?.ana && <p className="errMsg">* {errors?.ana?.message}</p>}
                            <div className="d-flex">
                                <span className="scdryTxt">Available Balance</span>
                                {isLoading ?
                                    <span className="prmyTxt ml-sm-auto"><ButtonLoader /></span> :
                                    <span className="prmyTxt ml-sm-auto">{getBalance?.anabalance > 0 ? Converter.formattedValue(getBalance?.anabalance) + " ANA": getBalance?.anabalance == 0 ? "0 ANA":""} </span>}
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="formLabel" htmlFor="loginId1">Select Store</label>
                            <input type="text" value="Redeem Store" className="form-control" id="loginId1"
                                readOnly
                                placeholder="Redeem Store" />
                        </div>
                        <div className="form-group">
                            <label className="formLabel" htmlFor="loginId2">MPIN</label>
                            <div className="input-group mb-3">
                                <Controller name='MPIN' control={control} render={({ field }) => (
                                    <input
                                        {...field}
                                        type={show ? "text" : "password"}
                                        className={`form-control  ${errors.MPIN && "is-invalid"}`}
                                        placeholder="Enter Your MPIN"
                                        onInput={handleKeyDown}
                                        autoFocus
                                        autoComplete='off'
                                    />
                                )} />
                                <div className="input-group-append">
                                    <span className="input-group-text">
                                        {show ?
                                           <button style={{ all: 'unset', cursor: 'pointer' }} onClick={(e)=>toggleShow(e)} onKeyDown={(e)=>toggleShow(e)}>
                                               <img src={eyeIcon} width="20" height="20" alt="eyeSlashIcon" />
                               
                                           </button>
                                            :
                                            <button style={{ all: 'unset', cursor: 'pointer' }} onClick={(e)=>toggleShow(e)} onKeyDown={(e)=>toggleShow(e)}>
                               
                                                <img src={eyeSlashIcon} width="20" height="20" alt="eyeSlashIcon" />
                                            </button>
                                        }
                                    </span>
                                </div>
                            </div>
                            {errors.MPIN && <p className="errMsg">* {errors.MPIN.message}</p>}
                        </div>
                        <div className="d-flex justify-content-center my-4">
                            {profileData?.kyc_status == 3 && sessionStorage.getItem('type') != 'admin' ?
                                <button className="btn btn-11545 btnGold" disabled={false}>
                                    {stakeStoremutation.isLoading ?
                                        <Dots>Sending</Dots>
                                        :
                                        "Send"}
                                </button>
                                :
                                <Submitbutton
                                    buttonText="Submit"
                                    loading={stakeStoremutation.isLoading}
                                    message="Ana Wallet option disabled. Users can't buy temporarily"
                                />}
                        </div>
                    </form>
                </div>
            </div>
        </section>
    )
}

RedeemStore.propTypes ={
    toggleRedeem: PropTypes.any,
}
export default RedeemStore;