import eyeSlashIcon from '../../assets/images/Auth/eye-slash.png';
import eyeIcon from "../../assets/images/Auth/eye.png";

import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useBalance } from "../common/fetchBalance";
import { useState } from "react";
import { useGetCurrentPhase, useMovetoStore } from "./api/fetchstakestore";
import { ButtonLoader, WaitingLoader } from "../../Components/Loader/buttonLoad";
import { useModalContext } from "../../contexts/ModalContext";
import { Submitbutton } from "../common/Submitbutton";
import Converter from "../../lib/converter";
import { useToast } from '../../hook/usetoast';
import { formatAmount } from '../../lib/formatAmount';
import PropTypes from 'prop-types';
import { generateSecureRandomNumber } from '../../hook/generateSecureRandomNumber';

const StakeStore = ({ toggle }) => {

    const { data: getBalance, isLoading } = useBalance();

    const schema = yup.object().shape({
        ana: yup.number()
            .typeError('Amount is Required')
            .required('Amount is Required')
            .positive('Amount must be in positive values')
            .test('not-zero', 'The price should be at least Zero above', (value) => value > 0)
            .test('max-decimal-places', 'The amount must have at most 6 decimal places', value => {
                if (value) {
                    const numString = formatAmount(value.toString());
                    const [, decimal] = numString.includes('.') ? numString.split('.') : [numString, ''];
                    return !decimal || decimal.length >= 6;
                }
                return true;
            })
            .test(
                'greater-than',
                'Insufficient balance',
                function (value) {
                   if (getBalance && getBalance?.anabalance !== undefined) {
                      return +value <= getBalance?.anabalance;
                   }
                   return true;
                }
             ),
        MPIN: yup.string()
            .required('MPIN is Required')
            .matches(/^\d{6}$/, 'MPIN must be 6 digits'),
    })
    
    const { siteSettings,hisupdated, setHisupdated } = useModalContext()
    const stakeStoremutation = useMovetoStore()
    const { data: currentPhasemutation } = useGetCurrentPhase()
    const toast = useToast()

    const [show, setShow] = useState(false)
    const toggleShow = (e) => {if (e.key === "Enter" || e.nativeEvent.pointerType === "mouse" || e.nativeEvent.pointerType === "touch") {setShow(!show)}}

    const { control, handleSubmit, formState: { errors }, reset } = useForm({
        mode: 'onChange',
        resolver: yupResolver(schema),
        defaultValues: {
            ana: null,
            MPIN: "",
        }
    });


    const handleKeyDown = (e) => {
     
        const regex = /^\d{0,6}$/;
      
        const value = e.target.value + e.key; 
       
        if (!regex.test(value)) {
          e.preventDefault();
        }
      };

    let x = Math.floor(generateSecureRandomNumber() * 90000) + 10000;
    const onSubmit = async (data) => {
        try {
            if (getBalance?.anabalance < data?.ana) {
                toast.error('Insufficient Balance')
            } else {
                data["randomId"] = x.toString();
                data["type"] = "stake";
                data["phase"] = currentPhasemutation?.phase;
                let res = await stakeStoremutation.mutateAsync(data)
                if (res?.status) {
                    setHisupdated(!hisupdated)
                    reset()
                    toggle()
                }

            }
        } catch (error) {

        }
    }
    let balanceText;
    if (getBalance?.anabalance > 0) {
        balanceText = Converter.formattedValue(getBalance?.anabalance) + " ANA";
    } else if (getBalance?.anabalance === 0) {
        balanceText = "0 ANA";
    } else {
        balanceText = "";
    }
    return (
        <section className="authPagesCnt animated_div">
            {stakeStoremutation.isLoading && <WaitingLoader />}
            <div className="row justify-content-center w-100">
                <div className="col-lg-6 col-xl-4">
                    <h3 className="modalHeading">To Stake Store</h3>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                            <label className="formLabel" htmlFor="loginId3">ANACOIN</label>
                            <div className="input-group mb-3">
                                <Controller name='ana' control={control} render={({ field }) => (
                                    <input
                                        {...field}
                                        type='number'
                                        className={`form-control  ${errors.ana && "is-invalid"}`}
                                        maxLength={6}
                                        placeholder="Enter amount"
                                        autoComplete='off'
                                        onWheel={(e) => e.target.blur()}
                                        onKeyDown={(e) => {
                                            if (e.key === '-' || e.key === 'e' || e.key === 'E') {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                )} />
                                <div className="input-group-append">
                                    <span className="input-group-text">
                                        <span className="mx-2 prmyTxt">ANA</span>
                                    </span>
                                </div>
                            </div>
                            {errors?.ana && <p className="errMsg">* {errors?.ana?.message}</p>}
                            <div className="d-flex">
                                <span className="scdryTxt">Available Balance</span>
                                {isLoading ?
                                    <span className="prmyTxt ml-sm-auto"><ButtonLoader /></span> :
                                    <span className="prmyTxt ml-sm-auto">{balanceText}</span>}
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="formLabel" htmlFor="loginId1">Select Store</label>
                            <input type="text" value="Stake Store" className="form-control" id="loginId1"
                                readOnly
                                placeholder="Stake Store" />
                        </div>
                        <div className="form-group">
                            <label className="formLabel" htmlFor="loginId2">MPIN</label>
                            <div className="input-group mb-3">
                                <Controller name='MPIN' control={control} render={({ field }) => (
                                    <input
                                        {...field}
                                        type={show ? "text" : "password"}
                                        className={`form-control  ${errors.MPIN && "is-invalid"}`}
                                        placeholder="Enter Your MPIN"
                                        maxLength={6}
                                        onInput={handleKeyDown}
                                        autoFocus
                                        autoComplete='off'
                                    />
                                )} />
                                <div className="input-group-append">
                                    <span className="input-group-text">
                                        {show ?
                                           <button style={{ all: 'unset', cursor: 'pointer' }} onClick={(e)=>toggleShow(e)} onKeyDown={(e)=>toggleShow(e)}>
                                         <img src={eyeIcon} width="20" height="20" alt="eyeSlashIcon" />

                                           </button>
                                            :
                                            <button style={{ all: 'unset', cursor: 'pointer' }} onClick={(e)=>toggleShow(e)} onKeyDown={(e)=>toggleShow(e)}>
                               
                                                <img src={eyeSlashIcon} width="20" height="20" alt="eyeSlashIcon" />
                                            </button>
                                        }
                                    </span>
                                </div>
                            </div>
                            {errors.MPIN && <p className="errMsg">* {errors.MPIN.message}</p>}
                        </div>
                        <div className="d-flex justify-content-center my-4">
                            <Submitbutton
                                buttonText="Send"
                                loading={stakeStoremutation.isLoading}
                                Status={siteSettings?.stakeStore_status}
                                message="Stake option disabled. Users can't stake temporarily"
                            />
                        </div>
                    </form>
                </div>
            </div >
        </section >
    )
}
StakeStore.propTypes ={
    toggle: PropTypes.any,
}
export default StakeStore;