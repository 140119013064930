import eyeSlashIcon from '../../../assets/images/Auth/eye-slash.png';
import eyeIcon from "../../../assets/images/Auth/eye.png";

import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useState } from "react";
import { useModalContext } from "../../../contexts/ModalContext";
import { Submitbutton } from "../../common/Submitbutton";
import { useToast } from '../../../hook/usetoast';
import { formatAmount } from '../../../lib/formatAmount';
import { useGetCurrentPhase } from '../../StakeStore/api/fetchstakestore';

import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const MoveANA = ({ Balance }) => {
   
    const generateSecureRandomNumber = () => {
        const array = new Uint32Array(1);
        window.crypto.getRandomValues(array);
        return array[0] % 90000 + 10000; // Generates a random number between 10000 and 99999
        }
    const [randomData, setRandomData] = useState(Math.floor(100000 + generateSecureRandomNumber() * 900000))


    const [wallet, setWallet] = useState('ANA Wallet')
    const schema = yup.object().shape({
        ana: yup.number()
            .typeError('Amount is Required')
            .required('Amount is Required')
            .positive('Amount must be in positive values')
            .test('not-zero', 'The price should be at least Zero above', (value) => value > 0)
            .test('max-decimal-places', 'The amount must have at most 6 decimal places', value => {
                if (value) {
                    const numString = formatAmount(value.toString());
                    const [, decimal] = numString.includes('.') ? numString.split('.') : [numString, ''];
                    return !decimal || decimal.length >= 6;
                }
                return true;
            })
            .test(
                'greater-than',
                'Insufficient balance',
                function (value) {
                    if (Balance && Balance !== undefined) {
                        return +value <= Balance;
                    }
                    return true;
                }
            ),
        MPIN: yup.string()
            .required('MPIN is Required')
            .matches(/^\d{6}$/, 'MPIN must be 6 digits'),
    })

    const { siteSettings } = useModalContext()
    const { data: currentPhasemutation } = useGetCurrentPhase()
    const toast = useToast()

    const [show, setShow] = useState(false)
    const toggleShow = (e) => { if (e.key === "Enter" || e.nativeEvent.pointerType === "mouse" || e.nativeEvent.pointerType === "touch") { setShow(!show) } }
    const navigate = useNavigate()

    const { control, handleSubmit, formState: { errors }, reset } = useForm({
        mode: 'onChange',
        resolver: yupResolver(schema),
        defaultValues: {
            ana: null,
            MPIN: "",
        }
    });

    

    const handleKeyDown = (e) => {
     
        const regex = /^\d{0,6}$/;
      
        const value = e.target.value + e.key; 
       
        if (!regex.test(value)) {
          e.preventDefault();
        }
      };

    const wallets = [
        { walletName: 'ANA Wallet' }, //Here we need to add Participate Wallet
    ]


    const onSubmit = async (data) => {
        try {
            if (Balance < data?.ana) {
                toast.error('Insufficient Balance')
            } else {
                data["randomId"] = randomData;
                data["type"] = wallet;
                data["phase"] = currentPhasemutation?.phase;
                
             
                    toast.error("Coming Soon")
                

            }
        } catch (error) {

        }
    }

    const handleCurrencyChange = async (n) => {
        setWallet(n)
    }

    return (


        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
                <label className="formLabel" htmlFor="loginId3">ANACOIN</label>
                <div className="input-group mb-3">
                    <Controller name='ana' control={control} render={({ field }) => (
                        <input
                            {...field}
                            type='number'
                            className={`form-control  ${errors.ana && "is-invalid"}`}
                            maxLength={6}
                            placeholder="Enter amount"
                            autoComplete='off'
                            onWheel={(e) => e.target.blur()}
                            onKeyDown={(e) => {
                                if (e.key === '-' || e.key === 'e' || e.key === 'E') {
                                    e.preventDefault();
                                }
                            }}
                        />
                    )} />
                    <div className="input-group-append">
                        <span className="input-group-text">
                            <span className="mx-2 prmyTxt">ANA</span>
                        </span>
                    </div>
                </div>
                {errors?.ana && <p className="errMsg">* {errors?.ana?.message}</p>}
                <div className="d-flex">
                    <span className="scdryTxt">Available Balance</span>
                    <span className="prmyTxt ml-sm-auto">{Balance > 0 ? Balance + " ANA" : "0 ANA"}</span>
                </div>
            </div>
            <div className="form-group">
                <label className="formLabel" htmlFor="loginId1">Select Wallet</label>
                <UncontrolledDropdown>
                    <DropdownToggle caret>
                        {wallet}
                    </DropdownToggle>
                    <DropdownMenu>
                        {wallets?.map((n) => (

                            <DropdownItem tag="a" key={n} onClick={() => handleCurrencyChange(n.walletName)}
                            >
                                <span className="mx-2">
                                    {n.walletName} </span>
                            </DropdownItem>
                        ))}
                    </DropdownMenu>
                </UncontrolledDropdown>
            </div>
            <div className="form-group">
                <label className="formLabel" htmlFor="loginId2">MPIN</label>
                <div className="input-group mb-3">
                    <Controller name='MPIN' control={control} render={({ field }) => (
                        <input
                            {...field}
                            type={show ? "text" : "password"}
                            className={`form-control  ${errors.MPIN && "is-invalid"}`}
                            placeholder="Enter Your MPIN"
                            maxLength={6}
                            onInput={handleKeyDown}
                            autoFocus
                            autoComplete='off'
                        />
                    )} />
                    <div className="input-group-append">
                        <span className="input-group-text">
                            {show ?
                              <button style={{ all: 'unset', cursor: 'pointer' }} onClick={(e) => toggleShow(e)} onKeyDown={(e) => toggleShow(e)}>
                                  <img src={eyeIcon} width="20" height="20" alt="eyeSlashIcon"  />
                               
                              </button>
                              
                                :
                                <button style={{ all: 'unset', cursor: 'pointer' }} onClick={(e) => toggleShow(e)} onKeyDown={(e) => toggleShow(e)}>
                               
                                    <img src={eyeSlashIcon} width="20" height="20" alt="eyeSlashIcon"  />
                                </button>
                            }
                        </span>
                    </div>
                </div>
                {errors.MPIN && <p className="errMsg">* {errors.MPIN.message}</p>}
            </div>
            <div className="d-flex justify-content-center my-4">
                <Submitbutton
                    buttonText="Send"
                    Status={siteSettings?.stakeStore_status}
                />
            </div>
        </form>

    )
}
MoveANA.propTypes = {
    Balance : PropTypes.any
}
export default MoveANA;