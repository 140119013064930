import Header from "../Components/Header/Header";
import "../assets/scss/authPages/authPages.scss";
import { Navigate, Outlet } from "react-router-dom";

const AuthLayout = () => {
   let token = sessionStorage.getItem('token_ana')

   return (
      token ?
         <Navigate to='/' />
         :
         <>
            <Header />
            <section className="authPagesCnt">
               <div className="container-fluid">
                  <Outlet />
               </div>
            </section>
         </>
   )
}
export default AuthLayout;