import { useMutation, useQuery } from "react-query"
import DataService from "../../../config/DataService"
import { useToast } from "../../../hook/usetoast";
import { queryClient } from "../../../config";
const { getRequest, postRequest } = DataService();

export const useKycDetails = () => {

    return useQuery({
        queryKey: ['get-user-kyc'],
        queryFn: () => {
            return getRequest("user/get_user_kyc")
        },
        select: res => res?.data
    })
}

export const useUpdateKyc = () => {
    const toast = useToast();

    return useMutation({
        mutationFn: (data) => {
            return postRequest('user/create_user_kyc', data)
        },
        onSuccess: success => {
            if (success?.status) {
                queryClient.refetchQueries("get-profile")
                return toast.success(success?.message)
            }
            return toast.error(success?.message)
        }
    })
}