import React, { Fragment } from 'react'
import { TransferHistorySkeleton } from '../../../Components/Loader/skeleton';
import Nodatafound from '../../Nodata/Nodatafound';
import Converter from '../../../lib/converter';
import Pagination from '../../common/pagination/Pagination';
import PropTypes from 'prop-types';
function RedeemTransferHis({ transferList, page, setPage, isLoading }) {

    return (
        <Fragment>
            <div className="card tableCard">
                <div className="cardInnerCnt">
                    <div className="table-responsive">
                        <table className="table table-hover mb-0">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Type</th>
                                    <th>Date and Time</th>
                                    <th>From MemberID</th>
                                    <th>To MemberID</th>
                                    <th>Amount</th>
                                    <th>DetectAmount</th>
                                    <th className="text-right">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading ?
                                    <TransferHistorySkeleton />
                                    :
                                    transferList?.data?.length > 0 ?
                                        transferList?.data?.map((n, i) => (
                                            <tr className='animated_div' key={n}>
                                                <td>{i + 1}</td>
                                                <td>{n.type}</td>
                                                <td>{Converter.formatDated(n.createdAt)}</td>
                                                <td>{n.FromMemid}</td>
                                                <td>{n.toMemberid}</td>
                                                <td>{Converter.formattedValue(n.amount)} ANA</td>
                                                <td>{Converter.formattedValue(n.detectAmount)} ANA</td>
                                                <td className={`text-right text-success`}>Completed</td>
                                            </tr>
                                        ))
                                        :
                                        <tr>
                                            <td colSpan={8}><Nodatafound message="No Data found." /> </td>
                                        </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <Pagination
                total={transferList?.count}
                current={page}
                size={10}
                onPageChange={no => setPage(no)}
            />
        </Fragment>
    )
}
RedeemTransferHis.propTypes ={
    transferList: PropTypes.any,
    page: PropTypes.any,
    setPage: PropTypes.any,
    isLoading: PropTypes.any,
}
export default RedeemTransferHis