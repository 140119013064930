import React, { useEffect, useState } from 'react';
import "../../../assets/scss/Innerpages/Innerpages.scss";
import xlsIcon from "../../../assets/images/xlsIcon.png";
import { useDebounce } from '../../../hook/useDounce';
import * as XLSX from "xlsx";
import Nodatafound from '../../Nodata/Nodatafound';
import { TransferHistorySkeleton } from '../../../Components/Loader/skeleton';
import Pagination from '../../common/pagination/Pagination';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { useExportApi, useStakeStoreHistory } from '../api/fetchstakestore';
import { ButtonLoader } from '../../../Components/Loader/buttonLoad';
import Converter from '../../../lib/converter';
import { useModalContext } from '../../../contexts/ModalContext';


const StakeHistory = () => {

    const size = 10;
    const [page, setPage] = useState(1)
    const [sort, setSort] = useState("1")
    const [filter, setFilter] = useState('')
    const searchText = useDebounce(filter)

    const [transferList, setTransferList] = useState([])
    const getStakeHistorymutation = useStakeStoreHistory()
    const exportmutation = useExportApi();
    const { profileData } = useModalContext();

    useEffect(() => {
        fetchData()
    }, [page, sort, searchText])

    const fetchData = async () => {
        let obj = {
            page: page,
            size: size,
            sort: sort,
            filter: searchText
        }
        let res = await getStakeHistorymutation.mutateAsync(obj)
        setTransferList(res)
    }

    const handleDownload = async () => {
        let res = await exportmutation.mutateAsync({ type: "tostake" , filter: searchText, dayFilter: ""  });
        if (res?.status) {
            const header = Object.keys(res.data[0]);
            const sheet = XLSX.utils.json_to_sheet(res.data, { header });

            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, sheet, "Sheet1");

            XLSX.writeFile(wb, `${profileData?.memberID}-Withdraw.xlsx`);
        }
    };

    return (
        <div className="container animated_div">
            <h3 className="secHeading text-center">Stake Store</h3>

            <div className="row">
                <div className="col-12">
                    <div className="d-flex align-items-center flex-wrap">
                        <h3 className="cardTitle lgTitle">Stake History</h3>
                        <div className="d-flex align-items-center filterCnt mb-3 ml-auto flex-wrap flex-md-nowrap">
                            <input
                                onChange={(e) => setFilter(e.target.value)}
                                type="search"
                                placeholder="Search"
                                className={(filter ? "form-control my-1" : "form-control searchbox my-1") } />

                            <div className='octDrpdown dark mx-1'>
                                <UncontrolledDropdown>
                                    <DropdownToggle caret>
                                        {sort === "1" ? "Ascending" : "Descending"}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem tag={"a"} onClick={() => setSort("1")}>Ascending</DropdownItem>
                                        <DropdownItem tag={"a"} onClick={() => setSort("-1")}>Descending</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </div>

                            <div className='octDrpdown dark mx-1'>
                                <UncontrolledDropdown>
                                    <DropdownToggle caret>
                                        Recently
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem tag={"a"}>Recently</DropdownItem>
                                        <DropdownItem tag={"a"}>1 Week Ago</DropdownItem>
                                        <DropdownItem tag={"a"}>1 Month Ago</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </div>

                            <div className="float-right">
                                {exportmutation.isLoading || getStakeHistorymutation.isLoading ?
                                    <button className="btn filterBtn my-1" disabled>
                                        <ButtonLoader />
                                    </button>
                                    :
                                    <button className="btn filterBtn my-1"
                                        disabled={Boolean(transferList?.data?.length <= 0) || !transferList?.data}
                                        onClick={handleDownload}
                                    >
                                        <img src={xlsIcon} width="24" height="24" alt="xlsIcon" />
                                        Export File
                                    </button>}
                            </div>

                        </div>
                    </div>

                    <div className="card tableCard">
                        <div className="cardInnerCnt">
                            <div className="table-responsive">
                                <table className="table table-hover mb-0">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Date and Time</th>
                                            <th>Member ID</th>
                                            <th>Amount Value</th>
                                            <th className="text-right">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {false ?
                                            (<TransferHistorySkeleton />)
                                            :(
                                                (() => {
                                                    if (transferList?.data?.length > 0) {
                                                        return transferList.data.map((n, i) => (
                                                            <tr className='animated_div' key={n.id || i}>  
                                                                <td>{(page - 1) * size + (i + 1)}</td>
                                                                <td>{Converter.formatDated(n.createdAt)}</td>
                                                                <td>{n.memberID}</td>
                                                                <td>{Converter.formattedValue(n.stakeValue)} ANA</td>
                                                                <td className="text-right text-success">Completed</td>
                                                            </tr>
                                                        ));
                                                    } else {
                                                        return (
                                                            <tr>
                                                                <td colSpan={7}><Nodatafound message="No Data found." /></td>
                                                            </tr>
                                                        );
                                                    }
                                                })()

                                            )
                                       
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <Pagination
                        total={transferList?.count}
                        current={page}
                        size={size}
                        onPageChange={no => setPage(no)}
                    />
                </div>
            </div>
        </div>
    )
}
export default StakeHistory;