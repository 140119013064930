import { useMutation, useQuery } from "react-query"
import { useToast } from "../../../hook/usetoast";
import { queryClient } from "../../../config";
import StakeService from "../../../config/StakeService";
import { useNavigate } from "react-router-dom";

const { stakeget, stakepost } = StakeService();

export const useYieldDetails = () => {

    return useQuery({
        queryKey: ['get-user-yield'],
        queryFn: () => {
            return stakeget("user/getYieldDetails")
        },
        select: data => data?.data
    })
}

export const useUpdateStakeStore = () => {
    const toast = useToast();
    const navigate = useNavigate();
    
    return useMutation({
        mutationFn: (data) => {
            return stakepost('user/stakeStoretostake', data)
        },
        onSuccess: success => {
            if (success?.status) {
                queryClient.refetchQueries("userBalance")
                queryClient.refetchQueries("get-stake-history")
                navigate("/stake-view")
                return toast.success(success?.message)
            }
            return toast.error(success?.message)
        }
    })
}


export const useMovetoStore = () => {
    const toast = useToast();

    return useMutation({
        mutationFn: (data) => {
            return stakepost('user/anaMove', data)
        },
        onSuccess: success => {
            if (success?.status) {
                queryClient.refetchQueries("userBalance")
                queryClient.refetchQueries("ana-Wallet-Balance")
                return toast.success(success?.message)
            }
            return toast.error(success?.message)
        }
    })
}

export const useStakeHistory = ({ phase }) => {

    return useQuery({
        queryKey: ['get-stake-history', phase],
        queryFn: () => {
            return stakeget(`user/stakeStoreHistory/${phase}`)
        },
        select: res => res
    })
}

export const useStakeStoreHistory = () => {

    return useMutation({
        mutationFn: (data) => {
            return stakepost("user/toStakeHistory", { ...data })
        },
        select: res => res
    })
}

export const useGetCurrentPhase = () => {

    return useQuery({
        queryKey: ["get-current-phase"],
        queryFn: () => {
            return stakeget('user/getCurrentPhase')
        },
        select: res => res.data
    })
}

export const useStoreClaim = () => {
    const toast = useToast();

    return useMutation({
        mutationFn: (data) => {
            return stakepost('user/stakeClaim', data)
        },
        onSuccess: success => {
            if (success?.status) {
                queryClient.refetchQueries("get-stake-history")
                return toast.success(success?.message)
            }
            return toast.error(success?.message)
        }
    })
}

export const useExportApi = () => {
    return useMutation({
        mutationFn: (data) => {
            return stakepost('user/exportApi', data)
        },
    })
}

export const useClaimHistory = () => {
    return useMutation({
        mutationFn: (data) => {
            return stakepost('user/StakeClaimHistory', { ...data })
        },
    })
}
