import React, { Fragment, useState } from 'react'
import xlsIcon from "../../../assets/images/xlsIcon.png";

import Pagination from '../../common/pagination/Pagination';

import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';


function DepositeHistory() {
    const size = 10;
    const [page, setPage] = useState(1)
    const [time, setTime] = useState(0);




    return (
        <Fragment>
            <div className="d-flex align-items-center">
                <h3 className="cardTitle lgTitle">Deposit History</h3>
                <div className="d-flex align-items-center filterCnt mb-3 ml-auto">
                
                        <input type="search" placeholder="Search" className="form-control searchbox"  />
                        <div className='octDrpdown dark'>
                            <UncontrolledDropdown>
                                <DropdownToggle caret>
                                    {time === 7 && "1 Week Ago"}
                                    {time === 30 && "1 Month Ago"}
                                    {!time && "Recently"}
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem tag={"a"} onClick={() => setTime(0)}>Recently</DropdownItem>
                                    <DropdownItem tag={"a"} onClick={() => setTime(7)}>1 Week Ago</DropdownItem>
                                    <DropdownItem tag={"a"} onClick={() => setTime(30)}>1 Month Ago</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                    

                    <button className="btn filterBtn my-1" >
                        <img src={xlsIcon} width="24" height="24" alt="xlsIcon" />
                        Export File
                    </button>
                </div>
            </div>

            <div className="card tableCard">
                <div className="cardInnerCnt">
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Date and Time</th>
                                    <th>Address</th>
                                    <th>Amount</th>
                                    <th>Transaction ID</th>
                                    <th className="text-right">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Pagination
                total={1}
                current={0}
                size={10}
                onPageChange={no => setPage(no)}
            />
        </Fragment>
    )
}

export default DepositeHistory