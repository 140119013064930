import { useQuery } from 'react-query'
import DataService from '../../../config/DataService'

const { getRequest } = DataService()

export const useFetchCms = () => {
    return useQuery({
        queryKey: ['fetchCms'],
        queryFn: () => {
            return getRequest('home/get_home_cms')
        }
    })
}

export const useFetchLatestNewsCms = () => {
    return useQuery({
        queryKey: ['fetchLatestNewsCms'],
        queryFn: () => {
            return getRequest('home/get_home_latest_cms')
        }
    })
}

// ----->singlenews/:id


export const UsegetOneNews = (id) => {
    return useQuery({
        queryKey: ['fetchNews'],
        queryFn: () => {
            return getRequest(`home/get_single_News?id=${id}`)
        }
    })
}

export const UseGetIcoStaking = () => {
    return useQuery({
        queryKey: ['icostaking'],
        queryFn: () => {
            return getRequest(`home/icostaking`)
        }
    })
}