import React, { useState } from 'react';
import moment from 'moment';
import "../../../assets/scss/Innerpages/Innerpages.scss";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { useBusinessvolume } from '../api/index.js'
import Nodatafound from '../../Nodata/Nodatafound.js';


const schema = yup.object().shape({
    fromDate: yup.date().required('From Date is required')
        .max(new Date(), 'Date must be before or equal to today'),
    toDate: yup.date()
        .required('To Date is required')
        .min(yup.ref('fromDate'), 'To Date must be after From Date')
        .max(new Date(), 'Date must be before or equal to today'),
});

const BusinessVolumeReport = () => {

    const businessMutation = useBusinessvolume()
    const [data, setData] = useState('')
    const [resfrom, setResfrom] = useState('')
    const [resto, setResto] = useState('')

    const { control, handleSubmit, formState: { isValid, isDirty }} = useForm({
        mode: 'All',
        resolver: yupResolver(schema),
        defaultValues: {
            fromDate: '',
            toDate: "",
        }
    });

    const onSubmit = async (data) => {
       
        setResfrom(data?.fromDate)
        setResto(data?.toDate)
        let obj =
        {
            startDate: Math.floor(data?.fromDate?.getTime()),
            endDate: Math.floor(data?.toDate?.getTime())
        }
        const fetchedData = await businessMutation.mutateAsync(obj);
        if (fetchedData.status) {
            setData(fetchedData)
        }
    }

    return (
        <div className="row">
            <div className="col-12">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="d-flex align-items-center flex-wrap">
                        <h3 className="cardTitle lgTitle">Business Volume Report</h3>

                        <div className="d-flex align-items-center filterCnt mb-3 ml-auto flex-wrap flex-md-nowrap">

                            <Controller
                                name="fromDate"
                                control={control}
                                render={({ field }) => (
                                    <input
                                        {...field}
                                        className="form-control my-1"
                                        label="From Date"
                                        type="date"
                                        max={new Date().toISOString().split('T')[0]} />
                                )}
                            />


                            <Controller
                                name="toDate"
                                control={control}
                                render={({ field }) => (
                                    <input
                                        {...field}
                                        className="form-control my-1"
                                        label="To Date"
                                        type="date"
                                        max={new Date().toISOString().split('T')[0]}
                                    />
                                )}
                            />

                            <button className="btn btn-11545 btnGold my-1" type="submit" disabled={!isValid || !isDirty} >Filter</button>

                        </div>

                    </div>
                </form>
            </div>
            <div className="col-12">
                {data.status ?
                    <>
                        {data?.data?.directBusiness > 0 || data?.data?.selfBusiness > 0 || data?.data?.sideBusiness > 0 ?

                            <div className="stakeViewRow">
                                <div className="stakeViewInnerCnt">
                                    <div className="row w-100">
                                        <div className="col-sm-6 col-md-4 col-lg-2">
                                            <h3 className="cardLabel mb-0">From Date</h3>
                                            <h3 className="cardLabel whiteTxt mb-xl-0">{moment(resfrom).format('D/ MM/ YYYY')}</h3>
                                        </div>
                                        <div className="col-sm-6 col-md-4 col-lg-2">
                                            <h3 className="cardLabel mb-0">To Date</h3>
                                            <h3 className="cardLabel whiteTxt mb-xl-0">{moment(resto).format('D/ MM/ YYYY')}</h3>
                                        </div>
                                        <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                            <h3 className="cardLabel mb-0">Self Business</h3>
                                            <h3 className="cardLabel whiteTxt mb-xl-0">{data?.data?.selfBusiness ? data?.data?.selfBusiness + " ANA" : "-"} </h3>
                                        </div>
                                        <div className="col-sm-6 col-md-5 col-lg-5 col-xl-3">
                                            <h3 className="cardLabel mb-0">Direct Level -1 (Business Volume)</h3>
                                            <h3 className="cardLabel whiteTxt mb-xl-0">{data?.data?.directBusiness ? data?.data?.directBusiness + " ANA" : "-"}</h3>
                                        </div>
                                        <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <h3 className="cardLabel mb-0">Side Business Volume</h3>
                                            <h3 className="cardLabel whiteTxt mb-xl-0">{data?.data?.sideBusiness ? data?.data?.sideBusiness + " ANA" : "-"}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :

                            <Nodatafound message="No Record Found." />
                        }
                    </>

                    : ""}
            </div>

        </div>

    )
}
export default BusinessVolumeReport;