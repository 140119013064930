import { toast as reactHotToast } from 'react-hot-toast'
import { X, CircleX, Copy } from 'lucide-react'

export const useToast = () => {
	return {
		success: (message) => {
			reactHotToast.dismiss();
			reactHotToast((t) => (
				<div className='alertToast show'>
					<div className='d-flex justify-content-between align-items-center'>
						<div className='d-flex align-items-center'>
							<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#05af05" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-circle-check"><circle cx="12" cy="12" r="10" /><path d="m9 12 2 2 4-4" /></svg>
							<span>{message}</span>
						</div>
						<button
							className='closeToastBtn'
							onClick={() => reactHotToast.dismiss(t.id)}
						>
							<X color='white' size={15} />
						</button>
					</div>
				</div>
			))
		},
		error: (message) => {
			reactHotToast.dismiss();
			reactHotToast((t) => (
				<div className='alertToast show'>
					<div className='d-flex justify-content-between align-items-center'>
						<div className='d-flex align-items-center'>
							<CircleX color='red' />
							<span>{message || "Intrenal server Error."}</span>
						</div>
						<div>
							<button
								className='closeToastBtn'
								onClick={() => reactHotToast.dismiss(t.id)}
							>
								<X color='white' size={15} />
							</button>
						</div>
					</div>
				</div>
			))
		},
		copy: (message) => {
			reactHotToast.dismiss();
			reactHotToast((t) => (
				<div className='alertToast show'>
					<div className='d-flex justify-content-between align-items-center'>
						<div className='d-flex align-items-center'>
							<Copy color='#F8DC8F' size={20} />
							<span>{message}</span>
						</div>
						<div>
							<button
								className='closeToastBtn'
								onClick={() => reactHotToast.dismiss(t.id)}
							>
								<X color='white' size={15} />
							</button>
						</div>
					</div>
				</div>
			))
		},
	}
}
