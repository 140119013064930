import { Outlet } from "react-router-dom";
import "../assets/scss/Innerpages/Innerpages.scss";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import CopyRight from "../Components/CopyRight/CopyRight";
import { useFetchCms } from "../Pages/Home/api/fetchHomecon";
import Loader from "../Components/Loader/Loader";
const Cms = () => {
   const { data, isLoading } = useFetchCms()

   return (
      <div className="innerpagesSec">
         <Loader loaded={isLoading} />
         <Header />
         <section className="innerPagesCnt cmsCnt">
            <Outlet />
         </section>
         <Footer arrdata={data} />
         <CopyRight />
      </div>
   )
}
export default Cms;