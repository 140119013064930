import { createContext, useContext, useState ,useMemo } from "react";
import PropTypes from 'prop-types';
const ModalContext = createContext();

export const useModalContext = () => useContext(ModalContext);

export const ModalContextProvider = ({ children }) => {

  const [siteSettings, setSiteSettings] = useState({});
  const [profileData, setProfileData] = useState({});
  const [isLoading, setIsLoading] = useState(true)
  const [minWithdraw, setMinWithdraw] = useState()
  const [minDeposit, setMinDeposit] = useState()
  const [isLoggedIn, setIsLoggedIn] = useState("")
  const [hisupdated, setHisupdated] = useState(false);
  const [countryuser, setCountryuser] = useState("IN");
  const [countryverified, setCountryverified] = useState(false);

  const value = useMemo(() => ({
    siteSettings, 
    setSiteSettings,
    profileData, 
    setProfileData,
    isLoading, 
    setIsLoading,
    minWithdraw, 
    setMinWithdraw,
    minDeposit, 
    setMinDeposit,
    isLoggedIn, 
    setIsLoggedIn,
    hisupdated, 
    setHisupdated,
    countryuser, 
    setCountryuser,
    countryverified, 
    setCountryverified
  }), [
    siteSettings, 
    profileData, 
    isLoading, 
    minWithdraw, 
    minDeposit, 
    isLoggedIn, 
    hisupdated, 
    countryuser, 
    countryverified
  ]);
  return (
  <ModalContext.Provider value={value}>
      {children}
    </ModalContext.Provider>
  );
};

ModalContextProvider.propTypes = {
    children : PropTypes.any,
}
