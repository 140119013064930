import { ColorRing } from "react-loader-spinner";

export const WaitingLoader = () => {
    return (
        <div className='page-loader'>
            <div />
        </div>
    );
}

export const ButtonLoader = () => {
    return (
        <span className="px-2">
            <ColorRing
                visible={true}
                height="30"
                width="30"
                ariaLabel="color-ring-loading"
                wrapperStyle={{}}
                wrapperClass="color-ring-wrapper"
                colors={['##F8DC8F', '##F8DC8F', '##F8DC8F', '##F8DC8F', '##F8DC8F']}
            />
        </span>
    )
}

export const QRLoader = () => {
    return (
        <span className="qrLoader">
            <ColorRing
                visible={true}
                height="40"
                width="40"
                ariaLabel="color-ring-loading"
                wrapperStyle={{}}
                wrapperClass="color-ring-wrapper"
                colors={['#F8DC8F', '#F8DC8F', '#F8DC8F', '#F8DC8F', '#F8DC8F']}
            />
        </span>
    )
}