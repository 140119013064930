import React, { useState, useEffect } from 'react';
import "../../assets/scss/Innerpages/Innerpages.scss";
import crown from "../../assets/images/crown.png";
import crown1 from "../../assets/images/crown1.png";
import crown2 from "../../assets/images/crown2.png";
import profile2user from "../../assets/images/profile-2user.png";
import pf2 from "../../assets/images/pf2.png";
import user from "../../assets/images/user.png";
import downarr from "../../assets/images/downarr.png";
import { useGetRedeemReport, useGetReferealBonusReport, useReferralReport, useVolumeReport, useIndividualReport, useIndividualTeamReportHistory, usePoolBonusReport } from './api/fetchBusinessStore';
import { ButtonLoader, WaitingLoader } from '../../Components/Loader/buttonLoad';
import { useNavigate } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import ReferalClaim from './ReferalClaim';
import PoolBonusClaim from './PoolBonusClaim';

const BusinessStore = () => {

   const { data: getreferralReport, isLoading } = useReferralReport()
   const { data: getredeemReport, isLoadings } = useGetRedeemReport()
   const { data: getVolumeReport, isFetching } = useVolumeReport()
   const { data: getIndividualReport, isLoading: getIndividualisLoading } = useIndividualReport()

   const IndividualTeamMutation = useIndividualTeamReportHistory()
   const [individualTeamTotal, setIndividualTeamTotal] = useState('')
   const [teamNo, setTeamNo] = useState(1)
   const [loading, setLoading] = useState(true)



   const navigate = useNavigate()

   const levelCards = async (e, count) => {

      try {
         if (e.key === "Enter" || e.nativeEvent.pointerType === "mouse" || e.nativeEvent.pointerType === "touch") {
            navigate('/level-member-history', { state: { level: count } });
         }
      } catch (error) {
      }
   };
   const levelCard = async (e, l, count) => {
      try {
         if (e.key === "Enter" || e.nativeEvent.pointerType === "mouse" || e.nativeEvent.pointerType === "touch") {
            navigate(`/bussiness-store-history/${l}`, { state: { count } });
         }
      } catch (error) {
      }
   };

   const roleHistory = async (e, l, count) => {
      try {
         if (e.key === "Enter" || e.nativeEvent.pointerType === "mouse" || e.nativeEvent.pointerType === "touch") {
            navigate(`/qualification/${l}`, { state: { count } });
         }
      } catch (error) {
      }
   };

   const premiumachievement = async (e, levels) => {
      try {
         if (e.key === "Enter" || e.nativeEvent.pointerType === "mouse" || e.nativeEvent.pointerType === "touch") {
            navigate('/premium-achievement', { state: { levels } });
         }
      } catch (error) {
      }
   };

   const [modalLogin, setModalLogin] = useState(false);
   const toggle = () => setModalLogin(!modalLogin);

   const [modalPoolBonus, setModalPoolBonus] = useState(false);
   const toggleBonus = () => setModalPoolBonus(!modalPoolBonus);

   const { data: getreferal, isLoading: getisLoading } = useGetReferealBonusReport();

   const { data: getpoolbonusreport, isLoding: poolBonusLoading } = usePoolBonusReport()

   const [poolbonusdata, setPoolbonusdata] = useState([])

   useEffect(() => {

      if (getpoolbonusreport) {
         const poolbonusarray = Object?.entries(getpoolbonusreport?.data?.poolBonus);

         const poolbonus = poolbonusarray?.map(([key, value]) => {
            return {
               staker: key,
               reward: value,
            };
         });
         setPoolbonusdata(poolbonus);
      }
   }, [getreferal, getpoolbonusreport])


   useEffect(() => {
      if (getIndividualReport?.status && getIndividualReport?.data?.referrals?.length > 0) {
         IndividualTeam(getIndividualReport?.data?.referrals[0].userId, 0)
      } else {
         setLoading(false)
      }
   }, [getIndividualisLoading])

   const IndividualTeam = async (userId, i) => {

      try {
         setLoading(true)
         const response = await IndividualTeamMutation.mutateAsync({ userId })
         if (response?.status) {
            setLoading(false)
            setIndividualTeamTotal(response)
            setTeamNo(i + 1)
         } else {
            setLoading(false)
         }
      } catch (error) {
         setLoading(false)

      }
   }

   const poolBonusReport = (e, type) => {
      if (e.key === "Enter" || e.nativeEvent.pointerType === "mouse" || e.nativeEvent.pointerType === "touch") {
         navigate('/poolbonus-report', { state: { type: type } });
      }
   }
   const handleClick = async (e, status) => {
      try {
         if (e.key === "Enter" || e.nativeEvent.pointerType === "mouse" || e.nativeEvent.pointerType === "touch") {
            navigate(`/oneTimeReward-history/${status}`);
         }
      } catch (error) {
      }
   };

   let memberLabel;

   if (getreferralReport?.levelCount?.length > 1) {
       memberLabel = getreferralReport?.levelCount[1]?.count < 1 ? 'Member' : 'Members';
   } else {
       memberLabel = 'Member'; 
   }

   let memberLabeltwo;

   if (getreferralReport?.levelCount?.length > 2) {
      memberLabeltwo = getreferralReport?.levelCount[2]?.count < 1 ? 'Member' : 'Members';
   } else {
      memberLabeltwo = 'Member'; 
   }

   let memberLabelthree;

   if (getreferralReport?.levelCount?.length > 3) {
      memberLabelthree = getreferralReport?.levelCount[3]?.count < 1 ? 'Member' : 'Members';
   } else {
      memberLabelthree = 'Member'; 
   }

   const levelCount = getreferralReport?.levelCount;
   let memberLabels;
   
   if (levelCount && levelCount.length > 0) {
     memberLabels = levelCount[0]?.count < 1 ? 'Member' : 'Members';
   } else {
     memberLabels = 'Member';
   }
   return (
      <div className="container">
         {isLoadings || isLoading || getIndividualisLoading && <WaitingLoader />}
         <div className="d-flex align-items-center flex-wrap justify-content-center">
            <h3 className="secHeading text-center">Business Store</h3>
         </div>

         <section className="BisTorsec cmntxt">
            <div className="ashBg">
               <div className="inrCnt">
                  <div className="row align-items-center justify-content-between borderbtm mx-2">
                     <div className="col-xl-3 col-lg-3 col-md-3 px-0">
                        <h4 className="pb-lg-0 pb-3">Referral Report</h4>
                     </div>
                     <div className="col-xl-8 col-lg-7 col-md-8 px-0">
                        <div className="row align-items-center justify-content-between mx-2">
                           <div className="col-xl-3 col-lg-4 col-md-4 mb-lg-0 mb-3 ">
                              <h5>Latest Stake</h5>
                              <h3 className="text-yellow mt-1" style={{ fontWeight: "bold" }}>{parseFloat((getreferralReport?.resultObj
                                 ?.latestStakeValue)?.toFixed(6)) || 0} ANA</h3>
                           </div>
                           <div className="col-xl-3 col-lg-4 col-md-4 mb-lg-0 mb-3 p-1">
                              <h5>Capping Available</h5>
                              <h3 className="text-yellow mt-1" style={{ fontWeight: "bold" }}>{parseFloat((getreferralReport?.resultObj
                                 ?.cappingAvailable)?.toFixed(6)) || 0} ANA</h3>
                           </div>
                           <div className="col-xl-3 col-lg-4 col-md-4  mb-lg-0 mb-3 hrline">
                              <h5>Latest capping value</h5>
                              <h3 className="text-yellow mt-1" style={{ fontWeight: "bold" }}>{parseFloat((getreferralReport?.resultObj
                                 ?.letestCapping)?.toFixed(6)) || 0} ANA</h3>
                           </div>
                           <div className="col-xl-3 col-lg-4 col-md-4  mb-lg-0 mb-3 mt-2">
                              <h5>Rewards earned</h5>
                              <h3 className='mt-1'>{parseFloat((getreferralReport?.resultObj
                                 ?.rewards)?.toFixed(6)) || 0} ANA</h3>
                           </div>
                        </div>
                     </div>
                     {getreferralReport?.Upline ?
                        <div className="col-xl-6 col-lg-5 col-md-4 px-0 d-flex flexwrap">
                           <h4 className="pb-lg-0 pb-3">Referred by : </h4>
                           <h3 className="text-yellow ml-3" style={{ fontWeight: "bold" }}>{getreferralReport?.Upline}</h3>
                        </div>
                        :
                        ""
                     }
                  </div>
                  <div className="row mt20">
                     <div className="col-xl-3 col-lg-3">
                        <div className="yellBG mb-lg-0 mb-3">
                           <h5 className="text-blk">Business Rank</h5>
                           <div className="d-flex align-items-center justify-content-end mt-2">
                              <img className="img-fluid" src={crown} alt='crown'/>
                              <h4 className="text-blk pl-2 mt-1">{getreferralReport?.resultObj?.totalStaked ? "Staker" : "Non Staker"}</h4>
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-9 col-lg-9">
                        <div>
                           <div className="ash1">
                              <div className="inrCnt">
                                 <h5>Current Business Rank</h5>
                                 <div className="d-flex align-items-center justify-content-between">
                                    <ul className="nav d-flex align-items-center justify-content-between w-100" role="tablist">
                                       <li className="nav-item">
                                          <a href="#" className={getreferralReport?.resultObj?.moonStaker ? "nav-link active d-flex align-items-center" : "nav-link d-flex align-items-center" } onClick={(e) => e.preventDefault()} >
                                             <img className="img-fluid crown1img" src={crown1} alt='crown'/>
                                             <img className="img-fluid crown2img" src={crown2} alt='crown'/>
                                             <span className="px-2"> Moon Staker</span>
                                          </a>
                                       </li>
                                       <li className="nav-item">
                                          <a href="#" className={getreferralReport?.resultObj?.executiveDirector ? "nav-link active d-flex align-items-center" : "nav-link d-flex align-items-center"} onClick={(e) => e.preventDefault()}>
                                             <img className="img-fluid crown1img" src={crown1} alt='crown'/>
                                             <img className="img-fluid crown2img" src={crown2} alt='crown'/>
                                             <span className="px-2">Executive Director</span>
                                          </a>
                                       </li>
                                       <li className="nav-item">
                                          <a href="#" className={getreferralReport?.resultObj?.premiumDirector ? "nav-link active d-flex align-items-center" : "nav-link d-flex align-items-center"} onClick={(e) => e.preventDefault()}>
                                             <img className="img-fluid crown1img" src={crown1} alt='crown'/>
                                             <img className="img-fluid crown2img" src={crown2} alt='crown'/>
                                             <span className="px-2">Premium Director</span>
                                          </a>
                                       </li>
                                       <li className="nav-item">
                                          <a href="#" className={getreferralReport?.resultObj?.titanDirector ? "nav-link active d-flex align-items-center" : "nav-link d-flex align-items-center"} onClick={(e) => e.preventDefault()}>
                                             <img className="img-fluid crown1img" src={crown1} alt='crown'/>
                                             <img className="img-fluid crown2img" src={crown2} alt='crown'/>
                                             <span className="px-2">Titan Director</span>
                                          </a>
                                       </li>
                                    </ul>
                                 </div>
                              </div>

                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="row mt26">
                     <div className="col-xl-3 col-lg-3 col-md-6 mb-lg-0 mb-3" key={0} >
                        <div className="ash2">
                           <div className="inrCnt">
                              <div className="d-flex align-items-center justify-content-between">
                                 <div className="d-flex align-items-center">
                                    <div className="levelbg">
                                       01
                                    </div>
                                    <h5 className="px-2">Level</h5>
                                 </div>
                                 <button style={{ all: 'unset', cursor: 'pointer' }} onClick={(e) => levelCards(e, getreferralReport?.levelCount[0]?.level)} onKeyDown={(e) => levelCards(e, getreferralReport?.levelCount[0]?.level)}>
                                 {getreferralReport?.levelCount?.length > 0 &&
                                    <h5 style={{ color: "#FFE388", textDecoration: "underline", cursor: "pointer" }} >View</h5>
                                 }   
                                 </button>
                              
                              </div>
                              <div className="d-flex align-items-center justify-content-end">
                                 <div className="d-flex align-items-center mt-5">
                                    <h3 className="text-yellow"> {getreferralReport?.levelCount[0]?.count || 0} </h3>
                                    <h5 className="px-2">{memberLabels}</h5>
                                    <img className="img-fluid crown2img" src={profile2user} alt='profile'/>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-3 col-lg-3 col-md-6 mb-lg-0 mb-3" key={1} >
                        <div className="ash2">
                           <div className="inrCnt">
                              <div className="d-flex align-items-center justify-content-between">
                                 <div className="d-flex align-items-center">
                                    <div className="levelbg">
                                       02
                                    </div>
                                    <h5 className="px-2">Level</h5>
                                 </div>
                                 <button style={{ all: 'unset', cursor: 'pointer' }} onClick={(e) => levelCards(e, getreferralReport?.levelCount[1]?.level)} onKeyDown={(e) => levelCards(e, getreferralReport?.levelCount[1]?.level)}>
                                 {getreferralReport?.levelCount?.length > 1 &&
                                    <h5 style={{ color: "#FFE388", textDecoration: "underline", cursor: "pointer" }} >View</h5>
                                 }   
                                 </button>

                               
                              </div>
                              <div className="d-flex align-items-center justify-content-end">
                                 <div className="d-flex align-items-center mt-5">
                                    <h3 className="text-yellow"> {getreferralReport?.levelCount[1]?.count || 0} </h3>
                                    <h5 className="px-2">{memberLabel}</h5>
                                    <img className="img-fluid crown2img" src={profile2user} alt='profile'/>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-3 col-lg-3 col-md-6 mb-lg-0 mb-3" key={3} >
                        <div className="ash2">
                           <div className="inrCnt">
                              <div className="d-flex align-items-center justify-content-between">
                                 <div className="d-flex align-items-center">
                                    <div className="levelbg">
                                       03
                                    </div>
                                    <h5 className="px-2">Level</h5>
                                 </div>
                                 <button style={{ all: 'unset', cursor: 'pointer' }} onClick={(e) => levelCards(e, getreferralReport?.levelCount?.length > 2 ? getreferralReport?.levelCount[2]?.level : 0)} onKeyDown={(e) => levelCards(e, getreferralReport?.levelCount[1]?.level)}>
                                 {getreferralReport?.levelCount?.length > 2 &&
                                    <h5 style={{ color: "#FFE388", textDecoration: "underline", cursor: "pointer" }} >View</h5>
                                 }   
                                 </button>
                              </div>
                              <div className="d-flex align-items-center justify-content-end">
                                 <div className="d-flex align-items-center mt-5">
                                    <h3 className="text-yellow"> {getreferralReport?.levelCount[2]?.count || 0} </h3>
                                    <h5 className="px-2">{memberLabeltwo}</h5>
                                    <img className="img-fluid crown2img" src={profile2user} alt='profile'/>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-3 col-lg-3 col-md-6 mb-lg-0 mb-3" key={4} >
                        <div className="ash2">
                           <div className="inrCnt">
                              <div className="d-flex align-items-center justify-content-between">
                                 <div className="d-flex align-items-center">
                                    <div className="levelbg">
                                       04
                                    </div>
                                    <h5 className="px-2">Level</h5>
                                 </div>
                                 <button style={{ all: 'unset', cursor: 'pointer' }} onClick={(e) => levelCards(e, getreferralReport?.levelCount?.length > 1 ? getreferralReport?.levelCount[3]?.level : 0)} onKeyDown={(e) => levelCards(e, getreferralReport?.levelCount?.length > 1 ? getreferralReport?.levelCount[3]?.level : 0)}>
                                 {getreferralReport?.levelCount?.length > 3 &&
                                    <h5 style={{ color: "#FFE388", textDecoration: "underline", cursor: "pointer" }} >View</h5>
                                 }   
                                 </button>
                              </div>
                              <div className="d-flex align-items-center justify-content-end">
                                 <div className="d-flex align-items-center mt-5">
                                    <h3 className="text-yellow"> {getreferralReport?.levelCount[3]?.count || 0} </h3>
                                    <h5 className="px-2">{memberLabelthree}</h5>
                                    <img className="img-fluid crown2img" src={profile2user} alt='profile'/>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="mt41">
                     <h5 className="fn16 mb-3">Business Qualified Direct Team</h5>
                     <div className="row">
                        <div className="col-xl-3 col-lg-4 col-md-6 mb-lg-0 mb-3">
                           <div className="tmbg tm1bg purpleBg">
                              <div className="inrCnt">
                                 <div className="d-flex align-items-center justify-content-between">

                                 <button style={{ all: 'unset', cursor: 'pointer' }} onClick={(e) => premiumachievement(e, 'moonstaker')}><h5 style={{ cursor: "pointer" }} >Moon Stakers</h5></button>
                                 <button style={{ all: 'unset', cursor: 'pointer' }} onClick={(e) => roleHistory(e, 'moonStaker', getreferralReport?.resultObj?.totalMoonStakers)} onKeyDown={(e) => roleHistory(e, 'moonStaker', getreferralReport?.resultObj?.totalMoonStakers)} >
                                 {getreferralReport?.resultObj?.totalMoonStakers ? <h5 className="text-yellow" style={{ color: "#FFE388", textDecoration: "underline", cursor: "pointer" }} >View</h5> : ''}

                               </button>

                                 </div>
                                 <div className="d-flex align-items-end justify-content-between mt-3" style={{ cursor: "pointer" }} onClick={(e) => premiumachievement(e, 'moonstaker')}>
                                    <img className="img-fluid crown2img" src={pf2} alt='profile'/>
                                    <div className="d-flex align-items-center ">
                                       <h3 className="text-yellow">{getreferralReport?.resultObj?.totalMoonStakers !== '' ? getreferralReport?.resultObj?.totalMoonStakers || 0 : '--'}</h3>
                                       <h5 className="px-2">{getreferralReport?.resultObj?.totalMoonStakers < 1 ? 'Member' : "Members"}</h5>
                                    </div>
                                 </div>
                              </div>

                           </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 mb-lg-0 mb-3" >
                           <div className="tmbg blueBg">
                              <div className="inrCnt">
                                 <div className="d-flex align-items-center justify-content-between">
                                 <button style={{ all: 'unset', cursor: 'pointer' }} onClick={(e) => premiumachievement(e, 'executive')}><h5 style={{ cursor: "pointer" }} >Executive Director</h5></button>
                                 <button style={{ all: 'unset', cursor: 'pointer' }} onClick={(e) => roleHistory(e, 'executiveDirector', getreferralReport?.resultObj?.totalExecutive)} onKeyDown={(e) => roleHistory(e, 'executiveDirector', getreferralReport?.resultObj?.totalExecutive)} >
                                 {getreferralReport?.resultObj?.totalExecutive ? <h5 className="text-yellow" style={{ color: "#FFE388", textDecoration: "underline", cursor: "pointer" }} >View</h5> : ''}

                               </button>
                                 </div>
                                 <div className="d-flex align-items-end justify-content-between mt-3" style={{ cursor: "pointer" }} onClick={(e) => premiumachievement(e, 'executive')}>
                                    <img className="img-fluid crown2img" src={pf2} alt='profile'/>
                                    <div className="d-flex align-items-center ">
                                       <h3 className="text-yellow">{getreferralReport?.resultObj?.totalExecutive !== '' ? getreferralReport?.resultObj?.totalExecutive || 0 : '--'}</h3>
                                       <h5 className="px-2">{getreferralReport?.resultObj?.totalExecutive < 1 ? 'Member' : "Members"}</h5>
                                    </div>
                                 </div>
                              </div>

                           </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 mb-lg-0 mb-3" >
                           <div className="tmbg yellowBg">
                              <div className="inrCnt">
                                 <div className="d-flex align-items-center justify-content-between">
                                 <button style={{ all: 'unset', cursor: 'pointer' }} onClick={(e) => premiumachievement(e, 'premium')}><h5 style={{ cursor: "pointer" }} >Premium Director</h5></button>
                                 <button style={{ all: 'unset', cursor: 'pointer' }} onClick={(e) => roleHistory(e, 'premiumDirector', getreferralReport?.resultObj?.totalPremium)} onKeyDown={(e) => roleHistory(e, 'premiumDirector', getreferralReport?.resultObj?.totalPremium)} >
                                 {getreferralReport?.resultObj?.totalPremium ? <h5 className="text-yellow" style={{ color: "#FFE388", textDecoration: "underline", cursor: "pointer" }} >View</h5> : ''}

                               </button>

                                 </div>
                                 <div className="d-flex align-items-end justify-content-between mt-3" style={{ cursor: "pointer" }} onClick={(e) => premiumachievement(e, 'premium')}>
                                    <img className="img-fluid crown2img" src={pf2} alt='profile'/>
                                    <div className="d-flex align-items-center ">
                                       <h3 className="text-yellow">{getreferralReport?.resultObj?.totalPremium !== '' ? getreferralReport?.resultObj?.totalPremium || 0 : '--'}</h3>
                                       <h5 className="px-2">{getreferralReport?.resultObj?.totalPremium < 1 ? 'Member' : "Members"}</h5>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 mb-lg-0 mb-3" >
                           <div className="tmbg greyBg">
                              <div className="inrCnt">
                                 <div className="d-flex align-items-center justify-content-between">

                                 <button style={{ all: 'unset', cursor: 'pointer' }} onClick={(e) => premiumachievement(e, 'titan')}><h5 style={{ cursor: "pointer" }} >Titan Director</h5></button>
                                 <button style={{ all: 'unset', cursor: 'pointer' }} onClick={(e) => roleHistory(e, 'titanDirector', getreferralReport?.resultObj?.totalTitan)} onKeyDown={(e) => roleHistory(e, 'titanDirector', getreferralReport?.resultObj?.totalTitan)} >
                                 {getreferralReport?.resultObj?.totalTitan ? <h5 className="text-yellow" style={{ color: "#FFE388", textDecoration: "underline", cursor: "pointer" }} >View</h5> : ''}

                               </button>

                                 </div>
                                 <div className="d-flex align-items-end justify-content-between mt-3" style={{ cursor: "pointer" }} onClick={(e) => premiumachievement(e, 'titan')}>
                                    <img className="img-fluid crown2img" src={pf2} alt='profile'/>
                                    <div className="d-flex align-items-center ">
                                       <h3 className="text-yellow">{getreferralReport?.resultObj?.totalTitan !== '' ? getreferralReport?.resultObj?.totalTitan || 0 : '--'}</h3>
                                       <h5 className="px-2">{getreferralReport?.resultObj?.totalTitan < 1 ? 'Member' : "Members"} </h5>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="ashBg mt-3 z-4">
               <div className="inrCnt">
                  <div className="borderbtm ">
                     <h4 className="pb-lg-0 py-3">INDIVIDUAL's TEAM REPORT</h4>
                  </div>
                  <div className="d-flex align-items-center justify-content-between flex-wrap bsform">
                     <div className="mb-lg-0 mb-3 ">
                        <div className="mx150 selbg">
                           <div className="d-flex align-items-center">
                              <img className="img-fluid crown2img" src={user} alt='user'/>
                              <div className='octDrpdown'>
                                 <UncontrolledDropdown>
                                    <DropdownToggle className="btn btn-11545 w-100" caret>
                                       <div className="d-flex justify-content-between align-items-center w-100">
                                          Team {teamNo}
                                          <img className="img-fluid" src={downarr} alt='downarr'/>
                                       </div>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                       {getIndividualReport?.data?.referrals?.length > 0 ?
                                          getIndividualReport?.data?.referrals?.map((n, i) => (<DropdownItem className='py-2' tag="a" key={n} onClick={() => IndividualTeam(n.userId, i)}>Team {i + 1}</DropdownItem>)) : ''}
                                    </DropdownMenu>
                                 </UncontrolledDropdown>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="mx150 selbg mb-1">
                        <div className="d-flex align-items-center">
                              <h5 className="px-lg-0 px-3">{loading ? <ButtonLoader /> : getIndividualReport?.status ? 
                              getIndividualReport?.data?.referrals?.length > 0 ? getIndividualReport?.data?.referrals[(teamNo - 1)]?.username
                               : '' : <h5 className="px-2">0 Member</h5>}</h5>

                                <h5 className="px-lg-0 px-3 ml-4">{loading ? <ButtonLoader /> : getIndividualReport?.status ? 
                              getIndividualReport?.data?.referrals?.length > 0 ? getIndividualReport?.data?.referrals[(teamNo - 1)]?.qualification
                              
                               : '' : " "}</h5>
                        </div>
                     </div>
                     <div className="yelbg mx460">
                        <h5 className="text-blk">Total Value</h5>
                        <h4 className="text-blk px-lg-0 px-3">{loading ? <ButtonLoader /> : individualTeamTotal?.status ? individualTeamTotal?.data?.totalStake || 0 : '0'}<span> ANA</span></h4>
                     </div>
                  </div>
               </div>
            </div>

            <div className="ashBg mt-3">
               <div className="inrCnt">
                  <div className="borderbtm ">
                     <h4 className="pb-lg-0 py-3">Volume Report</h4>
                  </div>
           
                  <div className="row mt26">
                     <div className="col-xl-3 col-lg-3 col-md-6 mb-lg-0 mb-3">
                        <div className="ash2">
                           <div className="inrCnt">
                              <div className="d-flex align-items-center justify-content-between">
                                 <div className="d-flex align-items-center">
                                    <div className="levelbg">
                                       01
                                    </div>
                                    <h5 className="px-2">Level</h5>
                                 </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-end">
                                 <div className="d-flex align-items-center mt-5">
                                    <h3 className="text-yellow"> {isFetching ? <ButtonLoader /> : getVolumeReport?.status ? getVolumeReport?.data?.levels?.l1 || 0 : '0'}</h3>
                                    <h5 className="px-2">ANA</h5>
                                 </div>
                              </div>
                           </div>

                        </div>
                     </div>
                     <div className="col-xl-3 col-lg-3 col-md-6 mb-lg-0 mb-3">
                        <div className="ash2">
                           <div className="inrCnt">
                              <div className="d-flex align-items-center justify-content-between">
                                 <div className="d-flex align-items-center">
                                    <div className="levelbg">
                                       02
                                    </div>
                                    <h5 className="px-2">Level</h5>
                                 </div>

                              </div>
                              <div className="d-flex align-items-center justify-content-end">
                                 <div className="d-flex align-items-center mt-5">
                                    <h3 className="text-yellow">{isFetching ? <ButtonLoader /> : getVolumeReport?.status ? getVolumeReport?.data?.levels?.l2 || 0 : '0'}  </h3>
                                    <h5 className="px-2">ANA</h5>
                                 </div>
                              </div>
                           </div>

                        </div>
                     </div>
                     <div className="col-xl-3 col-lg-3 col-md-6 mb-lg-0 mb-3">
                        <div className="ash2">
                           <div className="inrCnt">
                              <div className="d-flex align-items-center justify-content-between">
                                 <div className="d-flex align-items-center">
                                    <div className="levelbg">
                                       03
                                    </div>
                                    <h5 className="px-2">Level</h5>
                                 </div>

                              </div>
                              <div className="d-flex align-items-center justify-content-end">
                                 <div className="d-flex align-items-center mt-5">
                                    <h3 className="text-yellow"> {isFetching ? <ButtonLoader /> : getVolumeReport?.status ? getVolumeReport?.data?.levels?.l3 || 0 : '0'}  </h3>
                                    <h5 className="px-2">ANA</h5>
                                 </div>
                              </div>
                           </div>

                        </div>
                     </div>
                     <div className="col-xl-3 col-lg-3 col-md-6 mb-lg-0 mb-3">
                        <div className="ash2">
                           <div className="inrCnt">
                              <div className="d-flex align-items-center justify-content-between">
                                 <div className="d-flex align-items-center">
                                    <div className="levelbg">
                                       04
                                    </div>
                                    <h5 className="px-2">Level</h5>
                                 </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-end">
                                 <div className="d-flex align-items-center mt-5">
                                    <h3 className="text-yellow"> {isFetching ? <ButtonLoader /> : getVolumeReport?.status ? getVolumeReport?.data?.levels?.l4 || 0 : '0'}  </h3>
                                    <h5 className="px-2">ANA</h5>
                                 </div>
                              </div>
                           </div>

                        </div>
                     </div>

                  </div>
               </div>
            </div>

            <div className="ashBg mt-3">
               <div className="inrCnt">
                  <div className="borderbtm d-flex flex-wrap align-items-center justify-content-between">
                     <h4 className="pb-lg-0 py-3">Referral bonus Report</h4>
                     <button className="btn claimbtn" onClick={toggle}>Claim</button>
                  </div>
                  <div className="row mt20">
                     <div className="col-xl-4 col-lg-4 col-md-6 mb-lg-0 mb-3">
                        <div className="yelbg">
                           <h5 className="text-blk">Total Referral Bonus</h5>
                           <h4 className="text-blk">{getisLoading ? <ButtonLoader /> : getreferal?.totalReferralBonus || "0"} <span>ANA</span></h4>
                        </div>
                     </div>
                     <div className="col-xl-4 col-lg-4 col-md-6 mb-lg-0 mb-3">
                        <div className="yelbg">
                           <h5 className="text-blk">Rewards Claimed</h5>
                           <h4 className="text-blk">{getisLoading ? <ButtonLoader /> : getreferal?.totalReferralClaimed || "0"} <span>ANA</span></h4>
                        </div>
                     </div>
                     <div className="col-xl-4 col-lg-4 col-md-6 mb-lg-0 mb-3">
                        <div className="yelbg">
                           <h5 className="text-blk">Total Available</h5>
                           <h4 className="text-blk">{getisLoading ? <ButtonLoader /> : getreferal?.totalReferralAvailable || "0"} <span>ANA</span></h4>
                        </div>
                     </div>
                  </div>
                  <div className="row mt26">

                     <div className="col-xl-3 col-lg-3 col-md-6 mb-lg-0 mb-3">
                        <div className="ash2">
                           <div className="inrCnt">
                              <div className="d-flex align-items-center justify-content-between">
                                 <div className="d-flex align-items-center">
                                    <div className="levelbg">
                                       1
                                    </div>
                                    <h5 className="px-2">Level</h5>

                                 </div>
                                 {getreferralReport?.levelCount?.length > 0 &&
                                  <button style={{ all: 'unset', cursor: 'pointer' }} onClick={(e) => levelCard(e, 1, getreferralReport?.levelCount[0]?.count)} onKeyDown={(e) => levelCard(e, 1, getreferralReport?.levelCount[0]?.count)}>
                                 <h5 style={{ color: "#FFE388", textDecoration: "underline", cursor: "pointer" }} >View</h5>
                                  </button>
                                 }
                              </div>
                              <div className="d-flex align-items-center justify-content-end">
                                 <div className="d-flex align-items-center mt-5">
                                    <h3 className="text-yellow"> {getisLoading ? <ButtonLoader /> : getreferal?.referralRewardStake?.l1 || "0"}</h3>
                                    <h5 className="px-2">ANA</h5>

                                 </div>
                              </div>
                           </div>

                        </div>
                     </div>
                     <div className="col-xl-3 col-lg-3 col-md-6 mb-lg-0 mb-3">
                        <div className="ash2">
                           <div className="inrCnt">
                              <div className="d-flex align-items-center justify-content-between">
                                 <div className="d-flex align-items-center">
                                    <div className="levelbg">
                                       2
                                    </div>
                                    <h5 className="px-2">Level</h5>
                                 </div>
                                 {getreferralReport?.levelCount?.length > 1 &&
                                    <h5 style={{ color: "#FFE388", textDecoration: "underline", cursor: "pointer" }} onClick={(e) => levelCard(e, 2, getreferralReport?.levelCount[1]?.count)} onKeyDown={(e) => levelCard(e, 2, getreferralReport?.levelCount[1]?.count)}>View</h5>
                                 }
                              </div>
                              <div className="d-flex align-items-center justify-content-end">
                                 <div className="d-flex align-items-center mt-5">
                                    <h3 className="text-yellow"> {getisLoading ? <ButtonLoader /> : getreferal?.referralRewardStake?.l2 || "0"}</h3>
                                    <h5 className="px-2">ANA</h5>

                                 </div>
                              </div>
                           </div>

                        </div>
                     </div>
                     <div className="col-xl-3 col-lg-3 col-md-6 mb-lg-0 mb-3">
                        <div className="ash2">
                           <div className="inrCnt">
                              <div className="d-flex align-items-center justify-content-between">
                                 <div className="d-flex align-items-center">
                                    <div className="levelbg">
                                       3
                                    </div>
                                    <h5 className="px-2">Level</h5>
                                 </div>
                                 <button style={{ all: 'unset', cursor: 'pointer' }}  onClick={(e) => levelCard(e, 3, getreferralReport?.levelCount?.length > 2 ? getreferralReport?.levelCount[2]?.count : 0)}
                                    onKeyDown={(e) => levelCard(e, 3, getreferralReport?.levelCount[1]?.count)}>  
                                    {getreferralReport?.levelCount?.length > 2 && <h5 style={{ color: "#FFE388", textDecoration: "underline", cursor: "pointer" }}
                                   >View</h5>}                               
                                    
                                      </button>
                                
                              </div>
                              <div className="d-flex align-items-center justify-content-end">
                                 <div className="d-flex align-items-center mt-5">
                                    <h3 className="text-yellow"> {getisLoading ? <ButtonLoader /> : getreferal?.referralRewardStake?.l3 || "0"}</h3>
                                    <h5 className="px-2">ANA</h5>

                                 </div>
                              </div>
                           </div>

                        </div>
                     </div>
                     <div className="col-xl-3 col-lg-3 col-md-6 mb-lg-0 mb-3">
                        <div className="ash2">
                           <div className="inrCnt">
                              <div className="d-flex align-items-center justify-content-between">
                                 <div className="d-flex align-items-center">
                                    <div className="levelbg">
                                       4
                                    </div>
                                    <h5 className="px-2">Level</h5>
                                 </div>
                                 <button style={{ all: 'unset', cursor: 'pointer' }}    onClick={(e) => levelCard(e, 4, getreferralReport?.levelCount?.length > 1 ? getreferralReport?.levelCount[3]?.count : 0)}
                                    onKeyDown={(e) => levelCard(e, 4, getreferralReport?.levelCount?.length > 1 ? getreferralReport?.levelCount[3]?.count : 0)}>
                                {getreferralReport?.levelCount?.length > 3 && <h5 style={{ color: "#FFE388", textDecoration: "underline", cursor: "pointer" }}
                                 >View</h5>}
                               </button>

                                
                              </div>
                              <div className="d-flex align-items-center justify-content-end">
                                 <div className="d-flex align-items-center mt-5">
                                    <h3 className="text-yellow"> {getisLoading ? <ButtonLoader /> : getreferal?.referralRewardStake?.l4 || "0"}</h3>
                                    <h5 className="px-2">ANA</h5>

                                 </div>
                              </div>
                           </div>

                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <div className="ashBg mt-3">
               <div className="inrCnt">
                  <div className="borderbtm d-flex align-items-center justify-content-between flex-wrap">
                     <h4 className="pb-lg-0 ">Pool bonus Report</h4>
                     <div className="d-flex flex-wrap align-items-center">
                        <h6 className="mb-lg-0 mb-2 pr-3"> <span className="ashtxt">Total Earned </span> : {poolBonusLoading ? <ButtonLoader /> : parseFloat((getpoolbonusreport?.data?.totalpoolBonus)?.toFixed(6)) || "0"} ANA  <span className="px-2"> |</span>    <span className="ashtxt">Total Claim</span>    : {poolBonusLoading ? <ButtonLoader /> : parseFloat((getpoolbonusreport?.data?.totalpoolClaimed)?.toFixed(6)) || "0"} ANA  <span className="px-2"> | </span>  <span className="ashtxt">Total Available</span>   : {poolBonusLoading ? <ButtonLoader /> : parseFloat((getpoolbonusreport?.data?.totalpoolAvailable)?.toFixed(6)) || "0"} ANA</h6>
                        <button className="btn claimbtn" onClick={toggleBonus}>Claim</button>
                     </div>
                  </div>
                  <div className="row mt26">
                     {poolbonusdata?.map((data) => (

                        <div className="col-xl-3 col-lg-4 col-md-6 mb-lg-0 mb-3" key={data}>
                           <div className={`tmbg mn119 ${data.staker == "moonStaker" ? "purpleBg" : data.staker == "executiveDirector" ? "blueBg" : data.staker == "premiumDirector" ? "yellowBg" : "greyBg"}`}>
                              <div className="inrCnt">
                                 <div className="d-flex align-items-center justify-content-between">
                                    <h5 style={{ textTransform: "capitalize" }}>{data.staker}</h5>
                                 </div>
                                 <div className="d-flex align-items-end justify-content-between mt-5">
                                    <h5 className="text-yellow" onClick={(e) => poolBonusReport(e, data.staker)} onKeyDown={(e) => poolBonusReport(e, data.staker)} style={{ cursor: "pointer" }}>View</h5>
                                    <div className="d-flex align-items-center ">
                                       <h3 className="text-yellow"> {parseFloat((data.reward)?.toFixed(2))}  </h3>
                                       <h5 className="px-2">ANA</h5>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     ))}
                  </div>
               </div>
            </div>

            <div className="ashBg mt-3">
               <div className="inrCnt">
                  <div className="borderbtm d-flex align-items-center justify-content-between flex-wrap">
                     <h4 className="pb-lg-0 ">Reward bonus Report</h4>
                  </div>
                  <div className="row mt26">
                     <div className="col-xl-4 col-lg-4 col-md-6 mb-lg-0 mb-3">
                        <div className={`bnimg blueBg ${getredeemReport?.executiveDirector ? "" : "active"}`}>
                           <div className="inrCnt">
                              <div className="d-flex align-items-center justify-content-between borderbtm">
                                 <h5>Executive Director Bonus</h5>
                                 {getredeemReport?.executiveOneReward ? <a onClick={(e) => handleClick(e, "executive")} onKeyDown={(e) => handleClick(e, "executive")}><h5 className="text-yellow" style={{ cursor: "pointer" }} >View</h5></a> : ""}
                              </div>
                              <div className="mt-4">
                                 {getredeemReport?.executiveDirector && getredeemReport?.executiveOneReward ?
                                    <>
                                       <h5 className="mb-2 fn17">One Time Bonus</h5>
                                       <div className="d-flex align-items-center ">
                                          <h3 className="text-yellow">Reward received</h3>
                                       </div>

                                    </>
                                    : <h3 className="text-yellow">You are eligible for One Time Reward Bonus</h3>

                                 }

                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-4 col-lg-4 col-md-6 mb-lg-0 mb-3">
                        <div className={`bnimg yellowBg ${getredeemReport?.premiumDirector ? "" : "active"}`}>
                           <div className="inrCnt">
                              <div className="d-flex align-items-center justify-content-between borderbtm">
                                 <h5>Premium Director Bonus</h5>
                                 {getredeemReport?.premiumOneReward ? <a onClick={(e) => handleClick(e, "premium")} onKeyDown={(e) => handleClick(e, "premium")}><h5 className="text-yellow" style={{ cursor: "pointer" }} >View</h5></a> : ""}
                              </div>
                              <div className="mt-4">
                                 {getredeemReport?.premiumDirector && getredeemReport?.premiumOneReward ?
                                    <>
                                       <h5 className="mb-2 fn17">One Time Bonus</h5>
                                       <div className="d-flex align-items-center ">
                                          <h3 className="text-yellow">Reward received</h3>
                                       </div>
                                    </>

                                    :
                                    <h3 className="text-yellow">You are eligible for One Time Reward Bonus</h3>

                                 }
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-4 col-lg-4 col-md-6 mb-lg-0 mb-3">
                        <div className={`bnimg greyBg ${getredeemReport?.titanDirector ? "" : "active"}`}>
                           <div className="inrCnt">
                              <div className="d-flex align-items-center justify-content-between borderbtm">
                                 <h5>Titan Director Bonus</h5>
                                 {getredeemReport?.titanDirectorOneReward ? <a onClick={(e) => handleClick(e, "titan")} onKeyDown={(e) => handleClick(e, "titan")}><h5 className="text-yellow" style={{ cursor: "pointer" }}>View</h5></a> : ""}

                              </div>
                              <div className="mt-4">
                                 {getredeemReport?.titanDirector && getredeemReport?.titanDirectorOneReward ?
                                    <>
                                       <h5 className="mb-2 fn17">One Time Bonus</h5>
                                       <div className="d-flex align-items-center ">
                                          <h3 className="text-yellow">Reward received</h3>
                                       </div>
                                    </>

                                    : <h3 className="text-yellow">You are eligible for One Time Reward Bonus</h3>

                                 }
                              </div>
                           </div>
                        </div>
                     </div>


                  </div>
               </div>
            </div>

            <div className="ashBg mt-3">
               <div className="inrCnt">
                  <div className="borderbtm d-flex align-items-center justify-content-between">
                     <h4 className="pb-lg-0 py-3">Redeem Report  </h4>
                  </div>
                  <div className="row mt20">
                     <div className="col-xl-4 col-lg-4">
                        <div className="yelbg">
                           <h5 className="text-blk">Total Redeem</h5>
                           <h4 className="text-blk ">{parseFloat((getredeemReport?.redeemStore)?.toFixed(6)) || 0} </h4>
                        </div>
                     </div>
                  </div>
                  <div className="row mt26">
                     <div className="col-xl-3 col-lg-4 col-md-6 mb-lg-0 mb-3">
                        <div className="ash3">
                           <div className="inrCnt">
                              <div className="d-flex align-items-center justify-content-between">
                                 <div className="d-flex align-items-center">
                                    <h5 className="px-2">Stake Redeem</h5>
                                 </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-end">
                                 <div className="d-flex align-items-center mt-4">
                                    <h3 className="text-yellow"> {getredeemReport?.totalClaimed || 0} </h3>
                                    <h5 className="px-2">ANA</h5>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-3 col-lg-4 col-md-6 mb-lg-0 mb-3">
                        <div className="ash3">
                           <div className="inrCnt">
                              <div className="d-flex align-items-center justify-content-between">
                                 <div className="d-flex align-items-center">
                                    <h5 className="px-2">Referral Bonus Redeem</h5>
                                 </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-end">
                                 <div className="d-flex align-items-center mt-4">
                                    <h3 className="text-yellow">{getredeemReport?.referralToRedeem || 0}</h3>
                                    <h5 className="px-2">ANA</h5>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-3 col-lg-4 col-md-6 mb-lg-0 mb-3">
                        <div className="ash3">
                           <div className="inrCnt">
                              <div className="d-flex align-items-center justify-content-between">
                                 <div className="d-flex align-items-center">
                                    <h5 className="px-2">Pool Bonus Redeem</h5>
                                 </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-end">
                                 <div className="d-flex align-items-center mt-4">
                                    <h3 className="text-yellow"> {getredeemReport?.poolBonusRedeem || 0}  </h3>
                                    <h5 className="px-2">ANA</h5>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-3 col-lg-4 col-md-6 mb-lg-0 mb-3">
                        <div className="ash3">
                           <div className="inrCnt">
                              <div className="d-flex align-items-center justify-content-between">
                                 <div className="d-flex align-items-center">
                                    <h5 className="px-2">ANA Wallet Redeem</h5>
                                 </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-end">
                                 <div className="d-flex align-items-center mt-4">
                                    <h3 className="text-yellow"> {((getredeemReport?.redeemStore) - ((getredeemReport?.totalClaimed) + (getredeemReport?.referralToRedeem) + getredeemReport?.poolBonusRedeem))?.toFixed(5) || 0}  </h3>
                                    <h5 className="px-2">ANA</h5>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <Modal isOpen={modalLogin} toggle={toggle} modalClassName="authModal">
            <ModalHeader toggle={toggle}></ModalHeader>
            <ModalBody className="d-flex align-items-center justify-content-center">
               <ReferalClaim toggle={toggle} available={getreferal?.totalReferralAvailable} />
            </ModalBody>
         </Modal>

         <Modal isOpen={modalPoolBonus} toggle={toggleBonus} modalClassName="authModal">
            <ModalHeader toggle={toggleBonus}></ModalHeader>
            <ModalBody className="d-flex align-items-center justify-content-center">
               <PoolBonusClaim toggle={toggleBonus} available={getpoolbonusreport?.data?.totalpoolAvailable} />
            </ModalBody>
         </Modal>
      </div>
   )
}
export default BusinessStore;