import { useQuery } from "react-query"

import DataService from "../../../config/DataService";

const { getRequest } = DataService()

export function useCms(type) {

    return useQuery({
        queryKey: ['cmspage', type],
        queryFn: () => {
            return getRequest(`home/getSiteinfo?type=${type}`)
        },
    })
}