import { Link } from "react-router-dom";
import "../../assets/scss/Innerpages/Innerpages.scss";

import notFound from '../../assets/images/cms/notFound.png';
import logo from '../../assets/images/logo.png';
import { BounceInDownButton } from "../../Components/animation/bouncein";


const Notfound = () => {
   return (
      <div className="innerpagesSec">
         <section className="innerPagesCnt notFdCnt">
            <div className="container">
               <div className="row justify-content-center">
                  <div className="col-lg-6 err_pg">
                     <div className="d-flex justify-content-center">
                        <img src={logo} className="img-fluid" alt="logo"/>
                     </div>
                     <img src={notFound} className="img-fluid" alt="notFound"/>
                     <BounceInDownButton>
                        <h3 className="secHeading text-center">Page not Found</h3>
                     </BounceInDownButton>
                     <div className="d-flex justify-content-center">
                        <Link to="/" className="btn btn-11545 btnLg btnGold" type="button">Back to Home</Link>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </div>
   )
}
export default Notfound;